let storeCountries = {
  default:'',
  allowed: [],
  countriesData: []
 }

const reducers = (state = storeCountries, action) => {
  switch(action.type) {
    case "SET_STORE_COUNTRIES":
      return action.countries;
    default:
      return state;
  }
}

export default reducers;
