export const miniCart = {
  cart:               "Cart",
  qty:                "Units",
  itemDelete:         "Delete",
  noItems:            "You do not have any products in your cart",
  oneProductInCart:   "Item in cart",
  productsInCart:     "Items in cart",
  subTotal:           "Subtotal",
  subTotalDiscount:   "With discount",
  buttonCheckout:     "Checkout",
  continueBuying:     "Continue shopping"
}

export default miniCart;
