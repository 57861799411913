export const footerNewsletter = {
  recaptchaError:     'You must complete the reCaptcha',
  privacyError:     'You must accept the privacy policy',
  subscribe:          'Newsletter',
  emailPlaceholder:   'Your email address',
  subscriptionError:  'An error has occurred while subscribing to the newsletter. Please check your email address and try again later.',
  send:               'Subscribe',
  accept:             'I accept the ',
  privacyLink:        'privacy policy'
}

export default footerNewsletter;
