import {ThirdPartyScript} from '@frontend/components/third-party-scripts';
import { connect }        from 'react-redux';

export class TrustedShops extends ThirdPartyScript {
  state = {
      subscribed: false,
      loaded: false,
      location : null,
      onProductPage: false
  }
  
  static mapStateToProps({ storeConfig }) {
      return {
          storeConfiguration: storeConfig,
      }
  }

  resetCurrentPage(){
      this.setState({onProductPage: false})
  }


  onPublicPage(){
    this.resetCurrentPage();
  }

  onPrivatePage() {
    this.resetCurrentPage();
  }

  onLoaded() {
    console.log("Trusted: Loaded")
    if (!this.state.onProductPage) {
      const { trustedShopsId } = this.props.storeConfiguration;
      var _ts = document.createElement('script');
      _ts.type = 'text/javascript';
      _ts.charset = 'utf-8';
      _ts.async = true;
      _ts.onload = () => {
        window._tsConfig = {
          'yOffset': '0', /* offset from page bottom */
          'variant': 'reviews', /* default, reviews, custom, custom_reviews */
          'customElementId': 'ez-trusted-shops', /* required for variants custom and custom_reviews */
          'trustcardDirection': '', /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */
          'customBadgeWidth': '', /* for custom variants: 40 - 90 (in pixels) */
          'customBadgeHeight': '', /* for custom variants: 40 - 90 (in pixels) */
          'disableResponsive': 'false', /* deactivate responsive behaviour */
          'disableTrustbadge': 'false' /* deactivate trustbadge */
        };
      }
      _ts.src = '//widgets.trustedshops.com/js/' + trustedShopsId + '.js';
      document.body.appendChild(_ts);

      console.log("Trustedshops:loaded")
    }
  }

  onProductPage(product){
    this.setState({onProductPage: true, product})
  }

  render() {
      this.subscribe();
      return null;
  }


}


export default connect(TrustedShops.mapStateToProps, {})(TrustedShops);