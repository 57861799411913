import React                          from 'react';
import App, { Container }             from 'next/app';
import Head                           from 'next/head';
import { Provider }                   from 'react-redux';
import { parseCookies }               from 'nookies';
import { prepareClientPortals }       from '@jesstelford/react-portal-universal'
import _get                           from 'lodash/get';

import settings                       from '@global/settings.config.js'
import Context                        from '@frontend/context';
import ReduxHydrate                   from '@frontend/components/ReduxHydrate';
import MediaQueriesManager            from '@frontend/components/media-queries-manager';
import { setIsMobile }                from '@frontend/actions/is-mobile';

import UrlBuilder                     from '@frontend/services/UrlBuilder';
import i18nService                    from "@frontend/services/i18n";
import initApollo                     from '@frontend/services/apollo';
import initRedux                      from '@frontend/services/redux';
import initScriptManager              from '@frontend/services/scriptManager';


import checkServer                    from "@frontend/util/checkServer";
import locales                        from '@frontend/locales/index';

import '@frontend/less/index.less';

const apolloClient  = initApollo(settings.graphql.endpoint);
// const reduxStore    = initRedux();
// const scriptManager = initScriptManager();
if (typeof window !== 'undefined') {
  // On the client, we have to run this once before React attempts a render.
  // Here in _app is a great place to do it as this file is only required once,
  // and right now (outside the constructor) is before React is invoked.
  prepareClientPortals()
}
class ZandmanApp extends App{

  static async getInitialProps(appContext){
    if (_get(appContext,"ctx.req.isStatic")) {
      // return {res: appContext.ctx.res, req: appContext.ctx.req};
      if (checkServer() && appContext.ctx && appContext.ctx.res) {
        // context.res.writeHead(404)
        appContext.ctx.res.statusCode = 404;
        appContext.ctx.res.end();
        return false;
      } 
    }
    const ctx           = appContext.ctx;
    const url           = (ctx.req) ? ctx.req.headers.host : "";
    const isMobile      = (ctx.req) ? ctx.req.isMobile     : undefined;
    // const scriptManager = initScriptManager();
    await this.updateAppContext(appContext);
    const appProps = await App.getInitialProps(appContext);
    return { ...appProps, url, isMobile, scriptManager: appContext.ctx.scriptManager, store: appContext.ctx.store  };
  }

  static async updateAppContext(appContext){
    let ctx           = appContext.ctx;
    ctx.client        = apolloClient;
    ctx.store         = initRedux();
    ctx.cookies       = parseCookies(ctx);
    ctx.router        = new UrlBuilder();
    ctx.scriptManager = initScriptManager(ctx);
  }

  initServices(){
    const { langCode='es',currentStore={path:''} } = {};
    const i18n                              = new i18nService(locales[langCode]);
    const router                            = new UrlBuilder();
    const client                            = apolloClient;
    const store                             = (checkServer() ? this.props.store         :  initRedux())
    const scriptManager                     = (checkServer() ? this.props.scriptManager :  initScriptManager())
    return { i18n,router,client,scriptManager, store};
  }

  initRedux(store,client,cookies){
    const { storeConfig,isMobile } = this.props;

    if(typeof(isMobile) === 'boolean'){
      store.dispatch(setIsMobile(isMobile));
    }

    if(!checkServer()){
      this.frontendReduxActions(store,client,cookies);
    }
  }

  frontendReduxActions(store,client,cookies){
  }

  initApp(reduxStore){
    this.initRedux(reduxStore,apolloClient,parseCookies({}));
  }

  renderHeadComponents(){
    return null;
  }

  renderManagers(){
    return (
      <>
        <MediaQueriesManager />
      </>
    )
  }

  renderThirdPartyScripts(){
    return (
      <>
      </>
    )
  }



  render () {
    const { Component,pageProps,url } = this.props;
    const services                    = this.initServices();
    
    this.initApp(services.store);

    return (
      <Container>
        <Head>
          {this.renderHeadComponents()}
        </Head>
        <Provider store={services.store}>
          <Context.Provider value={services}>
              {this.renderManagers()}
              <Component {...pageProps} url={url}/>
              {this.renderThirdPartyScripts()}
              <div id="portal"></div>
          </Context.Provider>
        </Provider>
        <ReduxHydrate store={services.store}></ReduxHydrate>
      </Container>
    );
  }

}

export default ZandmanApp;
export { apolloClient, ZandmanApp };
