const cookies = {
    ESSENTIAL:   'essential',
    FUNCTIONAL:  'functional',
    ANALYTICAL:  'analytical',
    ADVERTISING: 'advertising',
    types: function(){
        return [
            this.ESSENTIAL,
            this.FUNCTIONAL,
            this.ANALYTICAL,
            this.ADVERTISING
        ];
    }
};

export default cookies;