let megamenu = null;

const reducers = (state = megamenu, action) => {
  switch(action.type) {
    case "SET_MEGAMENU":
      return action.megamenu;
    default:
      return state;
  }
}

export default reducers;
