export const addresses = {
  editTagTitle:         'Adresse bearbeiten',
  editMetaTitle:        'Adresse bearbeiten',
  addTagTitle:          'Neue Adresse hinzufügen',
  addMetaTitle:         'Neue Adresse hinzufügen',
  add:                  'Neue Adresse hinzufügen',
  edit:                 'Adresse bearbeiten',
  firstnameLabel:       'Name',
  lastnameLabel:        'Nachname',
  regionLabel:          'Provinz',
  countryLabel:         'Land',
  postCodeLabel:        'Postleitzahl',
  cityLabel:            'Stadt',
  streetLabel:          'Adresse',
  telephoneLabel:       'Telefon',
  defaultBilling:       'Standard-Rechnungsadresse',
  defaultShipping:      'Standard-Lieferadresse',
  notDefaultBilling:    'Sie haben keine Standard-Rechnungsadresse in Ihrem Adressbuch.',
  notDefaultShipping:   'Sie haben keine Standard-Lieferadresse in Ihrem Adressbuch.',
  notOtherAddresses:    'Er hat keine weiteren Adressen in seinem Adressbuch.',
  notbillingAddresses:  'Sie haben noch keine Rechnungsadresse hinzugefügt.',
  notshippingAddresses: 'Sie haben noch keine Lieferadresse hinzugefügt'
}

export default addresses;
