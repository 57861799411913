import { newsletter as baseNewsletter } from '@themes/base/frontend/locales/de/newsletter';

export const newsletter = {
  ...baseNewsletter,
  subscribe:          'Abonnieren Sie unseren Newsletter',
  subscribeInfo:      'Seien Sie der Erste, der über unsere Neuigkeiten und Werbeaktionen informiert wird',
  emailPlaceholder:   'Geben Sie Ihre E-Mail ein',
  send:               'Senden'
}

export default newsletter;
