import { ThirdPartyScript }         from '@frontend/components/third-party-scripts';
import { connect }                  from 'react-redux';
import { showCookies,getCookies }   from '@frontend/actions/cookies';
import Cookies                      from '@frontend/components/third-party-scripts/cookies/component';
import checkServer                  from '@frontend/util/checkServer';

export class CookiesScript extends ThirdPartyScript {

    allowed = true

    static mapStateToProps({cookies}){
        return { cookies };
    }

    onCookies() {
        if(!this.props.getCookies()['cookies-allowed'] && !checkServer()){
            this.props.showCookies();
        }
    }

    render() {
        const { cookies } = this.props;

        if(!this.context.scriptManager.cookiesManagement) return null;

        this.subscribe();

        return (
            (cookies.visible && !checkServer()) &&
                <Cookies />
        )
    }
}

export default connect(CookiesScript.mapStateToProps, {showCookies,getCookies})(CookiesScript);