import { HttpLink }            from 'apollo-link-http';
import { InMemoryCache }       from 'apollo-cache-inmemory';
import { ApolloClient }        from 'apollo-client';
import checkServer             from '@frontend/util/checkServer';
import { onError }             from "apollo-link-error";
import { ApolloLink }          from 'apollo-link';
import fetch                   from 'isomorphic-fetch';
import { notifySentry }        from '@frontend/services/Sentry';

const link = onError(({graphQLErrors, networkError,operation={}}) => {
  const { operationName=''} = operation;

  if (graphQLErrors)
    graphQLErrors.map((details) => {
      const { message, locations } = details
      console.log(`[GraphQL error]: ${operationName} Message: ${message},  Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(details)}`
      )
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const initApollo = (uri) => {
  let url      = new URL(uri)
  url.host     = (checkServer() ? "127.0.0.1" : url.host)
  url.protocol = (checkServer() ? "http"      : url.protocol)
  return new ApolloClient({
    connectToDevTools: !checkServer(),
    onError: ({ networkError, graphQLErrors }) => {
      console.log('GraphQLErrors' + JSON.stringify(graphQLErrors));
      console.log('NetworkError' + JSON.stringify(networkError));
      notifySentry(new Error('GraphQLErrors' + JSON.stringify(graphQLErrors) + ' - NetworkError' + JSON.stringify(networkError)));
    },
    //ssrMode: checkServer(), // Disables forceFetch on the server (so queries are only run once)
    

    link: ApolloLink.from([link, new HttpLink({
      fetch,
      uri: url.toString(), // Server URL (must be absolute)
      credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
    })]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: (checkServer() ? "no-cache" : "cache-first"),
        errorPolicy : 'all'
      },
      query: {
        fetchPolicy: (checkServer() ? "no-cache" : "cache-first"),
        errorPolicy : 'all'
      },
    },
    cache: new InMemoryCache({ addTypename: false })
  });
}

export default initApollo;
