import { ScriptManager } from '@frontend/components/third-party-scripts';
import checkServer       from '@frontend/util/checkServer';

const initScriptManager = (context={}) => {
    if(checkServer()){
        return new ScriptManager(null,context);
    }else{
        if(!window.scriptManager){
            window.scriptManager = new ScriptManager(window.__SCRIPT_EVENTS_QUEUE__,context)
        }
        return  window.scriptManager;
    }
    //return !checkServer() && window && window.__SCRIPT_EVENTS_QUEUE__ ? new ScriptManager(window.__SCRIPT_EVENTS_QUEUE__) : null;
}

export default initScriptManager;
