let storeConfig = {
  storeId: null,
  storeCode: '',
  websiteId: null,
  shopInfo : {
    shop_name:'',
    shop_phone:'',
    shop_email:'',
    shop_street:''
  },
  googleMapKey: '',
  googleRecaptchaKey: '',
  galleryTypes: {},
  checkoutURL: ''
 }

const reducers = (state = storeConfig, action) => {
  switch(action.type) {
    case "SET_STORE_CONFIG":
      return action.config;
    default:
      return state;
  }
}

export default reducers;
