let looks = null;

const reducers = (state = looks, action) => {
  switch(action.type) {
    case "SET_LOOKS":
      return action.looks;
    default:
      return state;
  }
}

export default reducers;
