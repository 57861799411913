export const days = {
  monday:     "Lundi",
  tuesday:    "Mardi",
  wednesday:  "Mercredi",
  thursday:   "Jeudi",
  friday:     "Vendredi",
  saturday:   "Samedi",
  sunday:     "Dimanche",
  mon:        "L",
  tue:        "M",
  wed:        "X",
  thu:        "J",
  fri:        "V",
  sat:        "S",
  sun:        "D"
}

export default days;
