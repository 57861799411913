export const contact = {
  location: 'Where to find us',
  info: 'Contact us',
  form: 'Write to us',
  name: "Name and surname(s)",
  birth: "Date of birth",
  phone: "Telephone number",
  town:  "Town/City",
  region:"Province",
  email: "Email address",
}

export default contact;
