import getDeployVersionQuery from '@frontend/services/queries/deploy/get-version.graphql';

export const getDeployVersion = (context,variables) =>{
  return async (dispatch) => {
    const { client } = context;
    const { data:{ getDeployVersion: version }} = await client.query({
        query: getDeployVersionQuery,
        fetchPolicy: 'no-cache'
    });

    return version;
  }
}