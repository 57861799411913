export const signIn = {
  tagTitle:               'Anmeldung',
  metaTitle:              'Anmeldung',
  pageTitle:              'Neoaktive Benutzer',
  sectionDescription:     'Wenn Sie ein Konto haben, melden Sie sich mit Ihrer E-Mail-Adresse an',
  buttonLabel:            'einloggen',
  email:                  'E-Mail-Adresse',
  password:               'Passwort',
  newCustomer:            'BENUTZERKONTO ANLEGEN',
  createAccount:          'KONTO EINRICHTEN',
  createAccountBenefits:  'Erstellen Sie Ihr Benutzerkonto in Neoactives',
  signIn:                 'Anmeldung',
  forgottenPassword:      'Ich habe mein Passwort vergessen'
}

export default signIn;
