
import {
  setCookie as setNookie,
  destroyCookie,
  parseCookies
}                             from 'nookies'

export const getCookies = () => {
  return () => {
    let cookies = parseCookies();
    return cookies;
  }
}        

export const setCookie = (name, value,maxAge=3600) => {
  return async () => {
    setNookie({},name,value, {
        maxAge: maxAge || 60 * 60,
        path: '/',
    });
  }
}

export const showCookies = () => {
  return {
    type: "SHOW_COOKIES"
  }
}

export const hideCookies = () => {
  return {
    type: "HIDE_COOKIES"
  }
}

export const showConfig = () => {
  return {
    type: "SHOW_COOKIES_CONFIG"
  }
}

export const hideConfig = () => {
  return {
    type: "HIDE_COOKIES_CONFIG"
  }
}