export const neoactivesStaticTables = {
  mineral:{
    bones: 'Bones',
    connectiveTissue: 'Connective tissue',
    immuneSystem: 'Immune system',
    oxidativeDamage: 'Oxidative damage',
    bloodGlucose: 'Blood glucose',
    redBloodCells: 'Red blood cells',
    tirednessFatigue: 'Tiredness and fatigue',
    thyroid: 'Thyroid',
    acidBase: 'Acid base',
    testosterone: 'Testosterone',
    psychologicalFunction: 'Psychological function',
    calcium: 'Calcium',
    magnesium: 'Magnesium',
    zinc: 'Zinc',
    iron: 'Iron',
    manganese: 'Manganese',
    copper: 'Cooper',
    molybdenum: 'Molybdenum',
    iodine: 'Iodine',
    selenium: 'Selenium',
    chrome: 'Chrome',
    boron: 'Boron'
  },
  vitamine:{
    vitA: 'Vit. A',
    vitB: 'Vit. B',
    vitB1: 'Vit. B1',
    vitB2: 'Vit. B2',
    vitB5: 'Vit. B5',
    vitB6: 'Vit. B6',
    vitB12: 'Vit. B12',
    vitC: 'Vit. C',
    vitD3: 'Vit. D3',
    vitE: 'Vit. E',
    vitK2: 'Vit. K2',
    biotin: 'Biotin',
    choline: 'Choline',
    folicAcid: 'Folic acid',
    tirednessFatigue: 'Tiredness and fatigue',
    hormonalActivity: 'Hormonal activity',
    intellectualPerformance: 'Intellectual performance',
    nervousSystem: 'Nervous system',
    hairSkin: 'Skin and hair',
    lipids: 'Lipids',
    liverFunction: 'Liver function',
    homocysteine: 'Homocysteine',
    immuneSystem: 'Immune system',
    vision: 'Vision',
    oxidativeDamage: 'Oxidative damage',
    bones: 'Bones',
    muscles: 'Muscles'
  }
}

export default neoactivesStaticTables;
