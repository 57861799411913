
import EzComponent  from '@frontend/components/EzComponent';

class LdJson extends EzComponent {


    render(){
        return(<script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: JSON.stringify(this.props.data) }}
        />)
    }

}
export default LdJson;