import { myAccount as storeMyAccount }    from '@themes/store/frontend/locales/en/user/my-account';
import wishlistSection                    from '@frontend/locales/en/user/my-account/wishlist-section';
import pdf                                from '@frontend/locales/en/user/my-account/pdf';

export const myAccount = {
  ...storeMyAccount,
  wishlistSection,
  pdf,
  accountInfo:        'Contact information',
  welcome:            'hello %1!',
  editCustomer:       'Edit password/account information**',
  addAddress:         'Add address',
  editCustomer:       'edit profile',
  aditionalEntries: 'Other addresses'
}

export default myAccount;
