import {de as baseDe}       from '@themes/base/frontend/locales/de';
import productView          from '@frontend/locales/de/product-view';
import accountBanner        from '@frontend/locales/de/account-banner';
import miniCart             from '@frontend/locales/de/mini-cart';
import user                 from '@frontend/locales/de/user';
import coupons              from '@frontend/locales/de/coupons';
import filters              from '@frontend/locales/de/filters';
import footerNewsletter     from '@frontend/locales/de/footer-newsletter';
import footer               from '@frontend/locales/de/footer';
import addToWishlist        from '@frontend/locales/de/add-to-wishlist';
import deleteFromWishlist   from '@frontend/locales/de/delete-from-wishlist';
import categoryPage         from '@frontend/locales/de/category-page';
import checkout             from '@frontend/locales/de/checkout';
import relatedProducts      from '@frontend/locales/de/related-products';
import bestSellingProducts  from '@frontend/locales/de/best-selling-products';
import accountMenu          from '@frontend/locales/de/account-menu';
import countries            from '@frontend/locales/de/countries';
import genders              from '@frontend/locales/de/genders';
import regions              from '@frontend/locales/de/regions';
import contact              from '@frontend/locales/de/contact';
import cookies              from '@frontend/locales/de/cookies';
import general              from '@frontend/locales/de/general';

delete baseDe.mail;

export const de = {
  ...baseDe,
  productView,
  accountBanner,
  miniCart,
  user,
  coupons,
  filters,
  footerNewsletter,
  footer,
  addToWishlist,
  deleteFromWishlist,
  categoryPage,
  checkout,
  relatedProducts,
  bestSellingProducts,
  accountMenu,
  countries,
  genders,
  regions,
  contact,
  cookies,
  general
}


/**
 * FROM BASE
 *
 * langCode
 * contact
 * searchBar
 * navigationMenu
 * contact,
 * share,
 * days
 * months
 * searchResult
 * days
 * months
 *
 */

/**
* REMOVED
*
* mail
*/

/**
 * EXTENDED
 *
 * validator
 * newsletter
 * general
 *
 */

export default de;
