
import { ThirdPartyScript } from '@frontend/components/third-party-scripts';
import { connect } from 'react-redux';

export class TrustedReviewsWidget extends ThirdPartyScript {



    static mapStateToProps({ storeConfig }) {
        return {
            storeConfiguration: storeConfig,
        }
    }

    onProductPage(product){
        let productSkus;
        if (product.is_configurable){
            let productChilds = JSON.parse(product.product_childs);
            let item;
            productSkus = [];
            Object.keys(productChilds).map((productId)=>{
                item = productChilds[productId];
                productSkus.push(item.sku);
            })
        }else{
            productSkus = [`${product.sku}`];
        }
        window._tsProductReviewsConfig = {
            tsid: 'X2855779755726B07FA50B085C4AA9769',
            variant: 'productreviews',
            theme: 'light',
            element: '.data-block.reviews.actions',
            locale: 'es_ES',
            apiServer: '//cdn1.api.trustedshops.com/',
            richSnippets: 'on',
            borderColor: '#C8B9A5',
            backgroundColor: '#f2f2f2',
            sku: productSkus,
            starSize: '14px',
            starColor: '#FFDC0F',
            maxHeight: '0px',
            commentBorderColor: '#DAD9D5'
        };

        var scripts = document.getElementsByTagName('SCRIPT'),
            me = scripts[scripts.length - 1];
        var _ts = document.createElement('SCRIPT');
        _ts.type = 'text/javascript';
        _ts.async = true;
        _ts.charset = 'utf-8';
        _ts.src = 'https://widgets.trustedshops.com/reviews/tsSticker/tsProductSticker.js';
        me.parentNode.insertBefore(_ts, me);
        _tsProductReviewsConfig.script = _ts;
    }

    render() {
        this.subscribe()
        return null;
    }


}


export default connect(TrustedReviewsWidget.mapStateToProps, {})(TrustedReviewsWidget);