import { ThirdPartyScript   }   from '@frontend/components/third-party-scripts';
import { connect }              from 'react-redux';
import _find                    from 'lodash/find';
import _keyBy                   from 'lodash/keyBy';
import _filter                  from 'lodash/filter';


import { setPageType }          from '@frontend/actions/page';
import { setBreadcrumbs }       from '@frontend/actions/breadcrumbs';


export class Breadcrumbs extends ThirdPartyScript {

    static mapStateToProps({ storeConfig }) {
        return {
            additionalData: storeConfig.additionalData
        }
    }

    state = {
        ...this.state,
        lastCategoryTree: '',
        lastCategory:     ''
    }

    onProductPage(product) {
        if(product){
            const { lastCategoryTree,lastCategory} = this.state;
            const { additionalData }               = this.props;
            let breadcrumbs                        = []
            let breadcrumbsList                    = product.breadcrumbs

            this.props.setPageType('product');

            if(additionalData && additionalData.breadcrumbsExcludedCategories){
                breadcrumbsList = breadcrumbsList.filter(breadcrumb => {
                    const array          = breadcrumb.path.split('/');
                    const lastCategoryId = array[array.length - 1];
                    const excludedArray  = additionalData.breadcrumbsExcludedCategories.replace(/ /g,'').split(',');

                    return  !excludedArray.includes(lastCategoryId);
                })
            }

            const breadcrumbsObject = _keyBy(breadcrumbsList,'path');

            if(lastCategoryTree && breadcrumbsObject[lastCategoryTree]){
                breadcrumbs = breadcrumbsObject[lastCategoryTree].data;
            }else{
                breadcrumbs = breadcrumbsList.reduce((a, b) => a.size > b.size ? a : b ).data;
            }

            this.props.setBreadcrumbs(breadcrumbs);
        }
    }

    onCategoryPage(category) {
        if(category){
            const { lastCategory } = this.state;

            this.props.setPageType('category');
            this.props.setBreadcrumbs(category.breadcrumbs);

            if(lastCategory !== category.category_id){
                this.setState({
                    lastCategory:     category.category_id,
                    lastCategoryTree: category.tree
                });
            }
        }
    }

    onCmsPage(cms) {
        if(cms){
            this.props.setPageType('cms');
            this.props.setBreadcrumbs([])
        }
    }

    onPrivatePage(privatePage) {
        if(privatePage){
            this.props.setBreadcrumbs([])
        }
    }

    render() {
        this.subscribe()
        return null;
    }
}


export default connect(Breadcrumbs.mapStateToProps, {setPageType,setBreadcrumbs})(Breadcrumbs);




