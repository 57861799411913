export const contactInfo = {
  tagTitle:               'Kontaktinformationen',
  metaTitle:              'Kontaktinformationen',
  sectionTitle:           'Kontaktinformationen',
  age:                    '1 Jahr %2 Monate',
  male:                   'Mann',
  female:                 'Frau',
  firstnameLabel:         'Name',
  lastnameLabel:          'Nachname',
  ageLabel:               'Alter',
  taxvatLabel:            'DNI',
  genderLabel:            'Geschlecht',
  birthdayLabel:          'Geburtsdatum',
  dobLabel:               'Geburtsdatum',
  associatedClinicLabel:  'Assoziierte Klinik',
  languageLabel:          'Sprache',
  telephoneLabel:         'Telefon',
  regionLabel:            'Provinz',
  countryLabel:           'Land',
  edit:                   'Benutzer bearbeiten',
  save:                   'Änderungen speichern',
  cancel:                 'Abbrechen'
}

export default contactInfo;
