export const reviews = {
  review: 'Reviews: ',
  count: '%1 reviews',
  showMore: 'see more',
  showLess: 'see less',
  addReview: {
    button: 'Leave Review',
    title: 'Leave your review',
    send: 'Submit'
  }
}

export default reviews;
