export const cookies = {
  theTeam:            "L'équipe de ",
  andOtherCompanies:  " et d'autres sociétés utilisent des cookies afin de vous offrir une expérience d'achat personnalisée. Pour continuer à naviguer, vous devez accepter tous les cookies en cliquant sur le bouton 'Accepter'. Vous pouvez également modifier votre sélection dans ce ",
  forMoreInfo:        " Pour plus d'informations, vous pouvez consulter notre ",
  link:               "lien",
  acceptButton:       "Accepter",
  cookiesPolicy:      "Politique en matière de cookies",
  configuration:      "Paramètres des cookies",
  seeCookiesPolicy:   "Voir la politique en matière de cookies",
  updateConfig:       "Paramètres des cookies",
  functional: {
    title:  'Cookies fonctionnels/techniques',
    text:   "Nécessaire pour afficher correctement la page web et assurer le bon fonctionnement du site. Ils vous permettent de naviguer sur notre site web et d'utiliser les différentes options ou services qu'il propose. Grâce à eux, nous pouvons, par exemple, contrôler le trafic et la communication des données, vous identifier pendant la session, vous donner accès à des zones réservées et utiliser des dispositifs de sécurité pendant votre navigation."
  },
  analytical: {
    title:  'Cookies analytiques',
    text:   "Elles sont utilisées pour analyser le comportement des utilisateurs sous forme agrégée et anonyme, notamment le nombre de visiteurs du site web et des différentes pages internes, l'origine de la visite, le jour et l'heure, la plate-forme, les mots de recherche utilisés par un utilisateur pour trouver le contenu souhaité. Ils nous fournissent des informations statistiques sur le comportement des différents utilisateurs sur notre site web afin d'apporter des améliorations basées sur l'analyse des données relatives à l'utilisation des produits et/ou services sur le site web."
  },
  advertising: {
    title:  'Cookies publicitaires',
    text:   "Ils nous permettent de gérer les espaces publicitaires inclus sur notre site web, en fonction de critères tels que le contenu présenté ou la fréquence d'affichage des publicités."
  },
  basic: {
    title:  'Cookies essentiels',
    text:   "Nous avons besoin de ces cookies pour que notre site web puisse fonctionner correctement. Il nous aide à prévenir toute erreur de navigation ou à vous permettre d'effectuer un achat. De cette façon, nous pouvons améliorer la qualité de notre service."
  },
  acceptSelection:    "Accepter la sélection",
  rejectAll:          "Rejeter tout",
  session: {
    oneYear:          "Session / 1 an",
    halfHourTwoYears: "De 30 minutes à 2 ans"
  }
}

export default cookies;
