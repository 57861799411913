export const mail = {
  label:             'E-Mail',
  subjectDesc:       'Asunto:',
  subjectInfo:       'Wir sind Ezenit in vollem Gange, haha',
  destinationDesc:   'A:',
  destinationInfo:   'Paco Johnes',
  copyDesc:          'CC:',
  copyInfo:          'Paca Garte, Ana Tomía, Soila Cerdà',
  mailText:          'Hallo Dolores Delano, ich gebe Ihnen eine E-Mail mit dem, was Sie Marco Gol gebeten haben, Grüsse, Igor Dito',
  buttonDownload:    'Dateien herunterladen',
  attached:          'Anhang.jpg',
  buttonDraft:       'Neuer Entwurf'
}

export default mail;
