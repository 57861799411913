const addToCartModal = { 
  showModal: false,
  productName:'',
  finalPrice:''
} ;

const reducers = (state = addToCartModal, action) => {
  switch(action.type) {
    case "OPEN_ADD_TO_CART_MODAL":
      return { 
        showModal: true,
        productName:action.productName,
        finalPrice:action.finalPrice
      };
    case "CLOSE_ADD_TO_CART_MODAL":
      return addToCartModal;
    default:
      return state;
  }
}

export default reducers;
