import _cloneDeep                               from 'lodash/cloneDeep';
import _set                                     from 'lodash/set';

let mapStores = {
  stores: [],
  currentStore: {}
};

const reducers = (state = mapStores, action) => {
  switch(action.type) {
    case "SET_MAP_STORES":{
      let newState              = _cloneDeep(state);
      _set(newState,'stores',action.mapStores)
      return newState;
    }
    case "SET_CURRENT_STORE":{
      let newState              = _cloneDeep(state);
      _set(newState,'currentStore',action.data)
      return newState;
    }
    default:
      return state;
  }
}

export default reducers;
