export const rma = {
   notAvailable:    "The return cannot be processed automatically, please contact customer service.",
   notAvailableMsg: "You can write us an email to ",
   title:           "Return",
   reason:          "Reason for return",
   qtyError:        "Máx units: ",
   product_title:   "Products available for return",
   submit:          "Request return",
   submit_result:   "We have registered your request for return. You will soon receive an email with all the information",
   cant_rma:        "It is not possible to generate a return through the web, please contact our customer service department.",
   error_items:     "You must select at least one product to return"
}

export default rma;
