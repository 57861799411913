export const months = {
    month0:     "Januar",
    month1:     "Februar",
    month2:     "März",
    month3:     "April",
    month4:     "Mai",
    month5:     "Juni",
    month6:     "Juli",
    month7:     "August",
    month8:     "September",
    month9:     "Oktober",
    month10:    "November",
    month11:    "Dezember",
    january:    "Januar",
    february:   "Februar",
    march:      "März",
    april:      "April",
    may:        "Mai",
    june:       "Juni",
    july:       "Juli",
    august:     "August",
    september:  "September",
    october:    "Oktober",
    november:   "November",
    december:   "Dezember"
  }

export default months;
