export const footerNewsletter = {
  recaptchaError:     'Es ist notwendig, das reCaptcha zu validieren',
  recaptchaError:     'Es ist notwendig, die Datenschutzerklärung zu akzeptieren.',
  subscribe:          'Newsletter',
  emailPlaceholder:   'email',
  emailEmpty:         'E-Mail erforderlich',
  subscriptionError:  'Fehler beim Abonnieren des Newsletters. Überprüfen Sie Ihre E-Mail und versuchen Sie es später noch einmal.',
  send:               'Abonnieren Sie',
  accept:             'Ich akzeptiere die ',
  privacyLink:        'AGB'
}

export default footerNewsletter;
