export const info = {
  vegan:                  'vegan',
  inStock:                'In stock',
  notStock:               'Out of stock',
  qty:                    'Quantity',
  description:            'Product description::',
  composition:            'Composition',
  howToUse:               'Instructions for use: ',
  howToUse_mobile:        'Instructions for use',
  help:                   'We’re here to help: ',
  help_mobile:            'We’re here to help',
  composition:            'Composition',
  opinions:               'Reviews',
  operatingInstructions:  'Instructions for use',
  shippingReturns:        'Deliveries and returns',
  bundleIncludes:         'The product includes: ',
  selectValue:            'Select a value',
  chooseVariation:        'Choose a product: ',
  noOptionChosen:         'Please select an option',
  just:                   'There are only ',
  units_left:             ' units left!',
  preOrderWarning:        'This product is available for reservation only. If you make the purchase now we will send it to you from March 8.'
}

export default info;
