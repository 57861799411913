import { ZandmanApp as BaseZandmanApp } from '@themes/base/frontend/pages/_app';
import { setStoreConfig }               from '@frontend/actions';
import { getStoreCountries }            from '@frontend/actions';
import { getUserData }                  from '@frontend/actions/user';
import { getCartData }                  from '@frontend/actions/cart';
import i18nService                      from "@frontend/services/i18n";
import locales                          from '@frontend/locales/index';
import { ThirdPartyScriptManager }      from '@frontend/components/third-party-scripts';
import { setIsApp }                     from '@frontend/actions/is-app';
import { getDeployVersion }             from '@frontend/actions/deploy/get-version';
import Breadcrumbs                      from '@frontend/components/third-party-scripts/tracking/breadcrumbs';
import UrlBuilder                       from '@frontend/services/UrlBuilder';
import _get                             from 'lodash/get';
import _toInteger                       from 'lodash/toInteger';
import _split                           from 'lodash/split';
import { setCookie,parseCookies }       from 'nookies'
import checkServer                      from '@frontend/util/checkServer';
import appSettings                      from '@frontend/settings/app';
import { deployVersion }                from '@frontend/deploy-version';

import '@frontend/less/index.less';

class ZandmanApp extends BaseZandmanApp {

  static async getInitialProps(appContext) {
    let baseProps = await super.getInitialProps(appContext);
    if (!baseProps) {
        return false;
    }

    const deployVersionCookie = _get(appContext.ctx.cookies,'deploy-version');
    const backendVersion      = !deployVersionCookie ? await appContext.ctx.store.dispatch(getDeployVersion(appContext.ctx)): deployVersionCookie;

    return { ...baseProps, storeConfig: appContext.ctx.storeConfig,backendVersion };
  }

    static async updateAppContext(appContext) {
        await super.updateAppContext(appContext);
        appContext.ctx.storeConfig = await this.getStoreConfig(appContext);
        appContext.ctx.router = new UrlBuilder({ currentStore: appContext.ctx.storeConfig.currentStore });
    }

    static async getStoreConfig(appContext) {
        // get store config on ssr or client
        let storeConfig = null;

        if (appContext.ctx.req && appContext.ctx.req.storeConfig) {
            storeConfig = appContext.ctx.req.storeConfig;
            await appContext.ctx.store.dispatch(setStoreConfig(storeConfig));
        }

        if ((!storeConfig || !storeConfig.storeId) && appContext.ctx.store.getState().storeConfig.storeId) {
            storeConfig = appContext.ctx.store.getState().storeConfig;
        }
        return storeConfig;
    }

    initServices() {
        const { storeConfig: { langCode } } = this.props;
        const baseServices = super.initServices();
        const i18n = new i18nService(locales[langCode]);
        const router = new UrlBuilder({ currentStore: this.props.storeConfig.currentStore });

        return { ...baseServices, i18n, router };
    }

    renderThirdPartyScripts() {
        return (
            <ThirdPartyScriptManager>
                <Breadcrumbs />
            </ThirdPartyScriptManager>
        )
    }

    initRedux(store, client, cookies) {
        const { storeConfig, urlPath }  = this.props;
        const appPath                   = _get(appSettings, 'appParam', '');

        const [ path, stringParams ] = _split(_split(urlPath, '?'));
        const urlParams = UrlBuilder.getUrlParams(stringParams);
        const customApp = !!_toInteger(_get(urlParams, appPath));

        super.initRedux(store, client, cookies);

        store.dispatch(setStoreConfig(storeConfig));
        store.dispatch(getStoreCountries({ client }, { storeId: parseInt(storeConfig.storeId), websiteId: parseInt(storeConfig.websiteId) }));
        if (customApp){
            store.dispatch(setIsApp({ client }, customApp));
        }
    }

    frontendReduxActions(store, client, cookies) {
        const { storeConfig } = this.props;
        const { storeId }     = storeConfig;
        const tokenCookie     = _get(cookies, `token-${storeId}`);
        const cartCookie      = _get(cookies, `cart-${storeId}`);

        // user info
        if (tokenCookie && (!store.getState().user || !store.getState().user.id)) {
            store.dispatch(getUserData({ client }, { token: tokenCookie, storeId: parseInt(storeId) }));
        } else {
            // cart info
            if (cartCookie) {
                store.dispatch(getCartData({ client }, { quoteId: cartCookie, storeId: parseInt(storeId), customerId: null }));
            }
        }
    }


  checkVersion(){
    const cookies = parseCookies({});

    if(!checkServer() && !_get(cookies,'deploy-version') && this.props.backendVersion !== deployVersion){

      setCookie({},'deploy-version',this.props.backendVersion, {
        maxAge: 3600,
        path: '/',
      });

      window.location.reload();
    }
  }

  initApp(reduxStore){
    this.checkVersion();
    super.initApp(reduxStore);
  }
}

export default ZandmanApp;
export { ZandmanApp };
