let postCollection = {};

const reducers = (state = postCollection, action) => {
  switch(action.type) {
    case "SET_POST_COLLECTION":
      return action.postCollection;
    default:
      return state;
  }
}

export default reducers;
