import {GoogleTagManager as 
    StoreGoogleTagManager }       from '@themes/store/frontend/components/third-party-scripts/seo/google-tag-manager';

import { connect } from 'react-redux';
import checkServer from '@frontend/util/checkServer';

export class GoogleTagManager extends StoreGoogleTagManager {

    onCookies({ allowed }) { 
        this.allowed = true;
        this.setState({ allowed: true });
    }

    onOrderSuccess(order) {
        const products = this.getProductArray(order.items);
        order.total_without_shipping = (parseFloat(order.total) - parseFloat(order.shipping)).toString();
        window.dataLayer.push({
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        'id': order.increment_id,                         // Transaction ID. Required for purchases and refunds.
                        'revenue': order.total_without_shipping,                     // Total transaction value (incl. tax and shipping)
                        'shipping': order.shipping
                    },
                    'products': products
                }
            }
        })
        window.dataLayer.push({'event':"etnia-purchase"});
    }

    onLoaded(){
        return null;
    }

    loadBeforeCookies(){
        super.onLoaded();
    }

    render() {
        this.subscribe()
        if (!checkServer() && !this.loaded ){
            this.loadBeforeCookies()
            this.loaded = true
        }
        
        return null;
    }

}


export default connect(GoogleTagManager.mapStateToProps, {})(GoogleTagManager);




