export const stock = {
    noStock: "No stock currently available.",
    outOfStock: "out of stock",
    outOfStockProduct: "Product out of stock",
    mobileMessage: "Enter your email address and we’ll let you know when this product becomes available",
    mobilePlaceholder: "email address",
    sendProductList: "let me know when this product is back in stock",
    mobileSend: "submit",
    placeholder: "enter your email address and we’ll let you know when this product becomes available",
    giveMail: "Leave us your email address and we’ll let you know when this product comes back into stock.",
    invalidEmail: "Invalid email address entered, please try another email address.",
    send: "let me know",
    mobileSendWarn: "let me know"
}

export default stock;
