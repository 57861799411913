import React,{Component} from 'react';

import checkServer       from '@frontend/util/checkServer';

class ReduxHydrate extends Component{

  render(){
    return(
        checkServer() ?
          <script dangerouslySetInnerHTML={{ __html: `window.__NEXT_REDUX_STORE__ = ${JSON.stringify(this.props.store.getState())}` }}></script>
          : null

    )
  }
}

export default ReduxHydrate;
