export const wishlistSection = {
  title:              'wish list',
  welcomeMessage:     'Welcome to your wish list.',
  notificationMessage:'You can enable notifications to be notified when one of your favourites is on offer.',
  enableNotification: 'enable notifications',
  summarySubtitle:    'welcome to your wish list',
  youHave:            'you have',
  productInList:      'products on your list',
  total:              'total',
  buyNow:             'buy now',
  advantage:          'make the most of our deals',
  advantageSecond:    'to take home your favourite products',
  keepBuying:         'continue shopping',
  shareList:          'share my wish list'
}

export default wishlistSection;
