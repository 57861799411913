export const safeBuy = {
    label:                'secure shopping',
    paymentMethodsTitle:  'Various payment methods',
    paymentMethods1:      'Visa and mastercard, paypal, bizum',
    paymentMethods2:      'Payment in installments in 3, 6 or 12 months',
    freeShippingTitle:    'Free shippings',
    freeShipping1:        'From 35€ (peninsula, Baleares and Portugal)',
    freeShipping2:        'From 60€ (Canarias, Ceuta and Melilla)',
    freeShipping3:        'Lovely clients from 25€',
    freeShipping4:        'EU - consult in general conditions',
    deliveryTimeTitle:    'Ships in 24-48h* from 4.20',
    deliveryTime1:        'It can be extended up to 5 bussiness days in ',
    deliveryTime2:        'promotion and post promotion periods.',
    deliveryTime3:        'Shipments to the US'
  }
  
  export default safeBuy;
  