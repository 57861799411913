import EzComponent     from '@frontend/components/EzComponent';
import { connect }     from 'react-redux';
import dynamic         from 'next/dynamic';

import                 '@frontend/components/third-party-scripts/cookies/component/info/index.less';

const Link = dynamic(()=> import('next/link'));


class CookieInfo extends EzComponent {

  static mapStateToProps({storeConfig:{cookiesData, additional_data_array}}) {
    return {
      cookiesData,
      additionalData: additional_data_array
    }
  }

  render() {
    const {
      openConfig,
      acceptAll,
      cookiesData:{
        privacyPolicyUrl,
        websiteName
      }
    } = this.props;
    
    return (
      <>
        <button className="cookie-consent-accept-button" onClick={acceptAll}>{this.i18n.t('cookies.acceptButton')}</button>
        <div className="cookie-consent-text">
          <span>{this.i18n.t('cookies.theTeam')}</span>
          <span className="bold">{websiteName}</span>
          <span>{this.i18n.t('cookies.andOtherCompanies')}</span>
          <span className="bold open-config" onClick={openConfig}>{this.i18n.t('cookies.link')}</span>.
          <span>{this.i18n.t('cookies.forMoreInfo')}</span>
          {
            !!privacyPolicyUrl &&
              <Link {...this.router.get(privacyPolicyUrl)}>
                <a target="_blank" className="bold cookie-consent-policy" >{this.i18n.t('cookies.cookiesPolicy')}</a>
              </Link>
          }.
        </div>
      </>
    );
  }
}

export default connect(CookieInfo.mapStateToProps)(CookieInfo)