const searchResults = {
  items:            [],
  num:              0,
  hasMoreProducts : false,
  searchValue:      ''
};

const reducers = (state = searchResults, action) => {
  switch(action.type) {
    case "SET_SEARCH_RESULTS":
      const { searchResults } = action;
      return {...state,...searchResults,forceHide: false};
    case "DELETE_SEARCH_RESULTS":
      return {
        items:            [],
        num:              0,
        hasMoreProducts : false,
        searchValue:      '',
        forceHide:        false
      };
    case "SET_SEARCH_VALUE":
      const { searchValue } = action;
      return {...state,searchValue,forceHide: false};
    case "DELETE_SEARCH_VALUE":
      return {...state,searchValue:'',forceHide: false};
    case "CLOSE_SEARCH_BAR":
      return {...state,
        showSearchBar: false,
        forceHide: true
      };
      break;
    case "OPEN_SEARCH_BAR":
      return {...state,
        showSearchBar: true,
        forceHide: false
      };
      break;
    default:
      return state;
  }
}

export default reducers;
