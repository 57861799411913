export const createAccount = {
  tagTitle:         'Créez votre compte',
  metaTitle:        'Créez votre compte',
  success:          'Compte créé avec succès',
  pageTitle:        'Nouvel utilisateur',
  pageDescription:  'Créer un nouveau compte client',
  firstName:        'Nom',
  lastName:         'Nom de famille',
  birthday:         'Date de naissance',
  subscribe:        "S'abonner à notre newsletter",
  gender:           'Sexe',
  nif:              'NIF/NIE/CIF',
  address:          'Adresse',
  telephone:        'Téléphone',
  city:             'Population',
  postCode:         'C.P.',
  region:           'Province',
  country:          'Pays',
  personalInfo:     'Informations personnelles',
  accessInfo:       "Informations sur l'accès",
  email:            'Courriel :',
  password:         'Mot de passe',
  confirmPassword:  'Confirmer le mot de passe',
  buttonLabel:      'Créer un compte',
  linkSignin:       'Je suis déjà un utilisateur',
  accept:           "J'accepte le ",
  privacyLink:      'politique de confidentialité',
  newsletter:       'Inscrivez-moi à la newsletter'
}

export default createAccount;
