const activeInfoBlock = '';

const reducers = (state = activeInfoBlock, action) => {
  switch(action.type) {
    case "SET_ACTIVE_INFO_BLOCK":
      const activeInfoBlock = action.activeInfoBlock;
      return activeInfoBlock;
    case "SET_INITIAL_ACTIVE_INFO_BLOCK":
      const initialActiveInfoBlock = state ? state : action.activeInfoBlock;
      return initialActiveInfoBlock;
    default:
      return state;
  }
}

export default reducers;
