import { ThirdPartyScript }         from '@frontend/components/third-party-scripts';
import { connect }                  from 'react-redux';
import Router                       from 'next/router';
import _get                         from 'lodash/get';
import _split                       from 'lodash/split';
import _last                        from 'lodash/last';
import { addUtmCampaignToQuote }    from '@frontend/actions/add-utm-campaign-to-quote';
import { setCookie,getCookies }     from '@frontend/actions/cookies';

/**
 * Set Senderglobal url parameters in cookies. Call magento endpoint to add utm_campaign in quote when add to cart
 */
export class Senderglobal extends ThirdPartyScript {
    
  /**
   * Get url params by param name 
   */
  getParameterByName = (name) => {
    const params            = new URLSearchParams(_last(_split(_get(Router,'router.asPath'),'?')));
    return params.get(name);
  }

  /**
  * If there are utm_source and utm_campaign params, set this in cookies
  */
  onLoaded() {
    const utmSource = this.getParameterByName("utm_source");
    const utmCampaign = this.getParameterByName("utm_campaign");

    if(!!utmSource && !!utmCampaign){
      this.props.setCookie("utm_source",utmSource);
      this.props.setCookie("utm_campaign",utmCampaign);
    }
  }

  /**
   * When added product get cookies utm_source and utm_campaign if it have values to
   * call addUtmCampaignToQuote action.
   * and this action get cookies utm_source and utm_campaign if it have values to call magento endpoint
   */
  onAddedToCart({ product, cart }) {
    const utmSource   = _get(this.props.getCookies(),"utm_source");
    const utmCampaign = _get(this.props.getCookies(),"utm_campaign");

    const variables = {
      quoteId: _get(cart,'quote_id'),
      utmSource,
      utmCampaign
    }
    
    !!utmSource && !!utmCampaign && this.props.addUtmCampaignToQuote(this.context,variables);
  }

}

export default connect(Senderglobal.mapStateToProps, {addUtmCampaignToQuote,setCookie,getCookies})(Senderglobal);