export const forgottenPassword = {
  tagTitle:       'Récupérer le mot de passe',
  metaTitle:      'Récupérer le mot de passe',
  pageTitle:      'Récupérer le mot de passe',
  description:    'Saisissez votre adresse électronique pour recevoir un lien de réinitialisation du mot de passe.',
  returnToLogin:  'Retour à la page de connexion',
  send:           'Envoyer',
  error:          "Erreur d'envoi de l'email, vérifiez que l'email existe et réessayez.",
  success:        "L'e-mail a été envoyé, veuillez vérifier votre boîte de réception pour réinitialiser votre mot de passe.",
  button:{
    send:    'Envoyer',
    sent:    'Envoyé à',
    sending: 'Envoi de'
  }
}

export default forgottenPassword;
