export const validator = {
  empty:                    "Das Feld ist leer.",
  email:                    "Dieses Feld muss eine gültige E-Mail sein.",
  phone:                    "Dieses Feld muss eine gültige Telefonnummer sein.",
  letters:                  "Dieses Feld kann nur Buchstaben enthalten.",
  lettersNumbers:           "Dieses Feld kann nur Buchstaben und Zahlen enthalten.",
  numbers:                  "Dieses Feld kann nur Zahlen enthalten.",
  float:                    "Dieses Feld kann nur Zahlen mit Dezimalstellen enthalten.",
  validPassword:            "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten und mindestens 8 Zeichen lang sein.",
  passwordnotmatch:         "Passwörter stimmen nicht überein",
  notoken:                  "Kein Token bereitgestellt",
  notEmpty:                 "Dieses Feld darf nicht leer sein.",
  lettersWithSpaces:        "Dieses Feld kann nur Buchstaben mit Leerzeichen enthalten.",
  lettersNumbersWithSpaces: "Dieses Feld kann nur Buchstaben und Zahlen mit Leerzeichen enthalten.",
  integerOrDecimal:         "Dieses Feld kann nur Zahlen enthalten.",
  "validDate(DD/MM/YYYY)":  "La fecha debe tener el formato DD/MM/YYYY"
}

export default validator;
