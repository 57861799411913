export const footer = {
  links:{
    logguedCustomers:{
      title: 'Utilisateurs enregistrés',
      items:{
        orderHistory:   'Historique des commandes',
        myAccount:      'Mon compte',
        addressesBook:  "Carnet d'adresses"
      }
    },
    services:{
      title: 'Services',
      items:{
        legalAdvice:    'Avis juridique',
        privacyPolicy:  'Politique de confidentialité',
        shipmentPolicy: "Politique d'expédition et de retour",
        warranty:       'Garanties'
      }
    },
    information:{
      title: 'Information',
      items: {
        aboutUs:    'A propos de nous',
        contactUs:  'Contact avec nous'
      }
    }
  }
}

export default footer;
