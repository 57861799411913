export const blackFriday = {
  bestsellersTitle: "BEST SELLERS",
  bestsellersText: "the most desired products",
  packsTitle: "PACKS",
  packsText: "smart packs thought by and for you",
  packsUrl: "gift-packs.html",
  outletTitle: "OUTLET",
  outletText: "last units",
  outletUrl: "etnia-cosmetics-outlet.html",
  makeupTitle: "MAKEUP",
  makeupUrl: "makeup-online.html",
  skincareTitle: "SKINCARE",
  skincareUrl: "online-cosmetics.html",
  fragancesTitle: "FRAGRANCES",
  fragancesUrl: "perfume-online.html",
  veganTitle: "VEGAN",
  veganUrl: "vegan-products.html",
  brushesTitle: "BRUSHES",
  brushesUrl: "makeup-accessories.html",
  happyTitle: "HAPPY",
  happyUrl: "happy.html",
  seeAll: "see all products",
  wannaTalkTitle: "wanna talk?",
  wannaTalkText: "If you have any questions when choosing the products that best suit you, let our team of experts advise you via chat, WhatsApp, virtual advice, email or direct message on our RRSS",
  wannaTalkButton: "connect with us"
}

export default blackFriday;
