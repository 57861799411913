import EzComponent  from '@frontend/components/EzComponent';
import              '@frontend/components/third-party-scripts/cookies/component/configurator/box/label/index.less';

class CookieConfigLabel extends EzComponent {

  render() {
    const {
      type,
      toggleAcceptance,
      checked
    } = this.props;

    return (
      <label className={`checkbox-container ${type}`}>
        <input type="checkbox" name={`${type}`} className="checkbox" />
        <span
          onClick={toggleAcceptance}
          className={`checkmark ${checked ? "checked" : ""}`}
        ></span>
      </label>
    );
  }
}

export default CookieConfigLabel;