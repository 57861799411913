let nextPrevPost = {};

const reducers = (state = nextPrevPost, action) => {
  switch(action.type) {
    case "SET_NEXT_PREV_POST":
      return action.nextPrevPost;
    default:
      return state;
  }
}

export default reducers;
