export const resetPassword = {
  pageTitle:        'Passwort wiederherstellen',
  description:      'Geben Sie das neue Passwort ein',
  returnToLogin:    'Zurück zum Login',
  password:         'Passwort',
  confirmPassword:  'Passwort bestätigen',
  send:             'Senden',
  error:            'Beim Speichern des neuen Passworts ist ein Fehler aufgetreten, versuchen Sie es erneut',
  success:          'Aktualisiertes Passwort',
  button:{
    send:    'Senden',
    sent:    'Gesendet',
    sending: 'Durch Senden'
  }
}

export default resetPassword;
