import {fr as baseFr}       from '@themes/base/frontend/locales/fr';
import productView          from '@frontend/locales/fr/product-view';
import accountBanner        from '@frontend/locales/fr/account-banner';
import miniCart             from '@frontend/locales/fr/mini-cart';
import user                 from '@frontend/locales/fr/user';
import coupons              from '@frontend/locales/fr/coupons';
import filters              from '@frontend/locales/fr/filters';
import footerNewsletter     from '@frontend/locales/fr/footer-newsletter';
import footer               from '@frontend/locales/fr/footer';
import addToWishlist        from '@frontend/locales/fr/add-to-wishlist';
import deleteFromWishlist   from '@frontend/locales/fr/delete-from-wishlist';
import categoryPage         from '@frontend/locales/fr/category-page';
import checkout             from '@frontend/locales/fr/checkout';
import relatedProducts      from '@frontend/locales/fr/related-products';
import bestSellingProducts  from '@frontend/locales/fr/best-selling-products';
import accountMenu          from '@frontend/locales/fr/account-menu';
import countries            from '@frontend/locales/fr/countries';
import genders              from '@frontend/locales/fr/genders';
import regions              from '@frontend/locales/fr/regions';
import contact              from '@frontend/locales/fr/contact';
import cookies              from '@frontend/locales/fr/cookies';

delete baseFr.mail;

export const fr = {
  ...baseFr,
  productView,
  accountBanner,
  miniCart,
  user,
  coupons,
  filters,
  footerNewsletter,
  footer,
  addToWishlist,
  deleteFromWishlist,
  categoryPage,
  checkout,
  relatedProducts,
  bestSellingProducts,
  accountMenu,
  countries,
  genders,
  regions,
  contact,
  cookies,
}


/**
 * FROM BASE
 *
 * langCode
 * contact
 * searchBar
 * navigationMenu
 * contact,
 * share,
 * days
 * months
 * searchResult
 * days
 * months
 *
 */

/**
* REMOVED
*
* mail
*/

/**
 * EXTENDED
 *
 * validator
 * newsletter
 * general
 *
 */

export default fr;
