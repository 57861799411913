import general          from '@frontend/locales/en/general';

import validator        from '@frontend/locales/en/validator';
import searchBar        from '@frontend/locales/en/search-bar';
import navigationMenu   from '@frontend/locales/en/navigation-menu';
import newsletter       from '@frontend/locales/en/newsletter';
import contact          from '@frontend/locales/en/contact';
import searchResult     from '@frontend/locales/en/search-result';
import share            from '@frontend/locales/en/share';
import mail             from '@frontend/locales/en/mail';
import days             from '@frontend/locales/en/days';
import months           from '@frontend/locales/en/months';

export const en = {
  langCode: 'en',
  ...general,
  validator,
  searchBar,
  navigationMenu,
  newsletter,
  contact,
  searchResult,
  share,
  mail,
  days,
  months
}

export default en;
