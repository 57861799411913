import _cloneDeep from 'lodash/cloneDeep';
import _set       from 'lodash/set';

const popup = {
  byKey: {}
};

const reducers = (state = popup, action) => {
  switch(action.type) {
    case "SET_POPUP_BLOCK":
      const { block, key: blockKey }  = action;
      let newBlockState               = _cloneDeep(state);

      _set(newBlockState,`byKey.${blockKey}.block`,block);

      return newBlockState;
    case "SET_POPUP_VISIBILITY":
      const { visibility, key: visibilityKey }  = action;
      let newVisibilityState                    = _cloneDeep(state);

      _set(newVisibilityState,`byKey.${visibilityKey}.visibility`,visibility);

      return newVisibilityState;
    default:
      return state;
  }
}

export default reducers;