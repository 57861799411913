export const validator = {
  empty:                    "The field is empty",
  email:                    "This field must be a valid email",
  phone:                    "This field must be a valid phone number",
  letters:                  "This field can only contain letters",
  lettersNumbers:           "This field can only contain letters and numbers",
  numbers:                  "This field can only contain numbers",
  float:                    "This field can only contain numbers with decimals",
  validPassword:            "The password must contain at least one uppercase letter, one lowercase letter and one number, and must be at least 8 characters long",
  passwordnotmatch:         "Passwords do not match",
  notoken:                  "No token provided",
  notEmpty:                 "This field cannot be empty",
  lettersWithSpaces:        "This field can only contain letters with spaces",
  lettersNumbersWithSpaces: "This field can only contain letters and numbers with spaces",
  integerOrDecimal:         "This field can only contain numbers",
  "validDate(DD/MM/YYYY)":  "The date must be in the format DD/MM/YYYY"
}

export default validator;
