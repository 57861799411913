const breadcrumbs = [];

const reducers = (state = breadcrumbs, action) => {
  switch(action.type) {
    case "SET_BREADCRUMBS":
      return action.breadcrumbs;
    default:
      return state;
  }
}

export default reducers;
