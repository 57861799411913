import Head                             from 'next/head'
import {
    SeoMetatags as 
    MagentoSeoMetatags 
}                                       from '@themes/magento/frontend/components/third-party-scripts/seo/metatags';
import { connect }                      from 'react-redux';
import _forEach                         from 'lodash/forEach';
import _find                            from 'lodash/find';
import _map                             from 'lodash/map';
import _values                          from 'lodash/values';
import _get                             from 'lodash/get';
import _split                           from 'lodash/split';
import _includes                        from 'lodash/includes';
import LdJson                           from '@frontend/components/third-party-scripts/seo/ld-json';


const XDEFAULT          = "x-default";
const DEFAULT_STORE_ID  = "1";
// Temporary until portuguese and italian stores are ready
const ES_STORE_ID       = 1;
const EN_STORE_ID       = 5;
export class SeoMetatags extends MagentoSeoMetatags {

    state = {
        loaded: false,
        subscribed: false,
        page: {}

    }

    static mapStateToProps({ storeConfig, user, storeCountries, categoryTree }) {
        return {
            storeConfiguration: storeConfig,
            user,
            defaultCountry: storeCountries.default,
            categoryTree
        }
    }

    getAlternateUrls(){
        const pageData = _get(this, "currentPage.data", {});
        let alternateUrls;
        if(this.isHomePage(pageData)){
            alternateUrls = _map(_values(_get(this, 'props.storeConfiguration.stores')), ({ domain, path, storeId }) => (
                { store: path.replace('/', ""), url: `${domain}${path}`, store_id: storeId }
            ));
        }else{
            alternateUrls = JSON.parse(_get(this.currentPage, "data.alternate", "{}"));
        }
        // Temporary until portuguese and italian stores are ready
        const esStore = _find(alternateUrls, function(o){return (o.store_id == ES_STORE_ID)});

        alternateUrls = [];
        if(esStore){
            alternateUrls.push(esStore);
        }
 
        return alternateUrls;
    }

    getAlternateUrlTagElements(){
        const { storeConfiguration } = this.props;
        const page_data_alternate = _get(this, "currentPage.data.alternate");
        let tag_elements = [];
        let alternateArray, element, langcode;
        if (typeof (page_data_alternate) != undefined && page_data_alternate != null && _get(page_data_alternate, "length") > 10) {
            alternateArray = this.getAlternateUrls();
            for (let index = 0; index < _get(alternateArray, "length", 0); index++) {
                element = _get(alternateArray, `${index}`, {});
                langcode = (_get(element, "store", "") == _get(storeConfiguration, "additional_data_array.xDefault")) ? XDEFAULT : _get(element, "store");
                if (_includes(_get(element, "store", ""), "_")) {
                    langcode = _get(_split(langcode, "_"), "1");
                }
                tag_elements.push(
                    <link
                        key={`${_get(element, "url", "")}-${_get(element, "store", "")}`}
                        rel="alternate"
                        href={`${_get(element, "url", "")}`}
                        hreflang={`${langcode}`} />
                );
            }
        }
        return tag_elements;
    }

    renderOnCmsPage() {
        const page = this.currentPage.data;
        const {storeConfiguration} = this.props;
        if (!(page && page.page_id)) {
            return;
        }
        let finalUrl;
        if(this.isStoreSelection(page)){
            // NOTE: /es/ concatenation is temporary, remove when indicated
            const defaultStorePath = _get(_find(storeConfiguration.stores, ["storeId", DEFAULT_STORE_ID]),"path");
            finalUrl = this.router.getStoreSelection(page) + defaultStorePath + "/";
        }else{
            const route = this.router.get("cmsPage", { id: page.page_id, slug: page.identifier });
            finalUrl = this.router.getUrlFromRoute(route);
            if (finalUrl.includes("/home")) {
                finalUrl = finalUrl.split("home")[0]
                if (storeConfiguration.additional_data_array.home_canonical != undefined && storeConfiguration.additional_data_array.home_canonical != null) {
                    finalUrl = this.props.storeConfiguration.additional_data_array.home_canonical
                }
            }
        }
        let title = "";
        if (page.meta_title) {
            title = page.meta_title;
        }
        if(page.title&&title=="")
        {
            title = page.title;
        }

        let alternate_urls = [];
            let alternateArray = null;
            let element = [];
            let langcode = null;
            
            if (typeof (page.alternate) != undefined && page.alternate != null && page.alternate.length > 10) {
                alternateArray = this.getAlternateUrls();
                if (this.isStoreSelection(page) || this.isHomePage(page)){
                    alternate_urls.push(
                        <link
                            key={`${storeConfiguration.stores[0]["domain"]}-${XDEFAULT}`}
                            rel="alternate"
                            href={`${storeConfiguration.stores[0]["domain"]}/`}
                            hreflang={`${XDEFAULT}`} />
                    );
                }
                if(this.isStoreSelection(page)){
                    _forEach(storeConfiguration.stores, function(store) {
                        element = _find(alternateArray, function(o){return o.store_id == store.storeId});
                        if(element){
                            langcode = (element["store"] == storeConfiguration.additional_data_array.xDefault) ? XDEFAULT : element["store"].split("_")[1];
                            alternate_urls.push(
                                <link
                                    key={`${element["url"]}-${element["store"]}`}
                                    rel="alternate"
                                    href={`${store["domain"]}${store["path"]}/`}
                                    hreflang={`${langcode}`} />
                            );
                        }
                    });
                } else if (this.isHomePage(page)) {
                    _map(alternateArray, element => {
                        alternate_urls.push(
                            <link
                                key={`${element["url"]}-${element["store"]}`}
                                rel="alternate"
                                href={`${element["url"]}/`}
                                hreflang={`${element['store']}`} />
                        )
                    })
                } else {
                    alternate_urls = this.getAlternateUrlTagElements();
                }
            }
        
        return (
            <Head>
                <link rel="canonical" href={finalUrl} key="canonical" />
                { title && <title>{title}</title>}
                <meta key="page-meta-title" name="title" content={page.meta_title} />
                <meta key="page-meta-desc" name="description" content={page.meta_description} />
                <meta key="page-meta-keywords" name="keywords" content={page.meta_keywords} />
                {
                    alternate_urls.length &&
                    alternate_urls
                }
            </Head>
        )
    }

    renderOnProductPage() {
        const product = this.currentPage.data;
        if (!(product && product.prices)) {
            return;
        }
        const { storeConfiguration } = this.props;
        this.prices = this.getCalculatedPrices(product.prices, storeConfiguration);
        let meta_title = "";
        let meta_description = "";


        if (product.meta_description) {
            meta_description = product.meta_description;
        } else {
            if (product.description && product.description != null) {
                meta_description = product.description.replace(/<[^>]*>?/gm, '').substring(0, 199);
            }
        }

        if (product.meta_title != null) {
            meta_title = product.meta_title;
        } else {
            meta_title = product.name;
        }
        // Meta title and description
        let image = (JSON.parse(product.media_gallery)) ? JSON.parse(JSON.parse(product.media_gallery).productGallery).product_base_image : '';
        if (image) {
            image = image[0];
        }
        let productUrl = this.router.getStoreProductUrl(storeConfiguration, { id: product.product_id, slug: product.path });
        if (typeof (product.canonical) != undefined && product.canonical != null) {
            productUrl = product.canonical;
        }
        const alternate_urls = this.getAlternateUrlTagElements();

        return (
            <>
                <Head>
                    <link rel="canonical" href={productUrl} key="canonical" />
                    <meta key="meta-title" name="title" content={meta_title} />
                    <title key="page-title">{meta_title}</title>
                    <meta key="meta-description" name="description" content={meta_description} />
                    <meta key="og:type" property="og:type" content="product" />
                    <meta key="og:title" property="og:title" content={meta_title} />
                    <meta key="og:image" property="og:image" content={image} />
                    <meta key="og:description" property="og:description" content={meta_description} />
                    <meta key="og:url" property="og:url" content={productUrl} />
                    {
                        this.prices && this.prices.final_price && <meta key="product:price" property="product:price:amount" content={(this.prices.final_price)} />
                    }
                    {
                        alternate_urls.length &&
                        alternate_urls
                    }
                    <meta key="product:price:currency" property="product:price:currency" content="EUR" />
                    <meta key="og:brand" property="og:brand" content={storeConfiguration.additional_data_array.meta_brand ? storeConfiguration.additional_data_array.meta_brand : ''} />
                    <meta key="og:availability" property="og:availability" content={this.hasStock(product) ? "in stock" : "out of stock"} />
                    <meta key="og:condition" property="og:condition" content="new" />
                    <meta key="og:retailer_item_id" property="og:retailer_item_id" content={product.product_id} />
                </Head>
                <LdJson key="breadcrumb-snippet" data={this.getBreadcrumbObject(product.name, productUrl)} />
                <LdJson key="product-snippet" data={this.getProductObject(product, storeConfiguration, meta_description)} />
            </>
        )

    }

    renderOnCategoryPage() {
        const category = this.currentPage.data;
        if (!(category && category.category_id)) {
            return;
        }
        const { storeConfiguration, storeConfiguration: { canonicalCategories = {} }, categoryTree } = this.props;
        let meta_title = "";
        let meta_description = "";

        if (category) {
            meta_title = category.meta_title ? category.meta_title : category.name;
        }
        if (category) {
            if (category.meta_description) {
                meta_description = category.meta_description;
            } else {
                if (category.description) {
                    meta_description = category.description.replace(/<[^>]*>?/gm, '').substring(0, 199);
                }
            }
        }

        const findCategoryChild = (id, categoryTree) => {
            let result;
            _find(categoryTree, (child) => child.id == id && (result = child) || (result = findCategoryChild(id, child.children || [])));
            return result;
        }
        const categoryAlt   = findCategoryChild(_get(canonicalCategories, `${category.category_id}`, {}), categoryTree);
        const id            = categoryAlt ? _get(categoryAlt, "id") : _get(category, "category_id");
        const slug          = categoryAlt ? _get(categoryAlt, "url", "") : _get(category, "url_path", "") || _get(category, "path", "");
        const finalUrl      = (typeof (category.canonical) != undefined && category.canonical != null) ? category.canonical : this.router.getStoreCategoryUrl(storeConfiguration, { id, slug });
        const alternate_urls = this.getAlternateUrlTagElements();

        return (
            <>
                {
                    category &&
                    <>
                        <Head>
                            <link rel="canonical" href={finalUrl} key="canonical" />
                            <meta key="page-meta-title" name="title" content={meta_title} />
                            <title key="page-title">{meta_title}</title>
                            <meta key="page-meta-description" name="description" content={meta_description} />
                            <meta key="page-meta-keyword" name="keyword" content={category.meta_keyword ? category.meta_keyword : ""} />
                            <meta key="og:title" property="og:title" content={meta_title} />
                            <meta key="og:description" property="og:description" content={meta_description} />
                            <meta key="og:url" property="og:url" content={finalUrl} />
                            {
                                alternate_urls.length &&
                                alternate_urls
                            }
                        </Head>
                        <LdJson key="breadcrumb-snippet" data={this.getBreadcrumbObject(category.name, finalUrl)} />
                        {this.renderAdditionalOnCategoryPage()}
                    </>
                }

            </>
        )
    }

    renderOnGenericPage(){
        const page = this.currentPage.data;
        if (!page) return;

        return (
            <>
            { super.renderOnGenericPage() }
            <Head>
                <link rel="canonical" href={this.router.getCurrentUrl()} key="canonical" />
            </Head>
            </>
        );
    }

}

export default connect(SeoMetatags.mapStateToProps, {})(SeoMetatags);