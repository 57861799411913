import { signIn as storeSignIn } from '@themes/store/frontend/locales/en/user/sign-in';

export const signIn = {
  ...storeSignIn,
  pageTitle:              'log in',
  sectionDescription:     'to add your favourite products to your wish list and discover all our offers',
  buttonLabel:            'enter',
  email:                  'email address',
  createAccount:          'Are you new here?',
  forgottenPassword:      'Forgotten your password?',
  showPassword:           'show',
  hidePassword:           'hide'
}

export default signIn;
