const showAccountModal = false;

const reducers = (state = showAccountModal, action) => {
  switch(action.type) {
    case "OPEN_ACCOUNT_MODAL":
      return true;
    case "CLOSE_ACCOUNT_MODAL":
      return false;
    default:
      return state;
  }
}

export default reducers;
