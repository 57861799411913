export const orderDetail = {
  pageTitle:        'Bestellung',
  infoTitle:        'Informationen zur Bestellung',
  itemsTitle:       'Gewünschte Artikel',
  orderId:          'Bestellnummer',
  date:             'Datum',
  status:           'Stand',
  orderInfo:        'Details zur Bestellung',
  shippingAddress:  'Lieferadresse',
  shippingMethod:   'Versandart',
  billingAddress:   'Rechnungsadresse',
  billingMethod:    'Zahlungsweise',
  orderItems:{
    name:       'Name des Produkts',
    sku:        'SKU',
    price:      'Preis',
    qty:        'Menge.',
    subtotal:  'Zwischensumme'
  },
  orderGesamts:{
    subTotal: 'Zwischensumme',
    shippingTax:  'Versand- und Bearbeitungskosten',
    tax: 'Steuern',
    total: 'Total'
  },
  pdf:{
    download: 'pdf herunterladen',
    loading:  'pdf vorbereiten...'
  }
}

export default orderDetail;
