
import {
    ScriptManager,
    ThirdPartyScript
} from '@frontend/components/third-party-scripts';
import checkServer from '@frontend/util/checkServer';
import cookies              from '@frontend/settings/cookies'

import { connect } from 'react-redux';

export class GoogleAnalytics extends ThirdPartyScript {
    static cookiesAllowed  = cookies.ANALYTICAL

    state = {
        subscribed:  false,
        loaded:      false,
        location:    null,
        currentPage: false
    }
    static mapStateToProps({storeConfig}){
        return {
            storeConfiguration: storeConfig,
        }
    }

    onLoaded() {
        const { storeConfiguration } = this.props;
        const additionalDataArray = storeConfiguration.additional_data_array;
        let analyticsId              = false;
        if (storeConfiguration.googleAnalytics) {
            analyticsId = storeConfiguration.googleAnalytics;
        }
        if (additionalDataArray && additionalDataArray.googleAnalyticsId) {
            analyticsId = additionalDataArray.googleAnalyticsId;
        }
        (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
            m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
        ga('create', analyticsId, 'auto');
        ga('require', 'ec');
        this.setState({ loaded: true })    
        console.log("GoogleAnalytics: Loaded");    
    }

    onProductPage(product) {
        if(!(product && product.prices && product.prices.final_price)){
            return;
        }      
        if (product != null) {
            ga('ec:addProduct', {
                name: product.name,
                id: product.sku,
                price: product.prices.final_price
            });
        }
        ga('ec:setAction', 'detail');
    }

    onCategoryPage(category) {
        if(!(category && category.category_id)){
            return;
        }
        // add impressions of category products
        for (var i = 0; i < category.items.length; i++) {
            let currentProduct = category.items[i];
            ga('ec:addImpression', {
                name: currentProduct.name,
                category: category.name,
                id: currentProduct.sku,
                price: currentProduct.prices.final_price,
                list: category.name,
                position: i + 1
            })
        }
        ga('ec:setAction', 'detail');   
    }

    onAddedToCart({ product, cart }) {
        const quote_item = this.getProductBySku(cart.items, product.sku);
        let ga_product = {
            id: product.sku,
            quantity: product.qty ? product.qty.toString() : "1",
            price: quote_item.final_price ? quote_item.final_price.toString() : "1"
        }
        ga('ec:addProduct', ga_product);
        ga('ec:setAction', 'add');
        ga('send', 'event', 'UX', 'click', 'add to cart');
    }

    getProductBySku(products, sku) {
        let result = false;
        if (products) {
            if (products.length > 1) {
                for (let index = 0; index < products.length; index++) {
                    const element = products[index];
                    if (element.sku == sku) {
                        result = element;
                    }
                }
            } else {
                result = products[0];
            }
        }
        return result;
    }

    onChangedCartItem({ product, cart }) {
        console.log("GA:ChangedCartItem", product);
    }

    onDeletedCartItem({ product, cart }) {
        console.log("GA:DeletedCartItem")
    }

    onOrderSuccess(order) {
        if(!(order && order.items)){
            return;
        }
        let products = [];
        order.items.map((item) => {
            let product = {};
            if (item.price) {
                product['name'] = item.name;
                product['id'] = item.sku;
                product['quantity'] = item.qty;
                product['price'] = item.price;
                ga('ec:addProduct', {
                        'id': item.sku,
                        'name': item.name,
                        'price': item.price,
                        'quantity': item.qty
                    }
                );
                products.push(product);
            }
        });

        ga('ec:setAction', 'purchase', {
            'id': order.increment_id,
            'revenue': order.total,
            'products': products,
            'shipping': order.shipping
        }
        );
        this.setState({currentPage: window.location.href})
    }

    onRouteChangeComplete(url){
        ga('send', 'pageview',url);
    }

    onCategoryClick({product, category}){
        let cat_name = category == undefined ? "search/related" : category.name;
        ga('ec:addProduct', {
            id: product.sku,
            name: product.name,
            category: cat_name,
        }
        );
        ga('ec:setAction', {
            'list': cat_name
        });
        ga('send', 'event', {
            eventCategory: 'productlist',
            eventAction: 'click',
            eventLabel: cat_name
        });
    }

    render() {
        this.subscribe()
        return null;
    }

    
}


export default connect(GoogleAnalytics.mapStateToProps, {})(GoogleAnalytics);