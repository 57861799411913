export const forgottenPassword = {
  tagTitle:       'Passwort wiederherstellen',
  metaTitle:      'Passwort wiederherstellen',
  pageTitle:      'Passwort wiederherstellen',
  description:    'Geben Sie Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen des Passworts zu erhalten.',
  returnToLogin:  'Zurück zum Login',
  send:           'Senden',
  error:          'Fehler beim Senden der E-Mail, überprüfen Sie, ob die E-Mail existiert und versuchen Sie es erneut.',
  success:        'E-Mail gesendet, bitte überprüfen Sie Ihren Posteingang, um Ihr Passwort zurückzusetzen',
  button:{
    send:    'Senden',
    sent:    'Gesendet',
    sending: 'Durch Senden'
  }
}

export default forgottenPassword;
