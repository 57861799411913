let product = null;

const reducers = (state = product, action) => {
  switch(action.type) {
    case "SET_PRODUCT":
      return action.product;
    default:
      return state;
  }
}

export default reducers;
