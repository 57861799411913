import {
  ScriptManager,
  ThirdPartyScript
} from '@frontend/components/third-party-scripts';
import checkServer          from '@frontend/util/checkServer';
import cookies              from '@frontend/settings/cookies'
import { 
  getProductDataById 
} from '@frontend/actions/product';

import { connect } from 'react-redux';

export class GoogleAnalyticsFour extends ThirdPartyScript {

  static cookiesAllowed  = cookies.ANALYTICAL

  state = {
      subscribed:  false,
      loaded:      false,
      location:    null,
      currentPage: false,
      id:          "G-Y2CMN6LBRH"
  }
  static mapStateToProps({storeConfig, user, minicart}){
      return {
          storeConfiguration: storeConfig,
          user: user,
          cartData: minicart.cart,
      }
  }

  getCurrency() {
    return this.props.storeConfiguration.currencySymbol == "€" ? "EUR" : "USD";
  }

  onLoaded() {
      const { storeConfiguration } = this.props;
      const additionalDataArray = storeConfiguration.additional_data_array;
      if (additionalDataArray.googleAnalyticsFour != undefined) {
          let script = document.createElement("script");
          script.src = "https://www.googletagmanager.com/gtag/js?id=" + additionalDataArray.googleAnalyticsFour;
          document.body.appendChild(script);
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());

          gtag('config', additionalDataArray.googleAnalyticsFour);
      }
      
  }

  onCategoryPage(category) {
      if(!(category && category.category_id)){
          return;
      }
      let items = [];
      for (var i = 0; i < category.items.length; i++) {
          let currentProduct = category.items[i];
          items.push({
              item_id: currentProduct.sku,
              item_name: currentProduct.name,
              index: i,
              item_list_name: category.name,
              item_list_id: category.category_id,
              price: parseFloat(currentProduct.prices.final_price).toFixed(2),
              currency: this.getCurrency(),
              quantity: 1
          })
      }
      function gtag() { dataLayer.push(arguments); }
      gtag('event', 'view_item_list', {
          items: items,
          item_list_name: category.name,
          item_list_id: category.category_id
      });
  }

  onProductPage(product) {
      if(!(product && product.prices && product.prices.final_price)){
          return;
      }      
      if (product != null) {
          function gtag() { dataLayer.push(arguments); }
          const currency = this.getCurrency();
          gtag('event', 'view_item', {
              currency: currency,
              items: [{
                  item_id: product.sku,
                  item_name: product.name,
                  price: product.prices.final_price,
                  currency: currency,
                  quantity: 1
              }],
              value: product.prices.final_price
          });
      }
  }

  onViewCart(cartData = null) {
      let cart = (cartData) ? cartData : this.props.cartData;
      if(!cart || !cart.items || cart.items.length == 0){
        return;
      } else {
        let products = [];
        function gtag() { dataLayer.push(arguments); }
        const currency = this.getCurrency();
        cart.items.map((item) => {
          let product = {};
          if (item.price) {
            product['item_id'] = item.sku;
            product['item_name'] = item.name;
            product['quantity'] = parseInt(item.qty);
            product['price'] = parseFloat(item.final_price);
            product['currency'] = currency;
            products.push(product);
          }
        });
        gtag('event', 'view_cart', {
          currency: currency,
          value: parseFloat(cart.total),
          items: products
        });
      }
  }

  getProductBySku(products, sku) {
      let result = false;
      if (products) {
          if (products.length > 1) {
              for (let index = 0; index < products.length; index++) {
                  const element = products[index];
                  if (element.sku == sku) {
                      result = element;
                  }
              }
          } else {
              result = products[0];
          }
      }
      return result;
  }

  onAddedToCart({ product, cart }) {
      const quote_item = this.getProductBySku(cart.items, product.sku);
      const currency = this.getCurrency();
      function gtag() { dataLayer.push(arguments); }
      gtag('event', 'add_to_cart', {
          currency: currency,
          items: [{
              item_id:   quote_item.sku,
              item_name: quote_item.name,
              price:     quote_item.final_price,
              currency:  currency,
              quantity:  quote_item.qty
          }],
          value: quote_item.final_price * quote_item.qty
      });
  }

  onDeletedCartItem({ product, cart }) {
    const currency = this.getCurrency();
    function gtag() { dataLayer.push(arguments); }
    gtag('event', 'remove_from_cart', {
      currency: currency,
      items: [{
        item_id:   product.sku,
        item_name: product.name,
        price:     product.final_price,
        currency:  currency,
        quantity:  product.qty
      }],
      value: product.final_price * product.qty
    });
  }

  onChangedCartItem({ product, cart }) {
    const currency = this.getCurrency();

    let final_qty = 0;
    function gtag() { dataLayer.push(arguments); }
    if(product.oldQty < product.newQty){
      final_qty = (product.newQty - product.oldQty);
      gtag('event', 'add_to_cart', {
        currency: currency,
        items: [{
          item_id:   product.sku,
          item_name: product.name,
          price:     product.final_price,
          currency:  currency,
          quantity:  final_qty
        }],
        value: product.final_price * final_qty
      });
    } else if(product.newQty < product.oldQty) {
      final_qty = (product.oldQty - product.newQty);
      gtag('event', 'remove_from_cart', {
        currency: currency,
        items: [{
          item_id:   product.sku,
          item_name: product.name,
          price:     product.final_price,
          currency:  currency,
          quantity:  final_qty
        }],
        value: product.final_price * final_qty
      });
    }
  }

  getProductById = async (productId) => {
    const variables = {
        id:             productId,
        storeId:        (this.props.storeConfiguration.storeId)  ? parseInt(this.props.storeConfiguration.storeId) : 0,
        customerGroup:  (this.props.user.group_id)               ? this.props.user.group_id                        : 0
    };
    const product = await this.props.getProductDataById(this.context, variables);
    return product;
  }

  onAddedToWishlist({ variables, wishlist }) {
    this.getProductById(variables.productId).then((product) => {
      const currency = this.getCurrency();
      function gtag() { dataLayer.push(arguments); }
      gtag('event', 'add_to_wishlist', {
        currency: currency,
        items: [{
          item_id:   product.sku,
          item_name: product.name,
          price:     product.prices.final_price,
          currency:  currency
        }],
        value: product.prices.final_price
      });
    });
  }

  onGenerateLead({variables, value = null}) {
    var data = {
      variables
    };
    if(value){
      const currency = this.getCurrency();
      data.currency = currency;
      data.value = value;
    }
    function gtag() { dataLayer.push(arguments); }
    gtag('event', 'generate_lead', data);
  }

  onSelectItem({item_list_id, item_list_name, product}) {
    const currency = this.getCurrency();
    function gtag() { dataLayer.push(arguments); }
    gtag('event', 'select_item', {
      item_list_id,
      item_list_name,
      items: [{
        item_id:   product.sku,
        item_name: product.name,
        price:     product.prices.final_price,
        currency:  currency
      }]
    });
  }

  onSelectPromotion({location_id, product}) {
    const currency = this.getCurrency();
    function gtag() { dataLayer.push(arguments); }
    gtag('event', 'select_promotion', {
      location_id,
      items: [{
        item_id:   product.sku,
        item_name: product.name,
        price:     product.prices.final_price,
        currency:  currency
      }]
    });
  }

  onViewPromotion({location_id, product}) {
    const currency = this.getCurrency();
    function gtag() { dataLayer.push(arguments); }
    gtag('event', 'view_promotion', {
      location_id,
      items: [{
        item_id:   product.sku,
        item_name: product.name,
        price:     product.prices.final_price,
        currency:  currency
      }]
    });
  }

  onOrderSuccess(order) {
      if(!(order && order.items)){
          return;
      }
      let products = [];
      function gtag() { dataLayer.push(arguments); }
      const currency = this.getCurrency();
      order.items.map((item) => {
          let product = {};
          if (item.price) {
              product['name'] = item.name;
              product['item_id'] = item.sku;
              product['quantity'] = parseInt(item.qty);
              product['price'] = parseFloat(item.price_incl_tax);
              product['currency'] = currency;
              products.push(product);
          }
      });
      let data = {
          currency: currency,
          items: products,
          transaction_id: order.increment_id,
          shipping: parseFloat(order.shipping),
          value: parseFloat(order.total),
          tax: parseFloat(order.tax).toFixed(2)
      }
      gtag('event', 'purchase', data);
  }

  render() {
      this.subscribe()
      return null;
  }

  
}


export default connect(GoogleAnalyticsFour.mapStateToProps, { getProductDataById })(GoogleAnalyticsFour);