const swatchesModal = {
  showModal: false,
  option: {},
  product: {},
  onOptionSelected: (() => { }),
  setParentSwatchState: (() => { }),
  selectedValue: null
};

const reducers = (state = swatchesModal, action) => {
  switch (action.type) {
    case "OPEN_SWATCHES_MODAL":
      return {
        showModal: true,
        option: action.option,
        product: action.product,
        onOptionSelected: action.onOptionSelected,
        setParentSwatchState: action.setParentSwatchState,
        selectedValue: action.selectedValue
      };
    case "CLOSE_SWATCHES_MODAL":
      return swatchesModal;
    default:
      return state;
  }
}

export default reducers;
