const wishlistProductList = [];

const reducers = (state = wishlistProductList, action) => {
  switch(action.type) {
    case "SET_WISHLIST_PRODUCT_LIST":
      return action.wishlistProductList;
    default:
      return state;
  }
}

export default reducers;
