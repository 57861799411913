export const resetPassword = {
  pageTitle:        'Récupérer le mot de passe',
  description:      'Entrez le nouveau mot de passe',
  returnToLogin:    'Retour à la page de connexion',
  password:         'Mot de passe',
  confirmPassword:  'Confirmer le mot de passe',
  send:             'Envoyer',
  error:            "Une erreur s'est produite lors de l'enregistrement du nouveau mot de passe, veuillez réessayer.",
  success:          'Mot de passe mis à jour',
  button:{
    send:    'Envoyer',
    sent:    'Envoyé à',
    sending: 'Envoi de'
  }
}

export default resetPassword;
