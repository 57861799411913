import _map     from 'lodash/map';
import _get     from 'lodash/get';

let emptyUser = {
  id: null,
  group_id: null,
  email: null,
  firstname: null,
  lastname: null,
  middlename: null,
  store_id: null,
  taxvat: null,
  website_id: null,
  addresses: null,
  disable_auto_group_change: null,
  error: null,
  token: '',
  wishlist_items: [],
  wishlistItemsCount: 0,
  custom_attributes:[],
  isSignedIn: false
 }

let session             = { user: emptyUser } ;
let wishlistItems       = [];
let wishlistItemsCount  = 0;

const reducers = (state = session, action) => {

  switch(action.type) {
    case "SET_USER":
      action.user.isSignedIn = (action.user.isSignedIn ==null || action.user.isSignedIn== undefined) ? (action.user.error == null) : action.user.isSignedIn ;
      action.user.addresses  = _map(_get(action.user, 'addresses'), (address) => ({...address, custom_attributes: _get(address, 'custom_attributes') || []}));
      return { ...action.user };
    case "DELETE_USER":
      return {user:emptyUser,isSignedIn:false};
    case "ADD_ITEM_WISHLIST":
      wishlistItems = state.wishlist_items;

      // wishlistItems.push(action.productId);
      wishlistItems = [...wishlistItems,action.productId];
      wishlistItemsCount = wishlistItems.length;
      return {...state, wishlist_items:wishlistItems,wishlist_items_count:wishlistItemsCount};
    case "DELETE_ITEM_WISHLIST":
      wishlistItems       = state.wishlist_items;
      wishlistItems       = wishlistItems.filter(item=>item!==action.productId);
      wishlistItemsCount  = wishlistItems.length;

      return {...state, wishlist_items:wishlistItems,wishlist_items_count:wishlistItemsCount};
    default:
      return state;
  }
}

export default reducers;
