let canRMA = {};

const reducers = (state = canRMA, action) => {
    switch (action.type) {
        case "SET_CAN_RMA":
            return action.data;
        default:
            return state;
    }
}

export default reducers;
