import {
    ScriptManager,
    ThirdPartyScript
} from '@frontend/components/third-party-scripts';
import { connect } from 'react-redux';
import cookies              from '@frontend/settings/cookies'

export class Hotjar extends ThirdPartyScript {

    static cookiesAllowed  = cookies.ANALYTICAL

    static mapStateToProps({ storeConfig }) {
        return {
            storeConfiguration: storeConfig,
        }
    }

    onLoaded() {
        const { storeConfiguration } = this.props;
        const additionalDataArray = storeConfiguration.additional_data_array;
        const script = document.createElement("script");
        if (additionalDataArray.hotjarId != undefined) {
            (function (h, o, t, j, a, r) {
                h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                h._hjSettings = { hjid: additionalDataArray.hotjarId, hjsv: 6 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script'); r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
            console.log("HOTJAR:loaded")
        }

    }



    render() {
        this.subscribe()
        return null;
    }


}


export default connect(Hotjar.mapStateToProps, {})(Hotjar);




