import {en as baseEn}       from '@themes/base/frontend/locales/en';
import productView          from '@frontend/locales/en/product-view';
import accountBanner        from '@frontend/locales/en/account-banner';
import miniCart             from '@frontend/locales/en/mini-cart';
import user                 from '@frontend/locales/en/user';
import coupons              from '@frontend/locales/en/coupons';
import filters              from '@frontend/locales/en/filters';
import footerNewsletter     from '@frontend/locales/en/footer-newsletter';
import footer               from '@frontend/locales/en/footer';
import addToWishlist        from '@frontend/locales/en/add-to-wishlist';
import deleteFromWishlist   from '@frontend/locales/en/delete-from-wishlist';
import categoryPage         from '@frontend/locales/en/category-page';
import checkout             from '@frontend/locales/en/checkout';
import relatedProducts      from '@frontend/locales/en/related-products';
import bestSellingProducts  from '@frontend/locales/en/best-selling-products';
import accountMenu          from '@frontend/locales/en/account-menu';
import countries            from '@frontend/locales/en/countries';
import genders              from '@frontend/locales/en/genders';
import regions              from '@frontend/locales/en/regions';
import contact              from '@frontend/locales/en/contact';
import general              from '@frontend/locales/en/general';
import cookies              from '@frontend/locales/en/cookies';

delete baseEn.mail;

export const en = {
  ...baseEn,
  productView,
  accountBanner,
  miniCart,
  user,
  coupons,
  filters,
  footerNewsletter,
  footer,
  addToWishlist,
  deleteFromWishlist,
  categoryPage,
  checkout,
  relatedProducts,
  bestSellingProducts,
  accountMenu,
  countries,
  genders,
  regions,
  contact,
  general,
  cookies,
  add: 'Add',
  addToCart: 'Add'
}


/**
 * FROM BASE
 *
 * langCode
 * contact
 * searchBar
 * navigationMenu
 * contact,
 * share,
 * days
 * months
 * searchResult
 * days
 * months
 *
 */

/**
* REMOVED
*
* mail
*/

/**
 * EXTENDED
 *
 * validator
 * newsletter
 * general
 *
 */

export default en;
