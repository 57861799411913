export const orders = {
  tagTitle:   'Mes commandes',
  metaTitle:  'Mes commandes',
  notOrders:  "Vous n'avez aucune commande associée à votre compte.",
  orderId:    'N° de commande',
  date:       'Date',
  sendTo:     'Envoyer à',
  total:      'Total de la commande',
  status:     'Statut',
  actions:    'Action',
  detail:     'Voir la commande',
  statusValues:{
    pending:    'En attente',
    canceled:   'Annulé',
    closed:     'Fermé',
    complete:   'Complété',
    holded:     'Retenue',
    processing: 'En cours'
  }
}

export default orders;
