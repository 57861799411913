const seo = {
  publicName: "",
  lastCategory: ""
};

const reducers = (state = seo, action) => {
  switch(action.type) {
    case "SET_SEO_DATA":
      const seo = {...state,...action.seoData}
      return seo;
    default:
      return state;
  }
}

export default reducers;
