const searchResultsPageData = {
  items:            [],
  num:              0,
  hasMoreProducts : false,
  searchValue:      ''
};

const reducers = (state = searchResultsPageData, action) => {
  switch(action.type) {
    case "SET_SEARCH_RESULTS_PAGE_DATA":
      const { searchResultsPageData } = action;
      return searchResultsPageData;
    default:
      return state;
  }
}

export default reducers;
