export const contact = {
  tagTitle:     'Contact',
  metaTitle:    'Contact',
  name:         'Nom',
  email:        'Adresse électronique',
  subject:      'Sujet',
  message:      'Message',
  accept:       "J'ai lu et j'accepte les ",
  privacyLink:  'conditions de confidentialité',
  privacyError: "Il est nécessaire d'accepter les conditions de confidentialité"
}

export default contact;
