export const months = {
  month0: "January",
  month1: "February",
  month2: "March",
  month3: "April",
  month4: "May",
  month5: "June",
  month6: "July",
  month7: "August",
  month8: "September",
  month9: "October",
  month10: "November",
  month11: "December",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December"
  }

export default months;
