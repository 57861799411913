import addresses      from '@frontend/locales/de/user/my-account/addresses';
import contactInfo    from '@frontend/locales/de/user/my-account/contact-info';
import orderDetail    from '@frontend/locales/de/user/my-account/order-detail';
import orders         from '@frontend/locales/de/user/my-account/orders';

export const myAccount = {
  addresses,
  contactInfo,
  orderDetail,
  orders,
  tagTitle:           'Mein Konto',
  metaTitle:          'Mein Konto',
  pageTitle:          'Mein Konto',
  me:                 'Konto',
  myAdresses:         'Adressbuch',
  myOrders:           'Meine Befehle',
  accountInfo:        'Persönliche Daten',
  addressInfo:        'Adressbuch',
  wishlist:           'Wunschzettel',
  emptyWishlist:      'Sie haben noch keine Produkte zu Ihrer Wunschliste hinzugefügt',
  signOut:            'Sitzung schließen',
  billingAddresses:   'Rechnungsadresse',
  shippingAddresses:  'Lieferadresse',
  edit:               'bearbeiten',
  add:                'hinzufügen',
  defaultAddresses:   'Standard-Adressen',
  aditionalEntries:   'Zusätzliche Adresseinträge',

}

export default myAccount;
