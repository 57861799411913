export const newsletter = {
  recaptchaError:     'Il est nécessaire de valider le reCaptcha.',
  subscribe:          "S'abonner à notre newsletter",
  subscribeInfo:      'Soyez les premiers à être informés de nos promotions et remises.',
  emailPlaceholder:   'Entrez votre email...',
  subscriptionError:  "Erreur d'inscription à la newsletter. Vérifiez votre courrier électronique et réessayez plus tard.",
  emailError:         'El formato de correo electrónico no es correcto: ejemplo@dominio.com',
  success:            "Nous avons enregistré l'adresse électronique en tant qu'abonné."
}

export default newsletter;
