import EzComponent             from '@frontend/components/EzComponent';
import { connect }             from 'react-redux';

import                         '@frontend/components/third-party-scripts/cookies/component/configurator/box/table/index.less';

class CookieConfigTable extends EzComponent {

  static mapStateToProps({storeConfig:{cookiesData}}) {
    return {
      cookiesData
    }
  }

  render() {
    const {
      cookies
    } = this.props;

    return (
      <table className="cookie-table">
            <tbody>
              {
                !!cookies &&
                cookies.map(cookie => (
                  <tr>
                    <td className="platform">{cookie.cookie_platform}</td>
                    <td className="separator">/</td>
                    {
                      !!cookie.cookie_duration &&
                      <>
                        <td className="duration">
                          {cookie.cookie_duration}
                        </td>
                        <td className="separator">/</td>
                      </>
                    }

                    <td className="link">
                      {
                        cookie.cookie_urls.split(",").map(link => (
                          <a
                            target="_blank"
                            href={link}
                          >
                            {link}
                          </a>
                        ))
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
    );
  }
}

export default connect(CookieConfigTable.mapStateToProps)(CookieConfigTable)