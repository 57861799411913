export const addresses = {
  editTagTitle: 'Edit address',
  editMetaTitle: 'Edit address',
  addTagTitle: 'Add new address',
  addMetaTitle: 'Add new address',
  add: "Add new address",
  edit: "Edit address",
  firstnameLabel: "Name",
  lastnameLabel: "Surname",
  regionLabel: "Province",
  countryLabel: "Country",
  postCodeLabel: "Postal Code",
  cityLabel: "City",
  streetLabel: "Address",
  telephoneLabel: "Telephone",
  defaultBilling: "Default billing address",
  defaultShipping: "Default shipping address",
  notDefaultBilling: "You don't have a default billing address in your address book.",
  notDefaultShipping: "You don't have a default shipping address in your address book.",
  notOtherAddresses: "You have no other addresses in your address book.",
  notbillingAddresses: "You have not added a billing address yet",
  notshippingAddresses: "You have not added a shipping address yet"
}

export default addresses;
