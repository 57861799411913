export const searchBar = {
  placeholder:    "Search",
  search:         "Search",
  close:          "Close",
  productResult:  "%1 product/s found for ‘%2’",
  postResult:     "%1 post/s found for ‘%2’",
  recent:         "recent searches",
  popular:        "popular",
  findYourStore:  "find your closest store",
  conditions:     "Conditions",
  club:           "Lovely Club",
  legal:          "Legal terms and conditions",
  job:            "Jobs",
  warning:        "Legal Notice",
  stores:         "Shops",
  faq:            "FAQ",
}

export default searchBar;
