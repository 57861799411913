export const checkout = {
  successTagTitle:  'Merci pour votre achat !',
  successMetaTitle: 'Merci pour votre achat !',
  yourOrder:        'Tu nº de pedido es: %1',
  confirmationText: "Nous vous enverrons un courriel de confirmation contenant des informations et des détails sur l'expédition.",
  continueBuying:   'Poursuivre les achats',
  verificationText: "Vous pouvez vérifier l'état de votre commande en créant un compte.",
  createAccount:    'Créer un compte',
  failureText:      'Il y a eu un problème avec votre commande. Veuillez réessayer',
  topMessage:       'Tu pedido llegará muy pronto',   
  thanks:           'Merci pour votre achat !',
  question:         'Quelle a été la chose la plus importante pour vous ?',
  review:           'Nous voulons connaître votre opinion',
  emailWarning:     'Nous vous enverrons un courriel de confirmation contenant les détails et les informations de votre commande.',
  newOrder:         'Commencez une nouvelle commande ici',
  keepBuying:       'Poursuivre les achats',
  joinClub:         'Rejoignez maintenant le',
  clubNameOne:      'CLUB',
  clubNameTwo:      'LUXENTER',
  subscribe:        "S'abonner à",
  followShipment:   "Vous pouvez suivre l'état de votre commande depuis votre compte.",
  share:            'Partagez avec nous votre look incroyable et utilisez le hashtag ',
  hashtag:          '#LUXENTEROFICIAL'
}

export default checkout;
