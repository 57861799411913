export const contactInfo = {
  tagTitle:               'Informations de contact',
  metaTitle:              'Informations de contact',
  sectionTitle:           'Informations de contact',
  age:                    '%1 ans %2 mois',
  male:                   'Homme',
  female:                 'Femme',
  firstnameLabel:         'Nom',
  lastnameLabel:          'Nom de famille',
  ageLabel:               'Âge',
  taxvatLabel:            'DNI',
  genderLabel:            'Sexe',
  birthdayLabel:          'Date de naissance',
  dobLabel:               'Date de naissance',
  associatedClinicLabel:  'Clinique associée',
  languageLabel:          'Langue',
  telephoneLabel:         'Téléphone',
  regionLabel:            'Province',
  countryLabel:           'Pays',
  edit:                   "Modifier l'utilisateur",
  save:                   'Sauvegarder les changements',
  cancel:                 'Annuler'
}

export default contactInfo;
