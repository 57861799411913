export const signIn = {
  tagTitle:               'Se connecter',
  metaTitle:              'Se connecter',
  pageTitle:              'Utilisateur de Neoactives',
  sectionDescription:     'Si vous avez un compte, connectez-vous avec votre adresse électronique.',
  buttonLabel:            'Entrez',
  email:                  'Adresse électronique',
  password:               'Mot de passe',
  newCustomer:            'Nouvel utilisateur',
  createAccount:          'Créer un compte',
  createAccountBenefits:  'Créez votre compte utilisateur dans Neoactives',
  signIn:                 'Se connecter',
  forgottenPassword:      "J'ai oublié mon mot de passe"
}

export default signIn;
