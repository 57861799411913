import {
    ScriptManager,
    ThirdPartyScript
} from '@frontend/components/third-party-scripts';
import { connect }          from 'react-redux';
import { parseCookies }     from 'nookies';
import cookies              from '@frontend/settings/cookies'

export class GoogleTagManager extends ThirdPartyScript {
    static cookiesAllowed  = cookies.ANALYTICAL

    static mapStateToProps({ storeConfig }) {
        return {
            storeConfiguration: storeConfig,
        }
    }

    onLoaded() {
        const { storeConfiguration } = this.props;
        const additionalDataArray = storeConfiguration.additional_data_array;
        const script = document.createElement("script");
        if (additionalDataArray.tagManagerId != undefined) {
            (function (w, d, s, l, i) {
                w[l] = w[l] || []; w[l].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                }); var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
                //'https://www.googletagmanager.com/gtag/js?id=' + i + dl; f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', additionalDataArray.tagManagerId);
            console.log("GTM:loaded") //https://www.googletagmanager.com/gtm.js?id=
        }

    }

    onProductPage(product){
        const { storeConfiguration } = this.props;
        const additionalDataArray = storeConfiguration.additional_data_array;
        if (additionalDataArray.tagManagerId != undefined && additionalDataArray.remarketingTagId != undefined) {
            let cookies = parseCookies();
            const google_session_id = cookies["_gid"];
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('config', additionalDataArray.remarketingTagId, {
                'user_id': google_session_id
            });
            gtag('event', 'page_view', {
                'send_to': additionalDataArray.remarketingTagId,
                'ecomm_pagetype': 'product',
                'ecomm_prodid': product.sku,
                'ecomm_totalvalue': product.prices.final_price,
                'user_id': google_session_id
            });
            if (additionalDataArray.conversionLabel != undefined ){
                gtag('event', 'conversion', {
                    'send_to': `${additionalDataArray.remarketingTagId}/${additionalDataArray.conversionLabel}`,
                    'value': product.prices.final_price,
                    'currency': this.getCurrency()
                });
            }
        }
        
    }

    getCurrency() {
        return this.props.storeConfiguration.currencySymbol == "€" ? "EUR" : "USD";
    }

    getProductArray(products) {
        let result = [];
        if (products && products.length) {
            for (let index = 0; index < products.length; index++) {
                const element = products[index];
                result.push({
                    'name': element.name,     // Name or ID is required.
                    'id': element.sku,
                    'price': element.price,
                    'quantity': element.qty
                })
            }
        }

        return result;
    }

    onOrderSuccess(order) {
        const products = this.getProductArray(order.items);
        order.total_without_shipping = (parseFloat(order.total) - parseFloat(order.shipping)).toString();
        window.dataLayer.push({
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        'id': order.increment_id,                         // Transaction ID. Required for purchases and refunds.
                        'revenue': order.total_without_shipping,                     // Total transaction value (incl. tax and shipping)
                        'shipping': order.shipping
                    },
                    'products': products
                }
            }
        })
    }


    render() {
        this.subscribe()
        return null;
    }


}


export default connect(GoogleTagManager.mapStateToProps, {})(GoogleTagManager);




