import React, { Component } from 'react';
import {
    setCookie,
    parseCookies
} from 'nookies';
import _includes            from 'lodash/includes';
import checkServer          from '@frontend/util/checkServer';
import EzComponent          from '@frontend/components/EzComponent';
import Router               from 'next/router';
import cookies              from '@frontend/settings/cookies'

export class ScriptManager {
    isServer = checkServer()
    scripts = []
    queue =  null
    loaded = false
    component = null
    cookiesManagement = false
    constructor(queue,context={}){
        const allowed = parseCookies(context)['cookies-allowed'];
        this.queue    = queue || [{evt: "Cookies", data:{allowed}},{ evt: "Loaded", data: {} }]
    }
    setupScripts(script_components) {
        for (let script of script_components) {
            script[script.getKey()] = script
        }
        return script_components;
    }
    prepare() {
        if (!checkServer() && !this.prepared) {
            let cookies = parseCookies({});
            // if (!checkServer() && window && window.__SCRIPT_EVENTS_QUEUE__) {
            //     this.queue = window.__SCRIPT_EVENTS_QUEUE__;
            // }

            // add route event changes
            Router.events.on('routeChangeStart',    (url) => this.dispatch("RouteChangeStart",url      ))
            Router.events.on('routeChangeComplete', (url) => this.dispatch("RouteChangeComplete",url   ))
            this.dispatch("RouteChangeComplete",window.location.pathname)
            if (!cookies._track) {
                window.addEventListener("mousemove", this.load.bind(this))
                window.addEventListener("touchstart", this.load.bind(this))
            } else {
                this.load()
            }
        }
        this.prepared = true;
    }
    hydrate(){
        // const cookiesEvent = this.cookiesManagement ? [{evt: "Cookies", data:{allowed: parseCookies({})['cookies-allowed']}}] : [];
        // this.queue = [...cookiesEvent,{ evt: "Loaded", data: {} }];
        return this.queue;
    }
    load() {
        if (!this.loaded) {
            setCookie({}, '_track', 1, {
                maxAge: 30 * 24 * 60 * 60 * 12,
                path: '/',
            });
            this.loaded = true
            this.dispatchQueue()
            window.removeEventListener("mousemove", this.load)
            window.removeEventListener("touchstart", this.load)
        }
    }
    dispatchQueue() {
        const enableDispatch = this.cookiesManagement ? !!parseCookies({})['cookies-allowed'] : true;

        if(enableDispatch){
            for (let queued of this.queue) {
                let { evt, data } = queued;

                if(evt === 'Cookies'){
                    data.allowed = parseCookies({})['cookies-allowed'];
                }

                this.dispatchToScripts(evt, data)
            }
        }
    }
    dispatchToScript(script, evt, data) {
        script(this.loaded, evt, data)
    }
    dispatchToScripts(evt, data) {
        for (let script of this.scripts) {
            this.dispatchToScript(script, evt, data)
        }
    }
    dispatch(evt, data) {
        if (!this.prepared || !this.loaded) {
            this.queue.push({ evt, data })
        } else {
            if(checkServer()){
                this.queue.push({ evt, data })
            }
            this.dispatchToScripts(evt, data);
        }
    }

    subscribe(script) {
        this.scripts.push(script)
        for (let queued of this.queue) {
            let { evt, data } = queued
            this.dispatchToScript(script, evt, data)
        }
    }

    enableCookiesManagement(){
        this.cookiesManagement = true;
    }

}

export class ThirdPartyScript extends EzComponent {
    isServer = checkServer()
    allowed = false
    state = {
        subscribed: false,
        loaded: false,
        allowed: false
    }

    // componentDidMount(){
    //     this.subscribe();
    // }
    static cookiesAllowed  = cookies.ESSENTIAL

    onEvent(loaded, evt, data) {
        const allowed = this.context.scriptManager.cookiesManagement ? this.allowed : true;

        if (loaded && allowed && this[`on${evt}`] || this.context.scriptManager.cookiesManagement && evt === 'Cookies' && this[`on${evt}`]) {
            this[`on${evt}`](data)
        }
        if (!loaded && this[`_on${evt}`]) {
            this[`_on${evt}`](data)
        }
    }

    onCookies({allowed}){
        if(_includes(allowed,this.constructor.cookiesAllowed)){
            this.allowed = true;
            this.setState({allowed:true});
        }
    }
    onLoaded() {
        this.setState({ loaded: true })
    }

    prepare() {
        return;
    }

    subscribe() {
        if (!this.state.subscribed) {
            this.context.scriptManager.subscribe(this.onEvent.bind(this))
            if(!checkServer()){
                this.setState({ subscribed: true })
            }
        }
    }

    render() {
        this.subscribe()
        if (!checkServer() && this.state.loaded && this.props.children) {
            eval(this.props.children);
        }
        return null
    }
}

export class ThirdPartyScriptHydrater extends EzComponent {

    render() {
        return checkServer() ?
            <script dangerouslySetInnerHTML={{ __html: `window.__SCRIPT_EVENTS_QUEUE__ = ${JSON.stringify(this.scriptManager.hydrate())}` }}></script>
            : null
    }
}


export class ThirdPartyScriptManager extends EzComponent {
    isServer = typeof window == "undefined"
    state = {
        setup: false
    }

    componentDidMount(){
        this.context.scriptManager.prepare()
    }

    render() {
        // check if setup and prepared
        
        if(!this.state.setup){
            if(this.props.cookiesManagement){
                this.context.scriptManager.enableCookiesManagement();
            }
            this.context.scriptManager.prepare()
            if (!checkServer()){
                this.setState({setup:true})
            }
        }
        return (
            <>
                {this.props.children}
                {
                    !this.state.setup &&
                    <ThirdPartyScriptHydrater />
                }
            </>
        );
    }
}
export default ThirdPartyScriptManager;