import { general as storeGeneral }    from '@themes/store/frontend/locales/en/general';

export const general = {
  ...storeGeneral,
  uploadProgress: 'Uploading file: %1% completed',
  addToCart: 'add to basket',
  preOrder: 'pre order',
  seeProduct: 'View product',
  buy: 'Buy',
  vegan: 'vegan',
  addToCartMobile: 'add',
  noStock: 'product not available',
  fileError: 'You must include a file'
}

export default general;
