export const cookies = {
  theTeam:            "The team of ",
  andOtherCompanies:  " and other companies use cookies in order to provide you with a personalized shopping experience. To continue browsing you must accept all cookies by clicking the 'Accept' button. You can also modify your selection in this ",
  forMoreInfo:        " For more information you can visit our ",
  link:               "link",
  acceptButton:       "Accept",
  cookiesPolicy:      "Cookie Policy",
  configuration:      "Cookie settings",
  seeCookiesPolicy:   "View Cookie Policy",
  updateConfig:       "Cookie settings",
  functional: {
    title:  'Functional/technical cookies',
    text:   'Necessary to correctly display the web page and ensure the proper functioning of the site. They allow you to browse our website and use the different options or services it has. With them we can, for example, control traffic and data communication, identify you during the session, give you access to restricted areas, and use security features during your navigation.'
  },
  analytical: {
    title:  'Analytical Cookies',
    text:   'They are used to analyse user behaviour in aggregate and anonymous form including the number of visitors to the website and different internal pages, the origin of the visit, day and time, platform, search words used by a user to find their desired content. They provide us with statistical information about the behaviour of different users on our website in order to make improvements based on the analysis of data about the use of products and/or services on the website.'
  },
  advertising: {
    title:  'Advertising Cookies',
    text:   'They allow us to manage the advertising spaces included on our website, based on criteria such as the content shown or the frequency at which the ads are displayed.'
  },
  basic: {
    title:  'Essential Cookies',
    text:   'We need these cookies so that our website can function properly. It helps us to prevent any errors during navigation or to allow you to make a purchase. This way we can improve the quality of our service.'
  },
  acceptSelection:    "Accept selection",
  rejectAll:          "Reject all",
  session: {
    oneYear:          "Session / 1 year",
    halfHourTwoYears: "From 30 minutes to 2 years"
  }
}

export default cookies;
