import { createStore,
    applyMiddleware }         from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware         from 'redux-thunk'
import reducer                 from '@frontend/reducers'
import fetch                   from 'isomorphic-unfetch'
import checkServer             from '@frontend/util/checkServer';


const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__'
const initStore = (initialState = {}) => {
  return createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)))
}
const initRedux = () => {
  // Always make a new store if server
  if (checkServer()) {
    return initStore({});
  }else{
    if(!window.reduxStore){
      window.reduxStore = window && window.__NEXT_REDUX_STORE__ ? initStore(window.__NEXT_REDUX_STORE__) : initStore({});
    }
    return window.reduxStore;
  }
}

// Polyfill fetch() on the server (used by apollo-client)
if (typeof window === 'undefined') {
  global.fetch = fetch
}
export default initRedux;
