export const orderDetail = {
  pageTitle:        'Commandez',
  infoTitle:        'Informations sur la commande',
  itemsTitle:       'Articles demandés',
  orderId:          'N° de commande',
  date:             'Date',
  status:           'Statut',
  orderInfo:        'Détails de la commande',
  shippingAddress:  'Adresse de livraison',
  shippingMethod:   "Méthode d'expédition",
  billingAddress:   'Gestion de la facturation',
  billingMethod:    'Mode de paiement',
  orderItems:{
    name:       'Nom du produit',
    sku:        'SKU',
    price:      'Prix',
    qty:        'Qté.',
    subtotal:  'Sous-total'
  },
  orderTotals:{
    subTotal: 'Sous-total',
    shippingTax:  "Frais d'expédition et de manutention",
    tax: 'Taxes',
    total: 'Total'
  },
  pdf:{
    download: 'Télécharger le pdf',
    loading:  'Préparer le pdf...'
  }
}

export default orderDetail;
