import _get from 'lodash/get';
import _set from 'lodash/set';
import _each from 'lodash/each';
import _find from 'lodash/find';

const emptyCart = {
  cart:{
    items: [],
    id: "",
    subtotal: 0,
    subtotal_currency: "",
    is_active: false
  },
  showCart:false
};

const reducers = (state = emptyCart, action) => {
  switch(action.type) {
    case "SET_CART":
      const { cart } = action;
      return {...state, cart };
    case "DELETE_CART":
      return {...state, cart:emptyCart };
    case "OPEN_CART":
      return {...state, showCart:true};
    case "CLOSE_CART":
      return {...state, showCart:false};
    case "SET_CROSS_SELL_CATEGORY":
      const { crossSellCategory } = action;
      return {...state, crossSellCategory };
    case "SET_CART_BANNER":
      const { banner } = action;
      return {...state, banner };
    case "CART_ITEMS_ERRORS":
      const { cartItemsErrors } = action;
      let cartItems = _get(state, 'cart.items', []);
      _each(cartItems, (item) => {
        let error = _find(cartItemsErrors, ['key', item.item_id.toString()])
        if (error) {
          _set(item, 'error', error.value);
        }
      });
      return {
        ...state,
        cart: {
          ...state.cart,
          items: cartItems
        }
      };
    default:
      return state;
  }
}

export default reducers;
