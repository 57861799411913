export const resetPassword = {
  pageTitle:        'Recover password',
  description:      'Enter your email address and we’ll send you a link to recover your password.',
  returnToLogin:    'Return to log-in page',
  password:         'Password',
  confirmPassword:  'Confirm password',
  send:             'Submit',
  error:            'Error sending email, please check that you have entered the correct email address and try again.',
  success:          'Email sent, please check your inbox to reset your password.',
  button:{
    send:    'Submit',
    sent:    'Sent',
    sending: 'Sending'
  }
}

export default resetPassword;
