export const createAccount = {
  tagTitle:           "Create your account",
  metaTitle:          "Create your account",
  success:            "Account created successfully",
  pageTitle:          "New User",
  pageDescription:    "Create new customer account",
  firstName:          "Name",
  lastName:           "Surname",
  birthday:           "Date of birth",
  subscribe:          "Subscribe to our newsletter",
  gender:             "Sex",
  nif:                "ID Card",
  address:            "Street",
  telephone:          "Telephone",
  city:               "City",
  postCode:           "P.C.",
  region:             "Region",
  country:            "Country",
  personalInfo:       "Personal Information",
  accessInfo:         "Access information",
  email:              "Email",
  password:           "Password",
  confirmPassword:    "Confirm Password",
  buttonLabel:        "Create Account",
  linkSignin:         "I am already a user",
  accept:             "I accept the",
  privacyLink:        "privacy policy",
  newsletter:         "Subscribe to the newsletter"
}

export default createAccount;
