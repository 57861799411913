export const map = {
  sp:   "Spain",
  co:   "Colombia",
  ec:    "Ecuador",
  mx:    "Mexico",
  address: "Street, area or postcode",
  search: "Search",
  zeroResults: "Zero results found. Try again with another address.",
  selected_store: "Selected shop"
}

export default map;
