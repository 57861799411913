export const mail = {
  label:             'Mail',
  subjectDesc:       'Subject:',
  subjectInfo:       '[RE] We are Ezenit',
  destinationDesc:   'To:',
  destinationInfo:   'Paco Johnes',
  copyDesc:          'CC:',
  copyInfo:          'Paca Garte, Ana Tomía, Soila Cerdà',
  mailText:          'Hola Dolores Delano, \n Te paso un correo con lo que le pediste a Marco Gol Saludos, \n  Igor Dito',
  buttonDownload:    'Download Files',
  attached:          'Attached.jpg',
  buttonDraft:       'New Draft'
}

export default mail;
