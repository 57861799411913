export const footer = {
  links: {
    logguedCustomers: {
      title: "Registered users",
      items: {
        orderHistory: "Order history",
        myAccount: "My account",
        addressesBook: "Address Book"
      }
    },
    services: {
      title: "Services",
      items: {
        legalAdvice: "Legal notice",
        privacyPolicy: "Privacy Policy",
        shipmentPolicy: "Shipping and Returns Policy",
        warranty: "Warranties"
      }
    },
    information: {
      title: "Information",
      items: {
        aboutUs: "About us",
        contactUs: "Contact us"
      }
    }
  }
}

export default footer;
