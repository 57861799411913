export const footer = {
  links:{
    logguedCustomers:{
      title: 'Registrierte Benutzer',
      items:{
        orderHistory:   'Geschichte der Bestellung',
        myAccount:      'Mein Konto',
        addressesBook:  'Adressbuch'
      }
    },
    services:{
      title: 'Dienstleistungen',
      items:{
        legalAdvice:    'Rechtlicher Hinweis',
        privacyPolicy:  'Datenschutzerklärung',
        shipmentPolicy: 'Versand- und Rückgabepolitik',
        warranty:       'Garantien'
      }
    },
    information:{
      title: 'Informationen',
      items: {
        aboutUs:    'Über uns',
        contactUs:  'Kontakt'
      }
    }
  }
}

export default footer;
