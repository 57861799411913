export const mail = {
  label:             'Courrier',
  subjectDesc:       'Asunto:',
  subjectInfo:       'RE] Nous sommes Ezenit à fond là-bas haha',
  destinationDesc:   'A:',
  destinationInfo:   'Paco Johnes',
  copyDesc:          'CC:',
  copyInfo:          'Paca Garte, Ana Tomía, Soila Cerdà',
  mailText:          'Salut Dolores Delano, je vais vous envoyer un email avec ce que vous avez demandé à Marco Gol Salutations, Igor Dito',
  buttonDownload:    'Fichiers à télécharger',
  attached:          'Pièce jointe.jpg',
  buttonDraft:       'Nouveau projet'
}

export default mail;
