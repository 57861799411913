export const months = {
    month0:     "Janvier",
    month1:     "Février",
    month2:     "Mars",
    month3:     "Avril",
    month4:     "Mai",
    month5:     "Juin",
    month6:     "Juillet",
    month7:     "Août",
    month8:     "Septembre",
    month9:     "Octobre",
    month10:    "Novembre",
    month11:    "Décembre",
    january:    "Janvier",
    february:   "Février",
    march:      "Mars",
    april:      "Avril",
    may:        "Mai",
    june:       "Juin",
    july:       "Juillet",
    august:     "Août",
    september:  "Septembre",
    october:    "Octobre",
    november:   "Novembre",
    december:   "Décembre"
  }

export default months;
