import addresses      from '@frontend/locales/fr/user/my-account/addresses';
import contactInfo    from '@frontend/locales/fr/user/my-account/contact-info';
import orderDetail    from '@frontend/locales/fr/user/my-account/order-detail';
import orders         from '@frontend/locales/fr/user/my-account/orders';

export const myAccount = {
  addresses,
  contactInfo,
  orderDetail,
  orders,
  tagTitle:           'Mon compte',
  metaTitle:          'Mon compte',
  pageTitle:          'Mon compte',
  me:                 'Compte',
  myAdresses:         "Carnet d'adresses",
  myOrders:           'Mes commandes',
  accountInfo:        'Données personnelles',
  addressInfo:        "Carnet d'adresses",
  wishlist:           'Liste de souhaits',
  emptyWishlist:      "Vous n'avez pas encore ajouté de produits à votre liste de souhaits.",
  signOut:            'Déconnexion',
  billingAddresses:   'Adresse de facturation',
  shippingAddresses:  'Adresse de livraison',
  edit:               'Modifier',
  add:                'Ajouter',
  defaultAddresses:   'Adresses par défaut',
  aditionalEntries:   "Entrées d'adresses supplémentaires",

}

export default myAccount;
