import EzComponent                        from '@frontend/components/EzComponent'
import { connect }                        from 'react-redux'
import { MEDIA_QUERY_MAX_BREAKPOINT }     from '@frontend/constants';
import { setIsMobile }                    from '@frontend/actions/is-mobile';

class MediaQueriesManager extends EzComponent{

    static mapStateToProps({isMobile}){
      return { isMobile }
    }

  screenValidation = ({matches}) =>{
    const { isMobile,setIsMobile } = this.props;

    if((isMobile && matches) || !isMobile && !matches) return;

    setIsMobile(matches);
  }

  componentDidMount(){
    const { media=MEDIA_QUERY_MAX_BREAKPOINT } = this.props;
    const mediaQueryList                       = window.matchMedia(media);

    mediaQueryList.addListener(this.screenValidation);
    this.screenValidation(mediaQueryList);
  }

  render(){
    return null
  }
}

export default connect(MediaQueriesManager.mapStateToProps,{setIsMobile})(MediaQueriesManager);
