export const info = {
  inStock:                'En stock',
  notStock:               'Pas de stock',
  qty:                    'Quantité',
  description:            'Description',
  composition:            'Composition',
  opinions:               'Opinions',
  operatingInstructions:  "Mode d'emploi",
  shippingReturns:        'Expédition et retours'
}

export default info;
