export const checkout = {
  yourOrder:        'Your order number is: %1',
  confirmationText: 'We will send you a confirmation email with all your order details and delivery information.',
  continueBuying:   'Continue shopping',
  verificationText: 'You can check the status of your order by creating an account.',
  createAccount:    'Create an account',
  failureText:      'There has been an issue with your order. Please try again.',
  deliveryTime:     'for choosing us, your order will be delivered to your home in 24/48 hours',
  confirmation:     'in a few minutes, we’ll send you a confirmation email with your order tracking details',
  home:             'home',
}

export default checkout;
