let header = {};

const reducers = (state = header, action) => {
  switch(action.type) {
    case "SET_HEADER_HEIGHT":
      return {...state, height:action.headerHeight};
    default:
      return state;
  }
}

export default reducers;
