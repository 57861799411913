const footer = "";

const reducers = (state = footer, action) => {
  switch(action.type) {
    case "SET_FOOTER":
      return action.footer;
    default:
      return state;
  }
}

export default reducers;
