import { combineReducers }    from "redux";
import user                   from "@frontend/reducers/user";
import minicart               from "@frontend/reducers/minicart";
import storeConfig            from "@frontend/reducers/storeConfig";
import storeCountries         from "@frontend/reducers/storeCountries";
import flashMessages          from '@frontend/reducers/flashMessages';
import order                  from '@frontend/reducers/order';
import category               from '@frontend/reducers/category';
import categoryTree           from '@frontend/reducers/categoryTree';
import product                from '@frontend/reducers/product';
import wishlist               from '@frontend/reducers/wishlist';
import megamenu               from '@frontend/reducers/megamenu';
import footer                 from '@frontend/reducers/footer';
import newsletter             from '@frontend/reducers/newsletter';
import cmsPage                from '@frontend/reducers/cmsPage';
import search                 from '@frontend/reducers/search';
import searchResultsPageData  from '@frontend/reducers/search-results-page';
import activeInfoBlock        from '@frontend/reducers/active-info-block';
import showAccountModal       from '@frontend/reducers/account-modal';
import swatchesModal          from '@frontend/reducers/swatches-modal';
import addToCartModal         from '@frontend/reducers/add-to-cart-modal';
import sideBar                from '@frontend/reducers/side-bar';
import header                 from '@frontend/reducers/header';
import seo                    from '@frontend/reducers/seo';
import theme                  from '@frontend/reducers/theme';
import mapStores              from '@frontend/reducers/map-stores';
import looks                  from '@frontend/reducers/looks';
import postCollection         from '@frontend/reducers/post-collection';
import isMobile               from '@frontend/reducers/isMobile';
import actualOrder            from '@frontend/reducers/sales';
import actualPost             from '@frontend/reducers/post-content';
import nextPrevPost           from '@frontend/reducers/nextprev-post';
import canRMA                 from '@frontend/reducers/rma';
import cookies                from '@frontend/reducers/cookies';
import popup                  from "@frontend/reducers/popup";
import breadcumbs             from "@frontend/reducers/breadcrumbs";
import contactResponse        from '@frontend/reducers/contact';


export default combineReducers(
  {
    user,
    minicart,
    storeConfig,
    storeCountries,
    flashMessages,
    order,
    category,
    categoryTree,
    product,
    wishlist,
    newsletter,
    megamenu,
    footer,
    cmsPage,
    canRMA,
    search,
    searchResultsPageData,
    activeInfoBlock,
    showAccountModal,
    sideBar,
    isMobile,
    header,
    seo,
    theme,
    mapStores,
    looks,
    postCollection,
    isMobile,
    actualOrder,
    swatchesModal,
    addToCartModal,
    actualPost,
    nextPrevPost,
    cookies,
    popup,
    breadcumbs,
    contactResponse
  }
);
