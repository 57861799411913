export const reviews = {
  count: '%1 critiques',
  showMore: 'voir plus',
  showLess: 'voir moins',
  addReview: {
    button: 'Laissez un commentaire',
    title: 'Laissez votre opinion',
    send: 'Envoyer'
  }
}

export default reviews;
