export const orders = {
  tagTitle:   'My orders',
  metaTitle:  'My orders',
  notOrders:  "You have no orders associated with your account.",
  orderId:    "Order number",
  date:       "Date",
  sendTo:     "Send to",
  total:      "Order total",
  status:     "Status",
  actions:    "Action",
  detail:     "View order",
  statusValues: {
    pending:    "Pending",
    canceled:   "Canceled",
    closed:     "Closed",
    complete:   "Completed",
    holded:     "Holded",
    processing: "Processing"
  }
}

export default orders;
