let newsletterResult = null;

const reducers = (state = newsletterResult, action) => {
  switch(action.type) {
    case "SET_SUBSCRIPTION_RESULT":
      return action.newsletterResult;
    case "DELETE_SUBSCRIPTION_RESULT":
      return null;
    default:
      return state;
  }
}

export default reducers;
