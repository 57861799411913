export const orderDetail = {
  pageTitle:        "Order",
  infoTitle:        "Order information",
  itemsTitle:       "Items requested",
  orderId:          "Order number",
  date:             "Date",
  status:           "Status",
  orderInfo:        "Order details",
  shippingAddress:  "Shipping address",
  shippingMethod:   "Shipping Method",
  billingAddress:   "Billing Address",
  billingMethod:    "Payment Method",
  orderItems: {
    name:     "Product name",
    sku:      "SKU",
    price:    "Price",
    qty:      "Qty.",
    subtotal: "Subtotal"
  },
  orderTotals: {
    subTotal:     "Subtotal",
    shippingTax:  "Shipping and handling costs",
    tax:          "Taxes",
    total:        "Total"
  },
  pdf: {
    download: "Download pdf",
    loading:  "Preparing pdf ..."
  }
}

export default orderDetail;
