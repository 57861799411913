export const pdf = {
  download:         'Download pdf',
  loading:          'Preparing pdf...',
  invoice:          'Invoice',
  company:          'NEOLIFE MEDICAL GROUP, S.L.',
  addressData1:     'c/ Velázquez, 94 · 28006 Madrid · Tel: +34 91 732 59 50 · Oasis Business Center, CN-340, km.176 · 29602 Marbella. Málaga · Tel: +34 95 130 44 88',
  addressData2:     'Tax ID: B-86.640.398. Madrid Commercial Registry. Volume 30689. Section 8, Folio 41, Sheet M 552218, Entry 1',
  email:            'info@neolifeclinic.com',
  website:          'www.neolifeclinic.com',
  invoiceNumber:    'Invoice Number',
  date:             'Date',
  vatId:            'ID/Tax ID',
  phone:            'Tel',
  shippingAddress:  'Delivery address',
  billingAddress:   'Billing address',
  units:            'Units',
  unitPvp:          'Unit retail price',
  subtotal:         'Subtotal',
  shippingTax:      'Shipping and handling fees',
  tax:              'Taxes',
  total:            'Total',
  paymentMethod:    'Payment method',
  endMessage:       'Thank you for putting your trust in us. We hope to see you soon at your next Etnia appointment.'
}
export default pdf;
