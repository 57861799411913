let flash = { messages: [] };

const reducers = (state = flash, action) => {
  let messages = null;
  switch (action.type) {
    case "FLASH_MESSAGES_ADD":
      let newMessage = action.message;
      let id = 1;
      if (state.messages.length){
        id = state.messages[(state.messages.length-1)].id;
        id += 1;
      }
      newMessage = {...newMessage,id}
      messages = state.messages;
      messages.push(newMessage)
      return {...state,messages};
    case "FLASH_MESSAGES_DELETE":
      messages = state.messages.filter((value)=> value.id !== action.id);
      return {...state,messages};
    case "FLASH_MESSAGES_LIST":
      return state;
    default:
      return state;
  }
};
export default reducers;
