export const newsletter = {
  recaptchaError:     'Es ist notwendig, das reCaptcha zu validieren',
  subscribe:          'Abonnieren Sie unseren Newsletter',
  subscribeInfo:      'Seien Sie der Erste, der von unseren Werbeaktionen und Rabatten erfährt',
  emailPlaceholder:   'Geben Sie Ihre E-Mail ein...',
  subscriptionError:  'Fehler beim Abonnieren des Newsletters. Überprüfen Sie Ihre E-Mail und versuchen Sie es später noch einmal.',
  emailError:         'El formato de correo electrónico no es correcto: ejemplo@dominio.com',
  success:            'Wir haben die E-Mail-Adresse als Abonnent registriert'
}

export default newsletter;
