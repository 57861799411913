import EzComponent                from "@frontend/components/EzComponent";
import { connect }                from "react-redux";
import Collapsible                from '@frontend/components/cms/collapsible';
import CookieConfiguratorBoxTable from "@frontend/components/third-party-scripts/cookies/component/configurator/box/table";
import CookieConfiguratorBoxLabel from "@frontend/components/third-party-scripts/cookies/component/configurator/box/label";

import                            '@frontend/components/third-party-scripts/cookies/component/configurator/box/index.less';

export class CookieConfiguratorBox extends EzComponent {
  static mapStateToProps({ isMobile, storeConfig: { cookiesData } }) {
    return {
      cookiesData,
      isMobile
    };
  }

  prepareCookies = () => {
    const {
      type,
      cookiesData: {
        cookieList
      }
    } = this.props;

    const cookiesClasified = [];

    if(cookieList){
      const cookies = JSON.parse(cookieList);
  
      Object.keys(cookies).forEach(key => {
        if( cookies[key].cookie_type === type) {
          cookiesClasified.push(cookies[key]);
        }
      });

    }

    return cookiesClasified;
  }

  render() {
    const {
      isMobile,
      type,
      checked,
      toggleAcceptance
    } = this.props;

    return (
      !isMobile ?
        <div className="cookie">
          <CookieConfiguratorBoxLabel
            type={type}
            toggleAcceptance={toggleAcceptance}
            checked={checked}
          />
          <div className="cookie-description">
            <span className="cookie-name">
              &bull; {this.i18n.t(`cookies.${type}.title`)}
            </span>
            : {this.i18n.t(`cookies.${type}.text`)}
            <CookieConfiguratorBoxTable cookies={this.prepareCookies()} />
          </div>
        </div>
      :
        <Collapsible 
          className="cookie"
          key={type}
          tagtitle="div"
          title={(
            <>
            <CookieConfiguratorBoxLabel
              type={type}
              toggleAcceptance={toggleAcceptance}
              checked={checked}
            />
              <span className="title">{this.i18n.t(`cookies.${type}.title`)}</span>
            </>
          )}
        >
          <div className="cookie-description">
            {this.i18n.t(`cookies.${type}.text`)}
            <CookieConfiguratorBoxTable cookies={this.prepareCookies()} />
          </div>
        </Collapsible>
    );
  }
}

export default connect(CookieConfiguratorBox.mapStateToProps)(CookieConfiguratorBox);
