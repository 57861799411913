import EzComponent            from '@frontend/components/EzComponent';
import {connect}              from 'react-redux';
import {setOpenedCollapsible} from '@frontend/actions/collapsible';

import '@frontend/components/cms/collapsible/index.less';

export class Collapsible extends EzComponent{

  toggle = () => {
    const {collapsible, title} = this.props;
    this.props.setOpenedCollapsible(collapsible == title ? null : this.props.title);
  }

  render(){
    const { className="",title, tagtitle: TagTitle, children=null, key, collapsible} = this.props;
    const active = collapsible == title ? true : false;

    return (
      <div key={key} className={`ez-collapsible${active ? ' active': ''} ${className}`}>
        <TagTitle className="ez-collapsible-toggle" onClick={this.toggle}>
          { title }
        </TagTitle>
        <div className="ez-collapsible-content-wrapper">
          <div className="ez-collapsible-content">
            { children }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({collapsible}){
  return {
    collapsible
  }
}

export default connect(mapStateToProps,{setOpenedCollapsible})(Collapsible);