export const footerNewsletter = {
  recaptchaError:     'Il est nécessaire de valider le reCaptcha.',
  recaptchaError:     "Il est nécessaire d'accepter la politique de confidentialité",
  subscribe:          'Newsletter',
  emailPlaceholder:   'email',
  emailEmpty:         'Courriel requis',
  subscriptionError:  "Erreur d'inscription à la newsletter. Vérifiez votre courrier électronique et réessayez plus tard.",
  send:               "S'abonner à",
  accept:             "J'accepte le ",
  privacyLink:        'politique de confidentialité'
}

export default footerNewsletter;
