
import {
    ScriptManager,
    ThirdPartyScript
} from '@frontend/components/third-party-scripts';
import { connect } from 'react-redux';

export class Nixi1 extends ThirdPartyScript {

    static mapStateToProps({ storeConfig }) {
        return {
            storeConfiguration: storeConfig,
        }
    }

   onLoaded(){
       const { storeConfiguration } = this.props;
       const additionalDataArray = storeConfiguration.additional_data_array;
       if (additionalDataArray.nixi1Token != undefined) {
           var nixi1_script = '//aocs.l1l.co/nixi1-aocs.js';
           (function (d, t) {
               var f = d.createElement(t), s = d.getElementsByTagName(t)[0]; f.async = 1;
               f.src = ('https:' == location.protocol ? 'https:' : 'http:') + nixi1_script;
               f.setAttribute('data-aocsToken', additionalDataArray.nixi1Token);
               f.onload = () => {console.log("NIXI1:loaded");window.dispatchEvent(new Event('load'))};
               s.parentNode.insertBefore(f, s)
           }(document, 'script'));
           
       }
   }



    render() {
        this.subscribe()
        return null;
    }


}


export default connect(Nixi1.mapStateToProps, {})(Nixi1);