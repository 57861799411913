export const tribe = {
  name: "Name and surname(s) *",
  birth: "Date of birth *",
  phone: "Telephone number *",
  town:  "Town/City",
  region:"Province",
  email: "Email address *",
  exp:   "Do you have experience as a make-up artist?",
  title: "join our family",
  yes:   "Yes",
  no:    "No",
  cv:    "Upload your CV in pdf format",
  file:  "file...",
  file_success: "File successfully uploaded",
  file_success_name: "File %1 successfully uploaded",
  he:    "I have",
  success_message: "We have received your details and will be in touch with you as soon as possible. Thank you!",
  terms: "read and accept",
  conditions: "the conditions of the ",
  privacy: "privacy policy. *",
  mandatory: "* Mandatory fields",
  send:   "submit",
  errors:{
    terms: "Please accept the conditions.",
    general: "This field is mandatory"
  }
}

export default tribe;
