export const accountMenu = {
  profile: 'My profile',
  orders: 'My orders',
  wishlist: 'Wish list',
  signout: 'Log out',
  signin: 'Log in',
  editProfile: 'edit profile',
  description: 'Here, you can view and edit all your profile information, or log out of your account.',
  createAccount: 'Create account',
  myAccount: 'My account'
}

export default accountMenu;
