export const signIn = {
  tagTitle:               "Sign in",
  metaTitle:              "Sign in",
  pageTitle:              "User",
  sectionDescription:     "If you have an account, please log in with your email address",
  buttonLabel:            "Enter",
  email:                  "Email",
  password:               "Password",
  newCustomer:            "New User",
  createAccount:          "Create account",
  createAccountBenefits:  "Create your user account",
  signIn:                 "Sign in",
  forgottenPassword:      "I forgot my password"
}

export default signIn;
