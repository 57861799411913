import { ZandmanApp as StoreZandmanApp }                  from '@themes/store/frontend/pages/_app';
import { setMegamenu,getMegamenu }                        from '@frontend/actions/megamenu';
import { getCMSBlock }                                    from '@frontend/actions/cms_block';
import { setFooter }                                      from '@frontend/actions/footer';
import { getUserData }                                    from '@frontend/actions/user';
import { getCartData }                                    from '@frontend/actions/cart';
import { getStoreCurrencies }                             from '@frontend/actions/store_currency';
import UrlBuilder                                         from '@frontend/services/UrlBuilder';
import _get                                               from 'lodash/get';

import '@frontend/less/index.less';

class ZandmanApp extends StoreZandmanApp{

  static async getMegamenuData(ctx,storeConfig){
    // get Megamenu Data
    const variables    =  {
      id: (storeConfig && storeConfig.megamenuSettings) ? storeConfig.megamenuSettings.desktopMegamenu : 'none',
      storeId: storeConfig.storeId
    };
    return await ctx.store.dispatch(getMegamenu(ctx,variables));
  }

  static async getFooterData(ctx,storeConfig){
    const footerId       = {id:storeConfig.footerIdentifier};
    return await ctx.store.dispatch(getCMSBlock(ctx,footerId));

  }
  static async getInitialProps(appContext){

    const storeProps      = await super.getInitialProps(appContext);
    if (!storeProps) {
      return false;
    }
    const { ctx }         = appContext;
    const { storeConfig } = storeProps;

    // get menu data
    const megamenuData    = await this.getMegamenuData(ctx,storeConfig);
    // get footer Data
    const footerCMSBlock = await this.getFooterData(ctx,storeConfig)

    return { ...storeProps,megamenuData,footerCMSBlock};
  }

  static async updateAppContext(appContext){
    await super.updateAppContext(appContext);
    appContext.ctx.router = new UrlBuilder({currentStore:appContext.ctx.storeConfig.currentStore});
  }

  // initServices(){
  //   const storeServices                   = super.initServices();
  //   const { storeConfig:{ currentStore }} = this.props;
  //   const router                          = new UrlBuilder({currentStore});
    
  //   return {...storeServices,router};
  // }

  initRedux(store,client,cookies){
    const { storeConfig, megamenuData,footerCMSBlock } = this.props;

    super.initRedux(store,client,cookies);

    store.dispatch(setMegamenu(megamenuData));
    store.dispatch(setFooter(footerCMSBlock));
    store.dispatch(getStoreCurrencies({client},{ storeId:parseInt(storeConfig.storeId), websiteId: parseInt(storeConfig.websiteId)}));
  }

  frontendReduxActions(store,client,cookies){
    const { storeConfig } = this.props;
    const { storeId }     = storeConfig;
    const tokenCookie     = _get(cookies, `token-${storeId}`);
    const cartCookie      = _get(cookies, `cart-${storeId}`);
    // user info
    if(tokenCookie){
      if (!store.getState().user || !store.getState().user.id){
        store.dispatch(getUserData({client}, {token: tokenCookie, storeId: parseInt(storeId)}));
      }else if(!store.getState().minicart || !store.getState().minicart.cart || !store.getState().minicart.cart.quote_id){
        store.dispatch(getCartData({client}, {quoteId:"",storeId: parseInt(storeId), customerId:store.getState().user.id,customerGroup:store.getState().user.group_id,enableCrossSellProducts:storeConfig.enableMinicartCrossSell}));
      }
    }else{
      // cart info
      if(cartCookie){
        store.dispatch(getCartData({client}, {quoteId:cartCookie,storeId: parseInt(storeId), customerId:null,enableCrossSellProducts:storeConfig.enableMinicartCrossSell}));
      }
    }
  }
}

export default ZandmanApp;
export {ZandmanApp};
