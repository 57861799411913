let category = null;

const reducers = (state = category, action) => {
  switch(action.type) {
    case "SET_CATEGORY":
      return action.category;
    default:
      return state;
  }
}

export default reducers;
