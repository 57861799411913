export const contactInfo = {
  sectionTitle:           'Contact information',
  nameLabel:              'Name',
  emailLabel:             'Email',
  male:                   'Male',
  female:                 'Female',
  ageLabel:               'Age',
  taxvatLabel:            'ID number',
  genderLabel:            'Sex',
  birthdayLabel:          'Date of birth',
  dobLabel:               'Date of birth',
  telephoneLabel:         'Telephone number',
  regionLabel:            'Province',
  countryLabel:           'Country',
  edit:                   'Edit User',
  save:                   'Save Changes',
  cancel:                 'Cancel'
}

export default contactInfo;
