import {
	ThirdPartyScript
} from '@frontend/components/third-party-scripts';
import { connect } from 'react-redux';
import _get from 'lodash/get'
import '@frontend/components/third-party-scripts/payments/sequra/index.less';

export class ProductSequra extends ThirdPartyScript {
	state = {
		subscribed: false,
		loaded: false,
		location: null,
		onProductPage: false
	}
	static mapStateToProps({ storeConfig }) {
		return {
			storeConfiguration: storeConfig
		};
	}

	onLoaded() {
		console.log("Sequra:loaded");
		this.setState({ loaded: true });
		

	}


	onProductPage(product) {
		console.log("Sequra: ProductPage");
		this.setState({ onProductPage: true, product })
	}

	resetCurrentPage() {
		this.setState({ onProductPage: false })
	}

	onPublicPage() {
		console.log("SEQURA: PublicPage")
		this.resetCurrentPage();
	}

	onPrivatePage() {
		this.resetCurrentPage();
	}

	


	renderOnProductPage() {
		const { storeConfiguration } = this.props;
		if (_get(storeConfiguration, "additional_data_array.sequraUserName")) {
			const sequraConfigParams = {
				merchant: storeConfiguration.additional_data_array.sequraUserName, 		// Your merchant reference given by SeQura.
				assetKey: storeConfiguration.additional_data_array.sequraAssetsKey, 	// Your asset key given by SeQura.
				products: ["sp1"], 							// List of SeQura products that you want to include components.
				scriptUri: storeConfiguration.additional_data_array.sequraAssetsUri, 	// SeQura Javascript library uri for production or sandbox.
				decimalSeparator: ",", 						// Decimal separator used in currencies formatting. Optional, default `,`.
				thousandSeparator: ".", 					// Thousand separator used in currencies formatting. Optional, default `.`.
			};

			window["SequraConfiguration"] = sequraConfigParams;
			window["SequraOnLoad"] = [];
			window["Sequra"] = {};
			window["Sequra"]["onLoad"] = function (callback) {
				window["SequraOnLoad"].push(callback);
			};
			window["Sequra"].onLoad(function () {
				window["Sequra"].refreshComponents();
			});
			var a = document.createElement("script")
			var m = document.getElementsByTagName("script")[0];
			a.async = 1;
			a.src = sequraConfigParams.scriptUri;
			m.parentNode.insertBefore(a, m);
		}
	}

	render() {
		this.subscribe();
		if (this.state.onProductPage) {
			this.renderOnProductPage()
		}
		return null;
	}
}

export default connect(ProductSequra.mapStateToProps)(ProductSequra);

