
export function getProductViewMediaGallery (storeConfiguration, media_gallery, selectedProductId = null, product = false){
    const { galleryTypes } = storeConfiguration;
    if (media_gallery && media_gallery.length) {
        let gallery = JSON.parse(media_gallery);
        if (!gallery['productGallery'].length) {
            return [];
        }
        let productGallery = JSON.parse(gallery['productGallery']);

        if (selectedProductId && gallery['childGallery'] && gallery['childGallery'][selectedProductId]) {
            if (!gallery['childGallery'][selectedProductId].length) {
                return [];
            }
            productGallery = JSON.parse(gallery['childGallery'][selectedProductId]);
        }

        if (product && product.is_configurable && (product.product_options.length == 0 || !selectedProductId)) {
            if (gallery && gallery.length) {
                let first_child_id = Object.keys(gallery['childGallery'])[0];
                productGallery = JSON.parse(gallery['childGallery'][first_child_id]);
            }
        }

        return (productGallery[galleryTypes['product_view']]) ?
            productGallery[galleryTypes['product_view']]
            : [];
    }
}