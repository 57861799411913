export const miniCart = {
  cart:               'Warenkorb',
  qty:                'Anzahl',
  itemDelete:         'löschen',
  noItems:            'Sie haben keine Produkte in Ihrem Warenkorb',
  oneProductInCart:   'Artikel im Korb',
  productsInCart:     'Artikel im Korb',
  subTotal:           'Zwischensumme',
  subTotalDiscount:   'Mit Rabatt',
  buttonCheckout:     'Kauf abschließen',
  continueBuying:     'Weiter einkaufen'
}

export default miniCart;
