import { addresses as storeAddresses } from '@themes/store/frontend/locales/en/user/my-account/addresses';

export const addresses = {
  ...storeAddresses,
  defaultSelect:{
    notDefault:             'is not a default address',
    defaultShipping:        'delivery address',
    defaultBilling:         'billing address',
    defaultShippingBilling: 'billing and delivery address',
    label:                  'Choose an option'
  },
  delete:               'Delete address',
  defaultBillingTitle:  'Default billing address',
  defaultShippingTitle: 'Default delivery address'
}

export default addresses;
