const sideBar = {
    show: false,
    component: null,
    backTo: [],
    props:{}
};

const reducers = (state = sideBar, action) => {
  switch(action.type) {
    case "OPEN_SIDE_BAR":
      return {show: true,component: action.component, backTo: action.backTo, props:action.props};
    case "CLOSE_SIDE_BAR":
      return {show: false,component: null,backTo:[],props:{}};
    default:
      return state;
  }
}

export default reducers;