const emptyCart = {
  visible:false,
  showConfig: false
};

const reducers = (state = emptyCart, action) => {
  switch(action.type) {
    case "SHOW_COOKIES":
      return {...state, visible:true};
    case "HIDE_COOKIES":
      return {...state, visible:false};
    case "SHOW_COOKIES_CONFIG":
      return {...state, showConfig:true};
    case "HIDE_COOKIES_CONFIG":
      return {...state, showConfig:false};
    default:
      return state;
  }
}

export default reducers;
