export const accountMenu = {
  profile: 'Mon profil',
  orders: 'Mes ordres',
  wishlist: 'Liste de souhaits',
  signout: 'Déconnexion',
  signin: 'Connexion',
  createAccount: 'Créer un compte'
}

export default accountMenu;
