export const orders = {
  tagTitle:   'Meine Bestellungen',
  metaTitle:  'Meine Bestellungen',
  notOrders:  'Sie haben keine Aufträge, die mit Ihrem Konto verbunden sind.',
  orderId:    'Bestellnummer',
  date:       'Datum',
  sendTo:     'Senden an',
  total:      'Auftragssumme',
  status:     'Stand',
  actions:    'Aktion',
  detail:     'Bestellung ansehen',
  statusValues:{
    pending:    'Ausstehend',
    canceled:   'Annulliert',
    closed:     'Geschlossen',
    complete:   'Abgeschlossen',
    holded:     'Zurückbehalten',
    processing: 'In Bearbeitung'
  }
}

export default orders;
