
import { ThirdPartyScript } from '@frontend/components/third-party-scripts';
import { connect } from 'react-redux';

export class SocialRoom extends ThirdPartyScript {



    static mapStateToProps({ storeConfig }) {
        return {
            storeConfiguration: storeConfig,
        }
    }

    onSocialRoom() {
        
        var jquery_script = '//cdnjs.cloudflare.com/ajax/libs/jquery/2.2.4/jquery.js';
        (function (d, t) {
            var f = d.createElement(t), s = d.getElementsByTagName(t)[0]; f.async = 1;
            f.onload = () => {
                var social_room_script = '//lgnsr2.fra1.digitaloceanspaces.com/js/cdn-social-room-loader.min.js';
                (function (d, t) {
                    var f = d.createElement(t), s = d.getElementsByTagName(t)[0]; f.async = 1;
                    f.id = "lgn-sr-script";
                    f.src = ('https:' == location.protocol ? 'https:' : 'http:') + social_room_script;
                    f.setAttribute('charset', 'utf-8');
                    f.onload =  () => {
                        jQuery.socialRoomLoader({
                            unique: "42933",
                            hash: "f7d6a4ae38790b416efd004a640a1b16",
                            lang: "ES",
                            type: "gallery"
                        });
                    }
                    s.parentNode.insertBefore(f, s);
                }(document, 'script'));
                console.log("SocialRoomJquery:Loaded");
            }
            f.src = ('https:' == location.protocol ? 'https:' : 'http:') + jquery_script;
            f.setAttribute('charset', 'utf-8');
            s.parentNode.insertBefore(f, s);
        }(document, 'script'));
       
        
        console.log("SocialRoom:loaded");
    }

    onLoaded(){
        var jquery_script = '//cdnjs.cloudflare.com/ajax/libs/jquery/2.2.4/jquery.js';
        (function (d, t) {
            var f = d.createElement(t), s = d.getElementsByTagName(t)[0]; f.async = 1;
            f.onload = () => {
                var social_room_script = '//lgnsr2.fra1.digitaloceanspaces.com/js/native-social-room-loader.min.js';
                (function (d, t) {
                    var f = d.createElement(t), s = d.getElementsByTagName(t)[0]; f.async = 1;
                    f.id = "lgn-sr-script";
                    f.src = ('https:' == location.protocol ? 'https:' : 'http:') + social_room_script;
                    f.setAttribute('charset', 'utf-8');
                    f.onload = () => {
                        new SocialRoomLoaderNative({
                            unique: "42933",
                            hash: "f7d6a4ae38790b416efd004a640a1b16",
                            lang: "ES",
                            type: "slider"
                        });
                    }
                    s.parentNode.insertBefore(f, s);
                }(document, 'script'));
                console.log("SocialRoomHomeLoaded:Loaded");
            }
            f.src = ('https:' == location.protocol ? 'https:' : 'http:') + jquery_script;
            f.setAttribute('charset', 'utf-8');
            s.parentNode.insertBefore(f, s);
        }(document, 'script'));
    }

    render() {
        this.subscribe()
        return null;
    }


}


export default connect(SocialRoom.mapStateToProps, {})(SocialRoom);