export const createAccount = {
  tagTitle:         'Erstelle deinen Account',
  metaTitle:        'Erstelle deinen Account',
  success:          'Erfolgreich erstelltes Konto',
  pageTitle:        'BENUTZERKONTO ANLEGEN',
  pageDescription:  'Neues Kundenkonto erstellen',
  firstName:        'Name',
  lastName:         'Nachname',
  birthday:         'Geburtsdatum',
  subscribe:        'Abonnieren Sie unseren Newsletter',
  gender:           'Geschlecht',
  nif:              'NIF/NIE/CIF',
  address:          'Adresse',
  telephone:        'Telefon',
  city:             'Bevölkerung',
  postCode:         'HERZ-LUNGEN-WIEDERBELEBUNG.',
  region:           'Provinz',
  country:          'Land',
  personalInfo:     'Persönliche Informationen',
  accessInfo:       'Zugang zu Informationen',
  email:            'E-Mail',
  password:         'Passwort',
  confirmPassword:  'Passwort bestätigen',
  buttonLabel:      'Konto erstellen',
  linkSignin:       'Ich bin bereits Benutzer',
  accept:           'Ich akzeptiere die ',
  privacyLink:      'AGB',
  newsletter:       'Abonnieren Sie den Newsletter'
}

export default createAccount;
