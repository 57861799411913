import {
    ScriptManager,
    ThirdPartyScript
} from '@frontend/components/third-party-scripts';
import { connect } from 'react-redux';
import cookies     from '@frontend/settings/cookies'

export class Facebook extends ThirdPartyScript {

    static cookiesAllowed  = cookies.ADVERTISING

    static mapStateToProps({ storeConfig }) {
        return {
            storeConfiguration: storeConfig,
        }
    }

    onLoaded() {
        const { storeConfiguration } = this.props;
        const additionalDataArray = storeConfiguration.additional_data_array;
        const script = document.createElement("script");
        if (additionalDataArray.facebookId != undefined) {
            !function (f, b, e, v, n, t, s) {
                if (f.fbq) return; n = f.fbq = function () {
                    n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                n.queue = []; t = b.createElement(e); t.async = !0;
                t.src = v; s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s)
            }(window, document, 'script',
                'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', additionalDataArray.facebookId);
            fbq('track', 'PageView');
            console.log("FACEBOOK:loaded")
        }

    }

    onRouteChangeComplete(url) {
        fbq('track', 'PageView');
    }

    onOrderSuccess(order) {
        if (!(order && order.items)) {
            return;
        }
        let products = [];
        order.items.map((item) => {
            let product = {};
            product['name'] = item.name;
            product['id'] = item.sku;
            product['quantity'] = item.qty;
            product['price'] = parseFloat(item.price);
            products.push(product);
        });

        fbq('track', 'Purchase', {
            value: parseFloat(order.total),
            currency: 'EUR',
            contents: products,
            content_ids: products.map(({id})=> id),
            content_type: 'product'
        });
        this.setState({ currentPage: window.location.href })
    }

    getProductBySku(products, sku) {
        let result = false;
        if (products) {
            if (products.length > 1) {
                for (let index = 0; index < products.length; index++) {
                    const element = products[index];
                    if (element.sku == sku) {
                        result = element;
                    }
                }
            } else {
                result = products[0];
            }
        }
        return result;
    }

    onAddedToCart({ product, cart }) {
        const quote_item = this.getProductBySku(cart.items, product.sku);
        if (parseFloat(quote_item.price) > 0) {
            fbq('track', 'AddToCart', {
                value: parseFloat(quote_item.price),
                currency: 'EUR',
                contents: [
                    {
                        quantity: parseInt(product.qty),
                        id: product.sku
                    }
                ],
                content_ids: product.sku,
                content_type: 'product'
            });
        }
    }
    

    onProductPage(product) {
        if (!(product && product.prices && product.prices.final_price)) {
            return;
        }
        if (product != null) {
            fbq('track', 'ViewContent', {
                value: product.prices.final_price,
                currency: 'EUR',
                content_ids: product.sku,
                content_type: "product"
            });
        }
    }


    render() {
        this.subscribe()
        return null;
    }


}


export default connect(Facebook.mapStateToProps, {})(Facebook);




