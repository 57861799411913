export const validator = {
  empty:                    "Le champ est vide",
  email:                    "Ce champ doit être une adresse électronique valide",
  phone:                    "Ce champ doit être un numéro de téléphone valide",
  letters:                  "Ce champ ne peut contenir que des lettres",
  lettersNumbers:           "Ce champ ne peut contenir que des lettres et des chiffres",
  numbers:                  "Ce champ ne peut contenir que des chiffres",
  float:                    "Ce champ ne peut contenir que des chiffres avec des décimales.",
  validPassword:            "Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule et un chiffre, et doit comporter au moins 8 caractères.",
  passwordnotmatch:         "Les mots de passe ne correspondent pas",
  notoken:                  "Aucun jeton fourni",
  notEmpty:                 "Ce champ ne peut pas être vide",
  lettersWithSpaces:        "Ce champ ne peut contenir que des lettres avec des espaces",
  lettersNumbersWithSpaces: "Ce champ ne peut contenir que des lettres et des chiffres avec des espaces.",
  integerOrDecimal:         "Ce champ ne peut contenir que des chiffres",
  "validDate(DD/MM/YYYY)":  "La fecha debe tener el formato DD/MM/YYYY"
}

export default validator;
