import { ZandmanApp as MagentoZandmanApp}     from '@themes/magento/frontend/pages/_app';
import { setMapStores,getMapStores }          from '@frontend/actions/map-stores';
import { getLooks, setLooks }                 from '@frontend/actions/looks';
import { getUserData }                        from '@frontend/actions/user';
import { getCrossSellCategory,setCartBanner } from '@frontend/actions/cart';
import { setPopupBlock,setPopupVisibility }   from '@frontend/actions/popup';
import { getCMSBlock }                        from '@frontend/actions/cms_block';
import SeoMetatags                            from '@frontend/components/third-party-scripts/seo/metatags';
import GoogleTagManager                       from '@frontend/components/third-party-scripts/seo/google-tag-manager';
import Doofinder                              from '@frontend/components/third-party-scripts/search/doofinder';
import Nixi1                                  from '@frontend/components/third-party-scripts/chat/nixi1';
import Hotjar                                 from '@frontend/components/third-party-scripts/tracking/hotjar';
import Facebook                               from '@frontend/components/third-party-scripts/tracking/facebook';
import SocialRoom                             from '@frontend/components/third-party-scripts/misc/social-room';
import CookiesScript                          from '@frontend/components/third-party-scripts/cookies';
import TrustedReviewsWidget                   from '@frontend/components/third-party-scripts/reviews/trusted-reviews-widget';
import ProductSequra                          from '@frontend/components/third-party-scripts/payments/sequra';
import GoogleAnalytics                        from '@frontend/components/third-party-scripts/seo/google-analytics';
import GoogleAnalyticsFour                    from '@frontend/components/third-party-scripts/seo/google-analytics-four';
import Senderglobal                           from '@frontend/components/third-party-scripts/seo/senderglobal';
import TrustedShops                           from '@frontend/components/third-party-scripts/reviews/trusted-shops'
import Breadcrumbs                            from '@frontend/components/third-party-scripts/tracking/breadcrumbs';
import {ThirdPartyScriptManager}              from '@frontend/components/third-party-scripts';
import settings                               from '@frontend/settings/components/popup';
import _get                                   from 'lodash/get';

import '@frontend/less/index.less';

export class ZandmanApp extends MagentoZandmanApp{

  static async getCartBannerData(ctx, storeConfig){
    const cartBannerIdentifier = { id: _get(storeConfig, "cartBannerIdentifier") };
    return await ctx.store.dispatch(getCMSBlock(ctx, cartBannerIdentifier));
  }

  static async getPopupData(ctx, storeConfig){
    const popupIdentifier = { id: _get(storeConfig, "popupIdentifier") };
    return await ctx.store.dispatch(getCMSBlock(ctx, popupIdentifier));
  }

  static async getInitialProps(appContext){
    const magentoProps        = await super.getInitialProps(appContext);
    const { ctx }             = appContext;
    const { storeConfig }     = magentoProps;

    const cartBannerCmsBlock  = await this.getCartBannerData(ctx, storeConfig);
    const popupCmsBlock       = await this.getPopupData(ctx, storeConfig)

    return {...magentoProps, cartBannerCmsBlock, popupCmsBlock};
  }

  initRedux(store,client,cookies){
    const { cartBannerCmsBlock, popupCmsBlock } = this.props;

    super.initRedux(store,client,cookies);

    store.dispatch(setCartBanner(cartBannerCmsBlock));
    store.dispatch(setPopupBlock(popupCmsBlock, _get(settings, "keys.main")));
  }

  frontendReduxActions(store,client,cookies){
    const { storeConfig } = this.props;
    const { cartCategoryId } = storeConfig;
    super.frontendReduxActions(store,client,cookies);
    if((!store.getState().minicart || !store.getState().minicart.crossSellCategory) && cartCategoryId){
      store.dispatch(getCrossSellCategory({client}, {id: parseInt(cartCategoryId), actualPage: 1, pageSize: 20, selectedFilters: [], storeId: parseInt(storeConfig.storeId)}));
    }
    const mainPopupVisibility = _get(cookies, 'main-popup') ? parseInt(_get(cookies, 'main-popup')) : 1;
    store.dispatch(setPopupVisibility(mainPopupVisibility, _get(settings, "keys.main")));
  }

  renderThirdPartyScripts(){
    return (
        <ThirdPartyScriptManager cookiesManagement={true}>
          <SeoMetatags/>
          <GoogleAnalytics/>
          <GoogleAnalyticsFour/>
          <GoogleTagManager/>
          <Doofinder/>
          <Nixi1/>
          <Hotjar/>
          <Facebook/>
          <SocialRoom/>
          <CookiesScript/>
          <TrustedReviewsWidget/>
          <ProductSequra />
          <TrustedShops/>
          <Breadcrumbs/>
          <Senderglobal/>
        </ThirdPartyScriptManager>
    )
  }

}

export default ZandmanApp;

