import { categoryPage as storeCategoryPage } from '@themes/store/frontend/locales/en/category-page';

export const categoryPage = {
  ...storeCategoryPage,
  rawEssenceTitle: "The raw essence.",
  rawEssenceSubtitle: "An introspection through smell, a route inside ourselves.",
  capilarTitle: "HONEST",
  capilarSubtitleFirstPart: "daily",
  capilarSubtitleSecondPart: "hair care",
  capilarText: "recover your hair with the new line",
}

export default categoryPage;
