export const looks ={
  looks:{
    title:    "LOOKS",
    inspire:  "to inspire",
    text1:     "Beauty doesn’t mean covering up – it’s about revealing your true self.",
    text2:     "This motto is what drives us, that’s why we’re working to offer you a new concept – smart cosmetics – that adapts to you, your tastes and your needs, allowing you to rediscover yourself."
  }
}

export default looks;
