import getStoreCurrenciesQuery      from '@frontend/services/queries/getStoreCurrencies.graphql';

export const setStoreCurrencies = ( currencies ) =>{
  return {
    type: "SET_STORE_CURRENCIES",
    currencies
  }
}
export const getStoreCurrencies = ({client},variables) => {
  return async (dispatch) => {
    let currencies = await client.query({
            query:getStoreCurrenciesQuery,
            variables
          }).then(({data:{ storeCurrencies }})=>{
            return storeCurrencies;
        });
    return dispatch(setStoreCurrencies(currencies));
  }
}
