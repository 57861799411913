
import {ThirdPartyScript} from '@frontend/components/third-party-scripts';
import { connect }        from 'react-redux';

export class Doofinder extends ThirdPartyScript {

    state = {
        subscribed: false,
        loaded: false,
        location : null
    }
    
    static mapStateToProps({ storeConfig }) {
        return {
            storeConfiguration: storeConfig,
        }
    }

    onLoaded() {
        const { storeConfiguration } = this.props;
        const {langCode}             = storeConfiguration;
        const additionalDataArray    = storeConfiguration.additional_data_array;
        
        if (additionalDataArray.doofinderId != undefined) {
            var doofinder_script = '//cdn.doofinder.com/media/js/doofinder-classic.7.latest.min.js';
            (function (d, t) {
                var f = d.createElement(t), s = d.getElementsByTagName(t)[0]; f.async = 1;
                f.src = ('https:' == location.protocol ? 'https:' : 'http:') + doofinder_script;
                f.setAttribute('charset', 'utf-8');
                s.parentNode.insertBefore(f, s)
            }(document, 'script'));

            if(window){
                window.dfClassicLayers = [{
                    "hashid": additionalDataArray.doofinderId,
                    "zone": "eu1",
                    "display": {
                        "lang": langCode
                    },
                    "queryInput": ".ez-search-input input"
                }];
            }
            
        }

        console.log("DOOF:loaded")
    }


    onPrivatePage(){
        this.setState({location: (window && window.location ? window.location.href : null) })
        //this.reloadDoofinder();
    }

    onPublicPage(){
        this.setState({location: (window && window.location ? window.location.href : null) })
        //this.reloadDoofinder();
    }
    
    reloadDoofinder() {
        const { storeConfiguration:{langCode} } = this.props;

        if (typeof window === 'undefined') {
            return;
        }
        const { storeConfiguration } = this.props;
        const additionalDataArray = storeConfiguration.additional_data_array;
        if (additionalDataArray.doofinderId != undefined && window && window.doofinder) {
            window.doofinder.classic.setLayers([{
                "hashid": additionalDataArray.doofinderId,
                "zone": "eu1",
                "display": {
                    "lang": langCode
                },
                "queryInput": ".ez-search-input input"
            }])
        }
        console.log("DOOF:reloaded")
    }


    render() {
        this.subscribe()
        this.reloadDoofinder();
        return null;
    }


}


export default connect(Doofinder.mapStateToProps, {})(Doofinder);