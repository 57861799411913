import Head                             from 'next/head'
import { ThirdPartyScript }             from '@frontend/components/third-party-scripts';
import { getProductViewMediaGallery }   from '@frontend/services/MediaGallery';
import { connect }                      from 'react-redux';
import LdJson                           from '@frontend/components/third-party-scripts/seo/ld-json';
import _endsWith                        from "lodash/endsWith"
import _findKey                         from 'lodash/findKey';
import _map                             from 'lodash/map';
import _get                             from 'lodash/get';
import _values                          from 'lodash/values';
import _replace                         from 'lodash/replace';
import _find                            from 'lodash/find';
import { getProductPrices }             from '@frontend/services/PriceCalculation';
import settings                         from '@frontend/settings/third-party-scripts/seo/metatags';

const EMPTY_CURRENT_PAGE = {
    onProductPage: false,
    onCategoryPage: false,
    onCmsPage: false,
    onThankYouPage: false,
    onPublicPage: false,
    onPrivatePage: false,
    data: null
}

const XDEFAULT = "x-default";

export class SeoMetatags extends ThirdPartyScript {
    currentPage = EMPTY_CURRENT_PAGE;

    state = {
        loaded: false,
        subscribed: false,
        page: {}

    }

    static mapStateToProps({ storeConfig, user, storeCountries }) {
        return {
            storeConfiguration: storeConfig,
            user,
            defaultCountry: storeCountries.default
        }
    }

    resetCurrentPage() {
        this.currentPage = { ...EMPTY_CURRENT_PAGE };
    }

    // ON PRODUCT PAGE
    _onProductPage(product) {
        this.resetCurrentPage()
        this.currentPage.onProductPage = true
        this.currentPage.data = product
        this.setState({ page: this.currentPage, product })
    }

    onProductPage(product) {
        this._onProductPage(product)
    }

    renderOnProductPage() {
        const product = this.currentPage.data;
        if (!(product && product.prices)) {
            return;
        }
        const { storeConfiguration } = this.props;
        this.prices = this.getCalculatedPrices(product.prices, storeConfiguration);
        let meta_title = "";
        let meta_description = "";


        if (product.meta_description) {
            meta_description = product.meta_description;
        } else {
            if (product.description && product.description != null) {
                meta_description = product.description.replace(/<[^>]*>?/gm, '').replace(/[\n\r]/g,' ').substring(0, 199);
            }
        }

        if (product.meta_title != null) {
            meta_title = product.meta_title;
        } else {
            meta_title = product.name;
        }
        // Meta title and description
        let image = (JSON.parse(product.media_gallery)) ? JSON.parse(JSON.parse(product.media_gallery).productGallery).product_base_image : '';
        if (image) {
            image = image[0];
        }
        let productUrl = this.router.getStoreProductUrl(storeConfiguration, { id: product.product_id, slug: product.path });
        if (typeof (product.canonical) != undefined && product.canonical != null) {
            productUrl = product.canonical;
        }
        let alternate_urls = [];
        if (typeof (product.alternate) != undefined && product.alternate != null && product.alternate.length > 10) {
            let alternateArray = JSON.parse(product.alternate)
            for (let index = 0; index < alternateArray.length; index++) {
                const element = alternateArray[index];
                if (element["store"].includes("_") && element["store"] != storeConfiguration.storeCode) {
                    let langcode = (element["store"] == storeConfiguration.additional_data_array.xDefault) ? XDEFAULT : element["store"].split("_")[1];
                    alternate_urls.push(
                        <link
                            key={`${element["url"]}-${element["store"]}`}
                            rel="alternate"
                            hreflang={`${langcode}`}
                            href={`${element["url"]}`} />
                    )
                }

            }
        }
        return (
            <>
                <Head>
                    <link rel="canonical" href={productUrl} key="canonical" />
                    <meta key="meta-title" name="title" content={meta_title}  />
                    <title key="page-title">{meta_title}</title>
                    <meta key="meta-description" name="description" content={meta_description} />
                    <meta key="og:type" property="og:type" content="product" />
                    <meta key="og:title" property="og:title" content={meta_title} />
                    <meta key="og:image" property="og:image" content={image} />
                    <meta key="og:description" property="og:description" content={meta_description} />
                    <meta key="og:url" property="og:url" content={productUrl} />
                    {
                        this.prices && this.prices.final_price && <meta key="product:price" property="product:price:amount" content={(this.prices.final_price)} />
                    }
                    {
                        alternate_urls.length &&
                        alternate_urls
                    }
                    <meta key="product:price:currency" property="product:price:currency" content="EUR" />
                    <meta key="og:brand" property="og:brand" content={storeConfiguration.additional_data_array.meta_brand ? storeConfiguration.additional_data_array.meta_brand : ''} />
                    <meta key="og:availability" property="og:availability" content={this.hasStock(product) ? "in stock" : "out of stock"} />
                    <meta key="og:condition" property="og:condition" content="new" />
                    <meta key="og:retailer_item_id" property="og:retailer_item_id" content={product.product_id} />
                </Head>
                <LdJson key="breadcrumb-snippet" data={this.getBreadcrumbObject(product.name, productUrl)} />
                <LdJson key="product-snippet" data={this.getProductObject(product, storeConfiguration, meta_description)} />
            </>
        )

    }

    hasStock(product){
        if(product && product.product_id) {
            if(product.is_configurable){
                let childs = JSON.parse(product.product_childs);
                return _findKey(childs, function(child) { return child.stock > 0; }) !== undefined;
            } else {
                return product.stock > 0;
            }
        }
        return false;
    }

    getProductObject(product, storeConfiguration, meta_description) {
        let image = getProductViewMediaGallery(storeConfiguration, product.media_gallery, null, product )
        if (image) {
            image = image[0];
        }
        // getStoreProductUrl
        const productUrl = this.router.getStoreProductUrl(storeConfiguration, { id: product.product_id, slug: product.path });
        const prices = this.getCalculatedPrices(product.prices, storeConfiguration);

        let productObject = {
            "@context": "http://schema.org/",
            "@type": "Product",
            "sku": product.sku,
            "category": "",
            "name": product.name,
            "url": productUrl,
            "image": image,
            "description": meta_description,
            "brand": {
                "@type": "Brand",
                "name": storeConfiguration.additional_data_array.meta_brand ? storeConfiguration.additional_data_array.meta_brand : ''
            },
            "offers": [{
                "@type": "Offer",
                "sku": product.sku,
                "availability": this.hasStock(product) ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
                "price": prices.final_price.toString(),
                "priceValidUntil": (prices.toDate && prices.toDate.isValid()) ? prices.toDate.toISOString().split('T')[0] : "",
                "priceCurrency": "EUR",
                "url": productUrl,
                "image": image,
                "itemCondition": "New", "seller": {
                    "@type": "Organization",
                    "name": storeConfiguration.additional_data_array.meta_brand ? storeConfiguration.additional_data_array.meta_brand : ''
                }
            }],
            //añadir un bloque “offer” por cada tono disponible
            // "aggregateRating": {
            //   "@type": "AggregateRating",
            //   "itemReviewed": {
            //     "name": product.name
            //   },
            //   "ratingCount": "44",
            //   "reviewCount": "44",
            //   "ratingValue": "4.6"
            // },
            // "review": {
            //   "@type": "Review",
            //   "itemReviewed": {
            //     "name": product.name
            //   },
            //   "reviewRating": {
            //     "@type": "Rating",
            //     "ratingValue":"5"
            //   }
            // }
            //añadir un bloque “review” por cada opinión del producto registrada
        }
        if(product.ean){
          const identifier = "gtin" + product.ean.length;
          productObject[identifier] = product.ean;
        }
        if (product.is_configurable === true && product.children && product.children.length) {
            for (var i = 0; i < product.children.length; i++) {
                let currentChildren = product.children[i];
                prices = this.getCalculatedPrices(currentChildren.product.prices, storeConfiguration);
                productObject["offers"].push({
                    "@type": "Offer",
                    "sku": currentChildren.sku,
                    "availability": this.hasStock(product) ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
                    "price": prices.final_price.toString(),
                    "priceValidUntil": (prices.toDate && prices.toDate.isValid()) ? prices.toDate.toISOString().split('T')[0] : "",
                    "priceCurrency": "EUR",
                    "url": productUrl,
                    "image": currentChildren.image ? currentChildren.image.src : "",
                    "itemCondition": "New", "seller": {
                        "@type": "Organization",
                        "name": storeConfiguration.additional_data_array.meta_brand ? storeConfiguration.additional_data_array.meta_brand : ''
                    }
                });
            }
        }
        return productObject;
    }

    getCalculatedPrices(prices, storeConfiguration) {
        const { storeConfiguration:{excludedTaxCustomerGroup},user,defaultCountry } = this.props;
        return getProductPrices({ user, excludedTaxCustomerGroup, prices, defaultCountry, storeConfig: storeConfiguration});
    }

    // ON CATEGORY PAGE
    _onCategoryPage(category) {
        this.resetCurrentPage()
        this.currentPage.onCategoryPage = true
        this.currentPage.data = category
        this.setState({ page: this.currentPage, category })
    }

    onCategoryPage(category) {
        this._onCategoryPage(category)
    }
    renderAdditionalOnCategoryPage() {
        return null;
    }
    renderOnCategoryPage() {
        const category = this.currentPage.data;
        if (!(category && category.category_id)) {
            return;
        }
        const { storeConfiguration } = this.props;
        let meta_title = "";
        let meta_description = "";

        if (category) {
            meta_title = category.meta_title ? category.meta_title : category.name;
        }
        if (category) {
            if (category.meta_description) {
                meta_description = category.meta_description;
            } else {
                if (category.description) {
                    meta_description = category.description.replace(/<[^>]*>?/gm, '').replace(/[\n\r]/g,' ').substring(0, 199);
                }
            }
        }
        let finalUrl = this.router.getStoreCategoryUrl(storeConfiguration, { id: category.category_id, slug: category.url_path || category.path });
        if (typeof(category.canonical) != undefined && category.canonical != null) {
            finalUrl = category.canonical;
        }
        let alternate_urls = [];
        if (typeof (category.alternate) != undefined && category.alternate != null && category.alternate.length > 10) {
            let alternateArray = JSON.parse(category.alternate)
            for (let index = 0; index < alternateArray.length; index++) {
                const element = alternateArray[index];
                if (element["store"].includes("_") && element["store"] != storeConfiguration.storeCode) {
                    let langcode = (element["store"] == storeConfiguration.additional_data_array.xDefault) ? XDEFAULT : element["store"].split("_")[1];
                    alternate_urls.push(
                        <link
                            key={`${element["url"]}-${element["store"]}`}
                            rel="alternate"
                            hreflang={`${langcode}`}
                            href={`${element["url"]}`} />
                    )
                }
                
            }
        }
        return (
            <>
                {
                    category &&
                    <>
                        <Head>
                            <link rel="canonical" href={finalUrl} key="canonical" />
                            <meta key="page-meta-title" name="title" content={meta_title} />
                            <title key="page-title">{meta_title}</title>
                            <meta key="page-meta-description" name="description" content={meta_description} />
                            <meta key="page-meta-keyword" name="keyword" content={category.meta_keyword ? category.meta_keyword : ""} />
                            <meta key="og:title" property="og:title" content={meta_title} />
                            <meta key="og:description" property="og:description" content={meta_description} />
                            <meta key="og:url" property="og:url" content={finalUrl} />
                            {
                                alternate_urls.length &&
                                alternate_urls
                            }
                        </Head>
                        <LdJson key="breadcrumb-snippet" data={this.getBreadcrumbObject(category.name, finalUrl)} />
                        { this.renderAdditionalOnCategoryPage()}
                    </>
                }

            </>
        )
    }

    getBreadcrumbObject(name, url) {
        let breadcrumbObject = {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 0, "item": {
                        "@id": this.router.getBaseUrl(),
                        "name": "Inicio"
                    }
                }, {
                    "@type": "ListItem",
                    "position": 1, "item": {
                        "@id": url,
                        "name": name
                    }
                }
            ]
        }
        return breadcrumbObject;
    }

    // ON CMS PAGE
    _onCmsPage(cms_page) {
        this.resetCurrentPage()
        this.currentPage.onCmsPage = true;
        this.currentPage.data = cms_page;
        this.setState({ page: this.currentPage, cms_page })
    }

    onCmsPage(cms_page) {
        this._onCmsPage(cms_page)
    }

    /**
     * @method
     * Checks if current page is home page
     * 
     * @returns {Boolean}
     */
    isHomePage = (page) => {
        return _get(page,'identifier','') === 'home';
    }

    /**
     * @method
     * Returns langcode by store path
     * 
     * @param {String} path 
     * 
     * @returns {String} langcode
     */
    getLangCodeByPath = (path) => {
      const langPath = path.replace('/', "");

      if (langPath.includes("-")) {
        return langPath.split("-")[0];
      }

      if (langPath.includes("_")) {
        return langPath.split("_")[1];
      }

      if(langPath != ""){
        return langPath;
      }

      return "x-default";
    }

    /**
     * @method
     * Check if current page is store selection page
     * 
     * @param {Object} page 
     * 
     * @returns {Boolean}
     */
    isStoreSelection = page => {
      return _get(page,'identifier','') === settings.defaultHome;
    }

    getCanonical(url){
      return  <link rel="canonical" href={url} key="canonical" />
    }


    renderOnCmsPage() {
      const page                 = this.currentPage.data;
      const {storeConfiguration} = this.props;
      const stores               = _get(this.props,'storeConfiguration.stores');

      if (!(page && page.page_id)) {
          return;
      }

      const route = this.router.get("cmsPage", { id: page.page_id, slug: page.identifier });
      let finalUrl = this.router.getUrlFromRoute(route);
      
      if (finalUrl.includes("/home")) {
          finalUrl = finalUrl.split("/home")[0]
          if (storeConfiguration.additional_data_array.home_canonical != undefined && storeConfiguration.additional_data_array.home_canonical != null) {
              finalUrl = this.props.storeConfiguration.additional_data_array.home_canonical
          }
      }
      let metatitle = _get(page,'metatitle','') || _get(page,'title','');
      let title     = _get(page,'title','')     || _get(page,'metatitle','');
      let alternate_urls = [];
      let alternateArray = []

      if(this.isStoreSelection(page)){
        finalUrl = this.router.getStoreSelection(page);

        _map(stores,({domain,path,storeId}) => {
          const langcode = this.getLangCodeByPath(path)

          alternate_urls.push(
            <link
              key={`${domain}${path}-${langcode}`}
              rel="alternate"
              hreflang={langcode}
              href={`${domain}${path}/`}
            />
          )
        });
      }

      else if(this.isHomePage(page)){
          let alternateArray = _map(_values(stores),({domain, path, storeId}) => ({store: path.replace('/', ""),url: `${domain}${path}`,storeId}))
          _map(alternateArray, element => {
              let langcode = this.getLangCodeByPath(element['store']);
              alternate_urls.push(
                  <link
                      key={`${element["url"]}-${element["store"]}`}
                      rel="alternate"
                      hreflang={`${langcode }`}
                      href={`${element["url"]}/`} />
              )
          })
          for (let index = 0; index < alternateArray.length; index++) {
              const element = alternateArray[index];
              if (element["store"].includes("_")) {
                  let langcode = element["store"].split("_")[1];
                  alternate_urls.push(
                      <link
                          key={`${element["url"]}-${element["store"]}`}
                          rel="alternate"
                          hreflang={`${langcode}`}
                          href={`${element["url"]}`} />
                  )
              }
          }
      }
      else{
          if (typeof (page.alternate) != undefined && page.alternate != null && page.alternate.length > 10) {
              alternateArray = JSON.parse(page.alternate)
          }
          else if(typeof (page.alternate) == 'object'){
            alternateArray = _map(stores,({domain, path, storeId}) => {
                const requestPath = _find(page.alternate, alternate => _get(alternate, 'store_id') == storeId);
                if(!!requestPath){
                    return {
                        store: _replace(path, '/', ''),
                        url: this.router.getAlternateUrlFromRoute({domain, path, storeId},_get(requestPath, 'request_path')), 
                        storeId
                    }
                }
            })
          }
    
          for (let index = 0; index < alternateArray.length; index++) {
            const element = alternateArray[index];
            if(!!element){
                let langcode = this.getLangCodeByPath(element["store"]);
                alternate_urls.push(
                    <link
                        key={`${element["url"]}-${element["store"]}`}
                        rel="alternate"
                        hreflang={`${langcode}`}
                        href={`${element["url"]}`} />
                )

                if(langcode === _get(storeConfiguration, "defaultStore", "")){
                  alternate_urls.push(
                    <link
                        key={`x-default`}
                        rel="alternate"
                        hreflang={"x-default"}
                        href={`${element["url"]}`} />
                  )
                }
            }
        }
      }

      // Add x-default on store selection and home pages
      if((this.isStoreSelection(page) || this.isHomePage(page)) && stores.length >= _get(settings, 'minStoresXDefault')){
        const defaultUrl = _get(this.props, 'storeConfiguration.currentStore.storeSelectionUrl');
        alternate_urls.push(
            <link
                key={`${defaultUrl}`}
                rel="alternate"
                hreflang={this.getLangCodeByPath("")}
                href={`${defaultUrl}/`}
            />
        );
    }

      return (
        <Head>
            {this.getCanonical(finalUrl)}
            { title && <title>{title}</title>}
            <meta key="page-meta-title" name="title" content={metatitle} />
            <meta key="page-meta-desc" name="description" content={page.meta_description} />
            <meta key="page-meta-keywords" name="keywords" content={page.meta_keywords} />
            {
                alternate_urls.length &&
                alternate_urls
            }
            {this.getCmsOpenGraphData(finalUrl)}
          </Head>
      )
    }

    /**
     * Returns OpenGraph metatags for current cms page
     * @param string url 
     * @returns 
     */
    getCmsOpenGraphData(url){
      return null;
    }

    renderAllPages() {
        const { storeConfiguration } = this.props;
        let robots = "index,follow";
        if (storeConfiguration.additional_data_array && storeConfiguration.additional_data_array.robots && storeConfiguration.additional_data_array.robots == "test") {
            robots = "noindex,nofollow";
        }
        return (
            <>
                <Head>
                    <meta   key="viewport"      name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0,minimal-ui"/>
                    <meta   key="robots"        name="robots" content={robots} />
                    <meta   key="apple-mobile"  name="apple-mobile-web-app-capable" content="yes" />
                    <meta   key="mobile"        name="mobile-web-app-capable" content="yes"/>
                    <link   key="favicon"       rel="shortcut icon" type="image/x-icon" href="/static/favicon.ico" />
                    {
                        storeConfiguration.seo_verification_code &&
                        <meta key="site-verification" name="google-site-verification" content={storeConfiguration.seo_verification_code} />
                    }
                </Head>
                <LdJson key="organization-snippet" data={this.getOrganizationObject(storeConfiguration)} />
                <LdJson key="website-snippet" data={this.getWebsiteObject(storeConfiguration)} />
            </>
        )
    }

    getWebsiteObject(storeConfiguration) {
        let websiteObject = {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": storeConfiguration.additional_data_array.meta_brand ? storeConfiguration.additional_data_array.meta_brand : '',
            "url": this.router.getBaseUrl(),
            "potentialAction":
            {
                "@type": "SearchAction",
                "target": this.router.getUrlFromPath("/search-result?q={search_term_string}"),
                "query-input": "required name=search_term_string"
            }
        }
        return websiteObject;
    }

    getOrganizationObject(storeConfiguration) {

        let organizationObject = {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": storeConfiguration.additional_data_array.meta_brand ? storeConfiguration.additional_data_array.meta_brand : '',
            "url": this.router.getBaseUrl(),
            "logo": storeConfiguration.seo_logo,
            "sameAs": storeConfiguration.same_as_urls ? storeConfiguration.same_as_urls.split(',') : "",
            "description": storeConfiguration.additional_data_array.meta_description ? storeConfiguration.additional_data_array.meta_description : "",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": storeConfiguration.seo_street,
                "addressRegion": storeConfiguration.seo_region,
                "addressLocality": storeConfiguration.seo_city,
                "postalCode": storeConfiguration.seo_postcode,
                "addressCountry": storeConfiguration.seo_country
            },
            "contactPoint": [
                {
                    "@type": "ContactPoint",
                    "telephone": storeConfiguration.seo_phone,
                    "email": storeConfiguration.seo_email
                }
            ]
        }
        return organizationObject;
    }

    onRouteChangeComplete(url) {
        this.setState({ currentPage: url })
    }

    _onPublicPage(page_metadata){
        this.resetCurrentPage();
        this.currentPage.onPublicPage = true;
        this.setGenericPageData(page_metadata);
    }

    onPublicPage(page_metadata){
        this._onPublicPage(page_metadata);
    }

    _onPrivatePage(page_metadata) {
        this.resetCurrentPage();
        this.currentPage.onPrivatePage = true;
        this.setGenericPageData(page_metadata);
    }

    onPrivatePage(page_metadata) {
        this._onPrivatePage(page_metadata);
    }

    _onProductPageNotFound(page_metadata) {
      this.resetCurrentPage();
      this.currentPage.onProductPageNotFound = true;
      this.setGenericPageData({
        title:              this.i18n.t('general.notFound'),
        meta_title:         this.i18n.t('general.notFound'),
        meta_description:   this.i18n.t('general.notFound'),
        meta_keywords:      this.i18n.t('general.notFound')
      });
    }

    onProductPageNotFound(page_metadata) {
        this._onProductPageNotFound(page_metadata);
    }

    setGenericPageData(page_metadata){
        if(page_metadata){
            this.currentPage.data                   = {};
            this.currentPage.data.title             = page_metadata.title ? this.i18n.t(page_metadata.title) : '';
            this.currentPage.data.meta_title        = page_metadata.meta_title ? this.i18n.t(page_metadata.meta_title) : '';
            this.currentPage.data.meta_description  = page_metadata.meta_description ? this.i18n.t(page_metadata.meta_description) : '';
            this.currentPage.data.meta_keywords     = page_metadata.meta_keywords ? this.i18n.t(page_metadata.meta_keywords) : '';
            this.setState({ page: this.currentPage, page_metadata });
        }
    }

    renderOnGenericPage(){
        const page = this.currentPage.data;
        if (!page) return;

        return (
            <Head>
                <title>{page.title}</title>
                <meta key="page-meta-title" name="title" content={page.meta_title} />
                <meta key="page-meta-desc" name="description" content={page.meta_description} />
                <meta key="page-meta-keywords" name="keywords" content={page.meta_keywords} />
            </Head>
        );
    }

    isNoneOfAbove(){
        return  !this.currentPage.onProductPage &&
                !this.currentPage.onCategoryPage &&
                !this.currentPage.onCmsPage &&
                !this.currentPage.onProductPageNotFound;
    }

    render() {
        this.subscribe()
        return (
            <>
                {  this.renderAllPages()}
                { this.currentPage.onProductPage && this.renderOnProductPage()}
                { this.currentPage.onCategoryPage && this.renderOnCategoryPage()}
                { this.currentPage.onCmsPage && this.renderOnCmsPage()}
                { this.isNoneOfAbove() && this.renderOnGenericPage() }
            </>
        );
    }
}

export default connect(SeoMetatags.mapStateToProps, {})(SeoMetatags);
