export const blog = {
  mainTitle:                'Etnia Cosmetics Blog: Latest trends in makeup and cosmetics',
  mainPageTitle:            'The best makeup and cosmetic items - Etnia Cosmetics Blog',
  mainPageMetaDescription:  'Discover the latest makeup and cosmetic trends on the Etnia Cosmetics Blog. Tricks and beauty tips to look even more beautiful. Get in now!',
  categories:     'categories',
  menu_text:      'Find what you’re looking for, have all your questions answered and discover the best tips to get the most out of your products',
  recentPosts:    'Most recent entries',
  latest:         'latest posts',
  latest_message: 'discover everything you didn’t know about cosmetics with our blog',
  read_more:      'read more'
}

export default blog;
