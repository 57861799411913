import { createAccount as storeCreateAccount } from '@themes/store/frontend/locales/en/user/create-account';

export const createAccount = {
  ...storeCreateAccount,
  success:          'You have been successfully signed up',
  pageTitle:        'Sign up',
  pageDescription:  'to add your favourite products to your wish list and discover all our offers',
  pageTitleFidelizados: 'register',
  pageDescriptionFidelizados: 'to be part of the etnia family',
  firstName:        'Name',
  lastName:         'Surname(s)',
  birthday:         'Date of birth',
  taxvat:           'ID card',
  subscribe:        'Subscribe to our newsletter',
  nif:              'Tax ID/Corporate Tax ID No/Foreigner’s Tax ID',
  address:          'Address',
  telephone:        'Telephone number',
  city:             'Town/City',
  postCode:         'Postcode.',
  region:            'Province',
  country:          'Country',
  personalInfo:     'Personal Information',
  accessInfo:       'Log-in details',
  email:            'Email',
  password:         'Password',
  confirmPassword:  'Confirm Password',
  buttonLabel:      'Create Account',
  linkSignin:       'Already signed up?',
  accept:          'I accept the ',
  privacyLink:      'privacy policy',
  newsletter1:       'Subscribe',
  newsletter2:       ' to the newsletter',
  requiredFields:   '* Mandatory fields',
  keepSession:      'Stay logged in',
  privacy1:         'I have ',
  privacy2:         'read and accept',
  privacy3:         ' the conditions of the '
}

export default createAccount;
