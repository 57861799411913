let sales = null;

const reducers = (state = sales, action) => {
  switch(action.type) {
    case "SET_ACTUAL_ORDER":
      return action.order;
    default:
      return state;
  }
}

export default reducers;
