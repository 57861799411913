let categoryTree = null;

const reducers = (state = categoryTree, action) => {
  switch(action.type) {
    case "SET_CATEGORY_TREE":
      return action.categoryTree;
    default:
      return state;
  }
}

export default reducers;
