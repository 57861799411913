import { en as storeEn }        from '@themes/store/frontend/locales/en';
import tribe                    from '@frontend/locales/en/tribe';
import contact                  from '@frontend/locales/en/contact';
import map                      from '@frontend/locales/en/map';
import rma                      from '@frontend/locales/en/rma';
import menuFooter               from '@frontend/locales/en/menu-footer';
import sideBarNewsletter        from '@frontend/locales/en/side-bar-newsletter';
import productSwatchInfo        from '@frontend/locales/en/product-swatch-info';
import neoactivesAttributes     from '@frontend/locales/en/neoactives-attributes';
import blog                     from '@frontend/locales/en/blog';
import neoactivesStaticTables   from '@frontend/locales/en/neoactives-static-tables';
import crossSellProducts        from '@frontend/locales/en/cross-sell-products';
import thanks                   from '@frontend/locales/en/thanks';
import wishlist                 from '@frontend/locales/en/wishlist';
import theme                    from '@frontend/locales/en/theme';
import safeBuy                    from '@frontend/locales/en/safe-buy';
import productViewInfo          from '@frontend/locales/en/product-view/info';
import productViewReviews       from '@frontend/locales/en/product-view/reviews';
import error                    from '@frontend/locales/en/error';
import looks                    from '@frontend/locales/en/looks';
import stock                    from '@frontend/locales/en/stock-notification';
import freeShippingStatusBar    from '@frontend/locales/en/free-shipping-status-bar';
import blackFriday              from '@frontend/locales/en/black-friday';
import socialRoom               from '@frontend/locales/en/social-room';

export const en = {
  ...storeEn,
  tribe,
  contact,
  map,
  rma,
  productSwatchInfo,
  neoactivesAttributes,
  blog,
  neoactivesStaticTables,
  menuFooter,
  sideBarNewsletter,
  crossSellProducts,
  thanks,
  wishlist,
  theme,
  stock,
  safeBuy,
  productViewInfo,
  productViewReviews,
  error,
  looks,
  freeShippingStatusBar,
  blackFriday,
  socialRoom
}

export default en;
