export const forgottenPassword = {
  tagTitle:         "Recover password",
  metaTitle:        "Recover password",
  pageTitle:        "Recover password",
  description:      "Enter your email address to receive a password reset link.",
  returnToLogin:    "Return to Login",
  send:             "Send",
  error:            "Error sending email, verify that email exists and try again.",
  success:          "Email sent, please check your inbox to reset the password",
  button: {
    send:     "Send",
    sent:     "Sent",
    sending:  "Sending"
  }
}

export default forgottenPassword;
