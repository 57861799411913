export const reviews = {
  count: '%1 REZENSIONEN',
  showMore: 'mehr sehen',
  showLess: 'weniger sehen',
  addReview: {
    button: 'Eine Meinung hinterlassen',
    title: 'IHRE MEINUNG IST UNS WICHTIG',
    send: 'Senden'
  }
}

export default reviews;
