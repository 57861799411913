import EzComponent     from '@frontend/components/EzComponent';
import { connect }     from 'react-redux';
import dynamic         from 'next/dynamic';

import                 '@frontend/components/third-party-scripts/cookies/component/configurator/index.less';

const Link = dynamic(()=> import('next/link'));

class CookieConfigurator extends EzComponent {

  static mapStateToProps({storeConfig:{cookiesData, additional_data_array}}) {
    return {
      cookiesData,
      additionalData: additional_data_array
    }
  }

  render() {
    const {
      children,
      closeConfig,
      acceptSelection,
      rejectAll,
      cookiesData:{
        privacyPolicyUrl
      }
    } = this.props;

    return (
      <div className="configuration-container">
        <span onClick={closeConfig} className="close"></span>
        <div className="configuration">
          <div className="title-container">
            <p className="title">{this.i18n.t('cookies.configuration')}</p>
              {
                !!privacyPolicyUrl &&
                  <Link {...this.router.get(privacyPolicyUrl)}>
                    <a target="_blank" className="cookie-consent-policy"  >{this.i18n.t('cookies.seeCookiesPolicy')}</a>
                  </Link>
              }
            </div>
            <div className="cookie-content">
              <div className="cookies-list">
                {children}
              </div>
              <div className="actions">
                <button onClick={acceptSelection} className="accept-selection">{this.i18n.t('cookies.acceptSelection')}</button>
                <button onClick={rejectAll} className="reject-all">{this.i18n.t('cookies.rejectAll')}</button>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default connect(CookieConfigurator.mapStateToProps)(CookieConfigurator)