import addresses      from '@frontend/locales/en/user/my-account/addresses';
import contactInfo    from '@frontend/locales/en/user/my-account/contact-info';
import orderDetail    from '@frontend/locales/en/user/my-account/order-detail';
import orders         from '@frontend/locales/en/user/my-account/orders';

export const myAccount = {
  addresses,
  contactInfo,
  orderDetail,
  orders,
  tagTitle:           "My account",
  metaTitle:          "My account",
  pageTitle:          "My account",
  me:                 "Account",
  myAdresses:         "Address book",
  myOrders:           "My Orders",
  accountInfo:        "Personal Data",
  addressInfo:        "Address book",
  wishlist:           "Wishlist",
  emptyWishlist:      "You have not yet added any product to your wishlist",
  signOut:            "Log Out",
  billingAddresses:   "Billing address",
  shippingAddresses:  "Shipping address",
  edit:               "Edit",
  add:                "Add",
  defaultAddresses:   "Default addresses",
  aditionalEntries:   "Additional address entries"
}

export default myAccount;
