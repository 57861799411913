const isMobile = false;

const reducers = (state = isMobile, action) => {
  switch(action.type) {
    case "SET_IS_MOBILE":
      return action.isMobile;
    default:
      return state;
  }
}

export default reducers;
