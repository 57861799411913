
export const wishlist = {
    logInToAdd: 'You must log in to be able to add products to your wish list',
    logIn: 'log in',
    addSuccess: '%1 added to your wish list',
    addError: 'Error adding %1 to your wish list. Please, try again.',
    deleteSuccess: '%1 deleted from your wish list.',
    deleteError: 'Error deleting %1 from your wish list. Please, try again.',
    goToWishlist: 'go to my wish list'
  }
  
  export default wishlist;
  