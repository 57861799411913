export const contact = {
  tagTitle:     'Kontakt',
  metaTitle:    'Kontakt',
  name:         'Name',
  email:        'E-Mail-Adresse',
  subject:      'Thema',
  message:      'Nachricht',
  accept:       'Ich habe gelesen und akzeptiere die ',
  privacyLink:  'Datenschutzbestimmungen',
  privacyError: 'Es ist notwendig, die Datenschutzbedingungen zu akzeptieren.'
}

export default contact;
