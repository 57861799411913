export const checkout = {
  successTagTitle:  'Danke für ihren Einkauf!',
  successMetaTitle: 'Danke für ihren Einkauf!',
  yourOrder:        'Su nº de pedido es: %1',
  confirmationText: 'Wir senden Ihnen eine Bestätigungs-E-Mail mit den Details und Informationen der Sendung.',
  continueBuying:   'Weiter einkaufen',
  verificationText: 'Sie können den Status Ihrer Bestellung überprüfen, indem Sie ein Konto erstellen.',
  createAccount:    'KONTO EINRICHTEN',
  failureText:      'Es gab ein Problem mit Ihrer Bestellung. Bitte versuchen Sie es erneut.',
  topMessage:         'Ihre Bestellung wird bald eintreffen',   
  thanks:             'Danke für ihren Einkauf!',
  question:           'Hat Ihnen der Kaufprozess gefallen?',
  review:             'Wir möchten Ihre Meinung wissen',
  emailWarning: 'Wir senden Ihnen eine E-Mail mit den Details und Informationen zu Ihrer Bestellung',
  newOrder: 'Starten Sie eine neue Bestellung',
  keepBuying: 'Kaufen Sie weiter',
  joinClub: 'Treten Sie jetzt unserem',
  clubNameOne: 'LUXENTER',
  clubNameTwo: 'CLUB',
  subscribe: 'Abonnieren',
  followShipment: 'Sie können Ihre Bestellaktualisierungen in meinem Kontobereich verfolgen',
  share: 'Teilen Sie uns Ihren unglaublichen Look mit dem Hashtag mit ',
  hashtag: '#LUXENTEROFICIAL'
}

export default checkout;
