let postContent = {};

const reducers = (state = postContent, action) => {
  switch(action.type) {
    case "SET_POST_CONTENT":
      return action.postContent;
    default:
      return state;
  }
}

export default reducers;
