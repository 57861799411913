const theme = {
  activeTheme: "white",
  activeSection: "",
  hideMegaMenu: true,
  menuBackground: "transparent",
  selectedLabel: false
};

const reducers = (state = theme, action) => {
  switch(action.type) {
    case "SET_ACTIVE_THEME":
      const theme = {...state,...{activeTheme: action.activeTheme}}
      return theme;
    case "SET_MENU_HIDDEN":
      const menu = {...state,...{hideMegaMenu: action.hideMegaMenu}}
      return menu;
    case "SET_ACTIVE_SECTION":
      const section = {...state,...{activeSection: action.activeSection}}
      return section;
    case "SET_MENU_BACKGROUND":
      const background = {...state,...{menuBackground: action.menuBackground}}
      return background;
    case "SET_SELECTED_LABEL":
      const selected = { ...state, ...{ selectedLabel: action.selectedLabel } }
      return selected;
    default:
      return state;
  }
}

export default reducers;
