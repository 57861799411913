export const theme = {
  cookies: {
    this_web: 'This website uses cookies.',
    keep_nav: 'To continue browsing, you must agree to our use of cookies',
    ok: 'accept',
    link: 'cookie policy',
    vegan: 'vegan'
  },
  days: 'days',
  hours: 'hours',
  minutes: 'minutes'
}

export default theme;
