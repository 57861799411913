import { newsletter as baseNewsletter } from '@themes/base/frontend/locales/fr/newsletter';

export const newsletter = {
  ...baseNewsletter,
  subscribe:          "S'abonner à notre newsletter",
  subscribeInfo:      'Soyez les premiers à être informés de nos nouvelles et de nos promotions.',
  emailPlaceholder:   'Entrez votre courriel',
  send:               'Envoyer'
}

export default newsletter;
