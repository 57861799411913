import general          from '@frontend/locales/fr/general';

import validator        from '@frontend/locales/fr/validator';
import searchBar        from '@frontend/locales/fr/search-bar';
import navigationMenu   from '@frontend/locales/fr/navigation-menu';
import newsletter       from '@frontend/locales/fr/newsletter';
import contact          from '@frontend/locales/fr/contact';
import searchResult     from '@frontend/locales/fr/search-result';
import share            from '@frontend/locales/fr/share';
import mail             from '@frontend/locales/fr/mail';
import days             from '@frontend/locales/fr/days';
import months           from '@frontend/locales/fr/months';

export const fr = {
  langCode: 'fr',
  ...general,
  validator,
  searchBar,
  navigationMenu,
  newsletter,
  contact,
  searchResult,
  share,
  mail,
  days,
  months
}

export default fr;
