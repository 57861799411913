let order = {
  userOrders : null,
  viewingOrder: null
};

const reducers = (state = order, action) => {
  switch(action.type) {
    
    case "SET_VIEWING_ORDER":
      return {...state,viewingOrder:{...action.orderDetail}};
    case "SET_USER_ORDERS":
      return {...state,userOrders:{...action.userOrders}};
    default:
      return state;
  }
}

export default reducers;
