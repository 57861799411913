import EzComponent                                        from '@frontend/components/EzComponent';
import {connect}                                          from 'react-redux';
import { hideCookies,setCookie,hideConfig,showConfig,getCookies }    from '@frontend/actions/cookies';

import CookieInfo                                         from '@frontend/components/third-party-scripts/cookies/component/info';
import CookieConfigurator                                 from '@frontend/components/third-party-scripts/cookies/component/configurator';
import CookieConfiguratorBox                              from '@frontend/components/third-party-scripts/cookies/component/configurator/box';
import cookies                                            from '@frontend/settings/cookies'

import '@frontend/components/third-party-scripts/cookies/component/index.less';
  
export class Cookies extends EzComponent {

    static mapStateToProps({cookies,storeConfig}){
        return { cookies,storeConfig };
    }

    state = {
        functional: false,
        analytical: false,
        advertising:  false,
    }

    toggleConfig = () => {
        const { cookies:{showConfig} } = this.props;
        showConfig ? this.props.hideConfig() : this.props.showConfig()
    }

    toggleAcceptance = (type) => () => {
        this.setState(state => { return { [type]: !state[type] } });
    }
    
    rejectAll= () => {
        this.setState({
            functional: false,
            analytical: false,
            advertising:  false,
        }, this.setCookie);
    }

    acceptAll= () => {
        this.setState({
            functional: true,
            analytical: true,
            advertising:  true,
        }, this.setCookie);
    }

    setCookie = () => {
        const {
            functional,
            analytical,
            advertising,
        } = this.state;

        const currentCookiesAllowed = this.props.getCookies()['cookies-allowed'];
        let newCookiesAllowed       = cookies.ESSENTIAL;
        
        if(functional){  newCookiesAllowed += ',' + cookies.FUNCTIONAL; }
        if(analytical){  newCookiesAllowed += ',' + cookies.ANALYTICAL; }
        if(advertising){ newCookiesAllowed += ',' + cookies.ADVERTISING;}

        if(this.context.scriptManager.cookiesManagement){
            this.props.setCookie('cookies-allowed', newCookiesAllowed,60*60*24*365);
            this.context.scriptManager.dispatchQueue();
            
            this.props.hideCookies();
    
            if(!!currentCookiesAllowed && currentCookiesAllowed !== newCookiesAllowed){
                this.removeCookies(newCookiesAllowed);
                window.location.reload();
            }
        }
    }

    removeCookies = (newCookies) => {
        if(!newCookies) return;

        const currentCookiesArray = newCookies.split(',').filter(Boolean);

        cookies.types().forEach(type => {
            if(type !== cookies.ESSENTIAL && currentCookiesArray.indexOf(type) === -1){
                const cookiesString = this.props.storeConfig.cookiesData[type];

                if(cookiesString){
                const cookiesArray = cookiesString.split(',');

                cookiesArray.forEach(cookie =>{
                    const domainArray  = this.props.storeConfig.cookiesData.domain ? this.props.storeConfig.cookiesData.domain.split(',') : '';

                    domainArray.forEach(domain => {
                        document.cookie = cookie + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=' + domain;
                    });
                });
                }
            }
        });
    }

    componentDidMount(){
        const currentCookiesAllowed = this.props.getCookies()['cookies-allowed'];

        if(currentCookiesAllowed){
            cookies.types().forEach(type => {
                if(currentCookiesAllowed.includes(type)){
                    this.setState({[type]: true})
                }
            });
        }
    }

    render() {
        const {
            functional,
            analytical,
            advertising,
        } = this.state;

        const { cookies: {showConfig} } = this.props


        return (
            <div className={`cookie-consent ${showConfig ? 'configuration' : ''}`}>
            {
                showConfig ?
                    <CookieConfigurator 
                        closeConfig={this.toggleConfig}  
                        acceptSelection={this.setCookie}
                    >
                        <CookieConfiguratorBox
                            key ={cookies.FUNCTIONAL}  
                            type={cookies.FUNCTIONAL} 
                            toggleAcceptance={this.toggleAcceptance(cookies.FUNCTIONAL)} 
                            checked={functional}
                        />
                        <CookieConfiguratorBox
                            key ={cookies.ANALYTICAL}  
                            type={cookies.ANALYTICAL} 
                            toggleAcceptance={this.toggleAcceptance(cookies.ANALYTICAL)} 
                            checked={analytical}
                        />
                        <CookieConfiguratorBox
                            key ={cookies.ADVERTISING}   
                            type={cookies.ADVERTISING}  
                            toggleAcceptance={this.toggleAcceptance(cookies.ADVERTISING)} 
                            checked={advertising}
                        />
                        <CookieConfiguratorBox
                            key ="basic"  
                            type="basic" 
                            checked={true}
                        />
                    </CookieConfigurator>
                :
                    <CookieInfo acceptAll={this.acceptAll} openConfig={this.toggleConfig} />
            }
            </div>
        )
    }
}

export default connect(Cookies.mapStateToProps,{hideCookies,setCookie,showConfig,hideConfig,getCookies})(Cookies);