export const days = {
  monday:     "Montag",
  tuesday:    "Dienstag",
  wednesday:  "Mittwoch",
  thursday:   "Donnerstag",
  friday:     "Freitag",
  saturday:   "Samstag",
  sunday:     "Sonntag",
  mon:        "L",
  tue:        "M",
  wed:        "X",
  thu:        "J",
  fri:        "V",
  sat:        "S",
  sun:        "D"
}

export default days;
