export const info = {
  inStock:                'Auf Lager',
  notStock:               'Kein Lagerbestand',
  qty:                    'Anzahl',
  description:            'Beschreibung',
  composition:            'Zusammensetzung',
  opinions:               'REZENSIONEN',
  operatingInstructions:  'Anweisungen zur Verwendung',
  shippingReturns:        'Versand und Rückgabe'
}

export default info;
