import { miniCart as storeMiniCart } from '@themes/store/frontend/locales/en/mini-cart';

export const miniCart = {
  ...storeMiniCart,
  cart:               'My basket',
  userWelcome:        '%1, welcome to your basket',
  description:        'Here you’ll find all your selected products. Edit your basket or continue to check out.',
  subTotal:           'product summary',
  subTotalDiscount:   'product summary',
  buttonCheckout:     'Go to check out',
  continueBuying:     'Continue shopping',
  shippingTax:        'delivery costs',
  shippingValue:      'Login or enter your address',
  discount:           'discount',
  freeShipping:       'free',
  total:              'total',
  itemAdded:          'Added to your basket',
  goToCart:           'go to my basket',
  goToProducts:       'see products',
  wishlistTitle:      'everything from 20% to 40%',
  wishlistSubTitle:   'to buy what you need',
  wishlistButtonOne:  'your wishlist',
  wishlistButtonTwo:  'add to cart',
  slow:               'slow friday',
  toCartWish:         'your wishlist to cart',
  wishlistMessOne:    'We know you have created a conscious wishlist.',
  wishlistMessTwo:    'It is time to dump your wishlist to the cart to get your',
  wishlistMessThree:  'favorite products with the biggest discount of the year.'
}

export default miniCart;
