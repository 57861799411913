export const menuFooter = {
  shops:      "Shops",
  blog:       "Blog",
  about:      "About",
  conditions: "Conditions",
  lovely:     "Lovely Club",
  legalBase:  "Legal terms and conditions",
  jobs:       "Jobs",
  legalWarning: "Legal notice",
  faq:          "FAQ",
  shops:        "Shops",
  terms:        "Terms and conditions"
}

export default menuFooter;
