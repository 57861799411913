const contactResponse = {};

export const reducers = (state = contactResponse, action) => {
  switch(action.type) {
    case "SET_CONTACT_RESPONSE":
      return action.response;
    default:
      return state;
  }
}

export default reducers;
