export const addresses = {
  editTagTitle:         "Modifier l'adresse",
  editMetaTitle:        "Modifier l'adresse",
  addTagTitle:          'Ajouter une nouvelle adresse',
  addMetaTitle:         'Ajouter une nouvelle adresse',
  add:                  'Ajouter une nouvelle adresse',
  edit:                 "Modifier l'adresse",
  firstnameLabel:       'Nom',
  lastnameLabel:        'Nom de famille',
  regionLabel:          'Province',
  countryLabel:         'Pays',
  postCodeLabel:        'Code postal/ZIP',
  cityLabel:            'Ville',
  streetLabel:          'Adresse',
  telephoneLabel:       'Téléphone',
  defaultBilling:       'Adresse de facturation par défaut',
  defaultShipping:      'Adresse de livraison par défaut',
  notDefaultBilling:    "Vous n'avez pas d'adresse de facturation par défaut dans votre carnet d'adresses.",
  notDefaultShipping:   "Vous n'avez pas d'adresse de livraison par défaut dans votre carnet d'adresses.",
  notOtherAddresses:    "Vous n'avez pas d'autres adresses dans votre carnet d'adresses.",
  notbillingAddresses:  "Vous n'avez pas encore ajouté d'adresse de facturation",
  notshippingAddresses: "Vous n'avez pas encore ajouté d'adresse de livraison"
}

export default addresses;
