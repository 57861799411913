export const days = {
  monday:     "Monday",
  tuesday:    "Tuesday",
  wednesday:  "Wednesday",
  thursday:   "Thursday",
  friday:     "Friday",
  saturday:   "Saturday",
  sunday:     "Sunday",
  mon:        "M",
  tue:        "T",
  wed:        "X",
  thu:        "T",
  fri:        "F",
  sat:        "S",
  sun:        "S"
}

export default days;
