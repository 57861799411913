export const accountMenu = {
  profile: 'Mein Profil',
  orders: 'Meine Befehle',
  wishlist: 'Wunschzettel',
  signout: 'Sitzung schließen',
  signin: 'Anmeldung',
  createAccount: 'KONTO EINRICHTEN'
}

export default accountMenu;
