export const newsletter ={
  recaptchaError:     'You must complete the reCaptcha',
  privacyError:       'You must accept the privacy policy',
  subscribe:          'subscribe to our newsletter',
  subscribeInfo:      'to stay up to date with our deals and discounts',
  emailPlaceholder:   'email address',
  subscriptionError:  'An error has occurred while subscribing to the newsletter. Please check your email address and try again later.',
  send:               'Submit',
  accept:             'I accept the ',
  privacyLink:        'privacy policy',
  rscSubscribe:       'need an attitude dose?',
  rscEmailPlaceholder:'leave us your email',
  rscSend:            'join the attitude plan!'
}

export default newsletter;
