let cmsPage = null;

const reducers = (state = cmsPage, action) => {
  switch(action.type) {
    case "SET_CMSPAGE":
      return action.cmsPage;
    default:
      return state;
  }
}

export default reducers;
