import general          from '@frontend/locales/de/general';
import validator        from '@frontend/locales/de/validator';
import searchBar        from '@frontend/locales/de/search-bar';
import navigationMenu   from '@frontend/locales/de/navigation-menu';
import newsletter       from '@frontend/locales/de/newsletter';
import contact          from '@frontend/locales/de/contact';
import searchResult     from '@frontend/locales/de/search-result';
import share            from '@frontend/locales/de/share';
import mail             from '@frontend/locales/de/mail';
import days             from '@frontend/locales/de/days';
import months           from '@frontend/locales/de/months';

export const de = {
  langCode: 'de',
  ...general,
  validator,
  searchBar,
  navigationMenu,
  newsletter,
  contact,
  searchResult,
  share,
  mail,
  days,
  months
}

export default de;
