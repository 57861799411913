import { SeoMetatags as StoreSeoMetatags }         from '@themes/store/frontend/components/third-party-scripts/seo/metatags';
import { connect }                                 from 'react-redux';
import { getProductPrices }                        from '@frontend/services/PriceCalculation';

export class SeoMetatags extends StoreSeoMetatags{

    getCalculatedPrices(prices,storeConfiguration) {
        return getProductPrices({
            user: this.props.user,
            prices: prices,
            defaultCountry: this.props.defaultCountry,
            excludedTaxCustomerGroup: storeConfiguration.excludedTaxCustomerGroup,
            storeConfig: storeConfiguration
        });
    }
}

export default connect(SeoMetatags.mapStateToProps, {})(SeoMetatags);