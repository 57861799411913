export const miniCart = {
  cart:               'Panier',
  qty:                'Unités',
  itemDelete:         'Supprimer',
  noItems:            "Vous n'avez aucun article dans votre panier",
  oneProductInCart:   'Article dans le panier',
  productsInCart:     'Articles dans le panier',
  subTotal:           'Sous-total',
  subTotalDiscount:   'Avec réduction',
  buttonCheckout:     'Checkout',
  continueBuying:     'Poursuivre les achats'
}

export default miniCart;
