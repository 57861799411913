import { orders as storeOrders } from '@themes/store/frontend/locales/en/user/my-account/orders';

export const orders = {
  ...storeOrders,
  state:              'Estado',
  repeat:             'repeat order',
  return:             'return order',
  // NOTE: next translates are magento DB status
  canceled:           'Canceled',
  closed:             'Closed',
  complete:           'Complete',
  complete_delivered: 'Completed and delivered',
  payment_review:     'Payment review',
  pending:            'Pending',
  "Pending Payment":  'Pending payment',
  pending_payment:    'Pending payment',
  processing:         'Processing',
  shipped:            'Shipped',
  waiting_payment:    'Waiting payment'
}

export default orders;
